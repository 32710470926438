<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingTrip" style="margin-left: 20px;" />
            <button v-if="existingTrip" class="button button--outline-blue button--sm" type="button" @click="handleAdd">
                {{ __("json.Add new cargo") }}
            </button>
        </header>

        <VeeForm v-slot="{ handleSubmit, errors }" as="div" v-if="existingTrip">
            <form @submit="handleSubmit($event, onSubmit)">
                <article class="accordion step-cargo is-active" v-for="(cargoInfo, idx) in form.cargo_infos" :key="idx">
                    <button class="accordion-toggle step-cargo__toggle" type="button">
                        {{ __('json.Cargo') }} №{{ idx + 1 }}
                        <svg class="account-accordion__arrow" width="18" height="12">
                            <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                        </svg>
                    </button>
                    <div class="accordion-dropdownm is-active">
                        <div class="step-cargo__body">
                            <!-- <div class="step-cargo__col">
                                <label for="mrn" class="label">
                                    {{ __("json.MRN/TIR/CMR/CIN") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].mrn" class="input" type="text" id="mrn" :placeholder="__('json.MRN/TIR/CMR/CIN')">
                                </div>
                            </div> -->
                            <!-- <div class="step-cargo__col">
                                <label class="label">
                                    {{ __("json.Sector") }}
                                </label>
                                <div class="input-group">
                                    <Field v-model="form.cargo_infos[idx].sector_id" :name="'sector_id['+idx+']'" v-slot="{field}">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.cargo_infos[idx].sector_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select sector')"
                                            :options="sectors"
                                            :reduce="sector => sector.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                </div>
                            </div> -->
                            <div class="step-cargo__col step-cargo__col--w-100">
                                <label for="hsInput" class="label">
                                    {{ __("json.H_S") }}
                                </label>
                                <div class="input-group">
                                    <Field v-model="hsn_temp" :name="'hsn_temp'" v-slot="{field}">
                                        <v-select
                                            v-bind="field"
                                            v-model="hsn_temp"
                                            :options="hsn"
                                            @update:modelValue="addHsn(idx,$event)"
                                            item-value="id"
                                            item-text="name"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select H_S')"
                                            :clearable="false"
                                        >
                                        <!-- <template v-slot:prepend-item>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <v-text-field v-model="searchTerm" placeholder="Search" @input="searchHSN"></v-text-field>
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-2"></v-divider>
                                          </template> -->
                                        </v-select>

                                    </Field>
                                </div>
                            </div>
                            <div class="step-cargo__col step-cargo__col--w-100">
                                <label for="hsInput" class="label">
                                    {{ __("json.H_S Details") }}
                                </label>
                                <div class="input-group">
                                        <table width="100%" >
                                            <thead>
                                                <tr style="background: #F2F2F4;">
                                                    <td style="font-weight: 600;  padding: 10px; padding-left: 25px;">
                                                        {{ __("json.ID") }}
                                                    </td>
                                                    <td style="font-weight: 600;  padding: 10px; padding-left: 25px;">
                                                        {{ __("json.Code") }}
                                                    </td>
                                                    <td style="font-weight: 600;  padding: 10px; padding-left: 25px;">
                                                        {{ __("json.Name") }}
                                                    </td>
                                                    <td style="font-weight: 600;  padding: 10px; padding-left: 25px;">
                                                        {{ __("json.Text") }}
                                                    </td>
                                                    <td style="font-weight: 600;  padding: 10px; padding-left: 25px;">

                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(h,i) in form.cargo_infos[idx].hsn_array" :key="h.id">
                                                    <td >
                                                        <!-- <Field v-model="form.cargo_infos[idx].hsn_array[i].id" :name="'form.cargo_infos['+idx+'].hsn_array['+i+'].id'" v-slot="{field}"> -->
                                                            <input v-model="h.id" readonly class="input" type="text" :id="'form.cargo_infos['+idx+'].hsn_array['+i+'].id'" :placeholder="__('json.ID')">
                                                        <!-- </Field> -->
                                                    </td>
                                                    <td >
                                                        <!-- <Field v-model="form.cargo_infos[idx].hsn_array[i].name" :name="'form.cargo_infos['+idx+'].hsn_array['+i+'].name'" v-slot="{field}"> -->
                                                            <input v-model="h.code" readonly class="input" type="text" :id="'form.cargo_infos['+idx+'].hsn_array['+i+'].name'" :placeholder="__('json.Name')">
                                                        <!-- </Field> -->
                                                    </td>
                                                    <td >
                                                        <!-- <Field v-model="form.cargo_infos[idx].hsn_array[i].name" :name="'form.cargo_infos['+idx+'].hsn_array['+i+'].name'" v-slot="{field}"> -->
                                                            <input v-model="h.name" readonly class="input" type="text" :id="'form.cargo_infos['+idx+'].hsn_array['+i+'].name'" :placeholder="__('json.Name')">
                                                        <!-- </Field> -->
                                                    </td>
                                                    <td >
                                                        <!-- <Field v-model="form.cargo_infos[idx].hsn_array[i].text" :name="'form.cargo_infos['+idx+'].hsn_array['+i+'].text'" v-slot="{field}"> -->
                                                            <input v-model="h.text" class="input" type="text" :id="'form.cargo_infos['+idx+'].hsn_array['+i+'].text'" :placeholder="__('json.Text')">
                                                        <!-- </Field> -->
                                                    </td>
                                                    <td>
                                                        <button class="button-action" @click="deleteHSN(idx,i)" type="button" aria-label="Delete">
                                                            <svg class="button-action__icon" width="18" height="19">
                                                                <use xlink:href="/assets/images/new/sprite.svg#trash"></use>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                            <div class="step-cargo__col">
                                <label class="label">
                                    {{ __("json.Country of Destination") }}
                                </label>
                                <div class="input-group">
                                    <Field v-model="form.cargo_infos[idx].destination_country_id" :name="'destination_country_id['+idx+']'" v-slot="{field}">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.cargo_infos[idx].destination_country_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select destination country')"
                                            :options="countries"
                                            :reduce="country => country.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <div class="step-cargo__col">
                                <label class="label">
                                    {{ __("json.Origin country") }}
                                </label>
                                <div class="input-group">
                                    <Field v-model="form.cargo_infos[idx].origin_country_id" :name="'origin_country_id['+idx+']'" v-slot="{field}">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.cargo_infos[idx].origin_country_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select origin country')"
                                            :options="countries"
                                            :reduce="country => country.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <!-- <div class="step-cargo__col">
                                <label class="label">
                                    {{ __("json.BGW type") }}
                                </label>
                                <div class="input-group">
                                    <Field v-model="form.cargo_infos[idx].bgw_type_id" :name="'bgw_type_id['+idx+']'" v-slot="{field}">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.cargo_infos[idx].bgw_type_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select BGW type')"
                                            :options="bgwTypes"
                                            :reduce="bgwType => bgwType.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                </div>
                            </div> -->
                            <!-- <div class="step-cargo__col">
                                <label for="portNameInput" class="label">
                                    {{ __("json.BGW name") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].bgw_name" class="input" type="text" id="portNameInput" :placeholder="__('json.BGW name')">
                                </div>
                            </div> -->
                            <div class="step-cargo__col">
                                <label class="label">
                                    {{ __("json.Measure type") }}
                                </label>
                                <div class="input-group">
                                    <Field v-model="form.cargo_infos[idx].measure_id" :name="'measure_id['+idx+']'" v-slot="{field}">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.cargo_infos[idx].measure_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select measure type')"
                                            :options="measureTypes"
                                            :reduce="measureType => measureType.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <div class="step-cargo__col">
                                <label for="weightInput" class="label">
                                    {{ __("json.Cargo Weight") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].weight" class="input" type="number" id="weightInput" :placeholder="__('json.Cargo Weight')">
                                </div>
                            </div>
                            <div class="step-cargo__col">
                                <label class="label">
                                    {{ __("json.Measure type") }}
                                </label>
                                <div class="input-group">
                                    <Field v-model="form.cargo_infos[idx].total_weight_measure_id" :name="'total_weight_measure_id['+idx+']'" v-slot="{field}">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.cargo_infos[idx].total_weight_measure_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select measure type')"
                                            :options="measureTypes"
                                            :reduce="measureType => measureType.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <div class="step-cargo__col">
                                <label for="weightInput" class="label">
                                    {{ __("json.Pallet") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].total_weight" class="input" type="number" id="weightInput" :placeholder="__('json.Pallet')">
                                </div>
                            </div>
                            <!-- <div class="step-cargo__col">
                                <label for="quantityInput" class="label">
                                    {{ __("json.Quantity") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].quantity" class="input" type="number" id="quantityInput" :placeholder="__('json.Quantity')">
                                </div>
                            </div> -->
                            <!-- <div class="step-cargo__col">

                            </div> -->
                            <div class="step-cargo__col">
                                <label for="currencyIdInput" class="label">
                                    {{ __("json.Currency") }}
                                </label>
                                <div class="input-group">
                                    <!-- <input v-model="form.cargo_infos[idx].currency_id" class="input" type="number" id="currencyIdInput" :placeholder="__('json.Currency')"> -->
                                    <v-select
                                            v-bind="field"
                                            v-model="form.cargo_infos[idx].currency_id"
                                            label="code"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Currency')"
                                            :options="currencies"
                                            :reduce="curr => curr.id"
                                            :clearable="false"
                                        />
                                </div>
                            </div>
                            <div class="step-cargo__col">
                                <label for="dollarInput" class="label">
                                    {{ __("json.Value") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].values_usd" class="input" type="number" id="dollarInput" placeholder="$">
                                </div>
                            </div>
                            <!-- <div class="step-cargo__col">
                                <label for="shipmentNameInput" class="label">
                                    {{ __("json.Cargo name") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].name" class="input" type="text" id="shipmentNameInput" :placeholder="__('json.Cargo name')">
                                </div>
                            </div> -->
                            <!-- <div class="step-cargo__col">
                                <label for="dateInput" class="label">
                                    {{ __("json.Clearance date") }}
                                </label>
                                <div class="input-group input-group--w-50">
                                    <input v-model="form.cargo_infos[idx].clearance_date" class="input" type="date" id="dateInput" placeholder="00/00/0000">
                                </div>
                            </div> -->
                            <!-- <div class="step-cargo__col">
                                <label for="manifestIDInput" class="label">
                                    {{ __("json.Declaration No") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].clearance_id" class="input" type="text" id="manifestIDInput" :placeholder="__('json.Clearance ID')">
                                </div>
                            </div> -->
                            <div class="step-cargo__col">
                                <label for="container_no" class="label">
                                    {{ __("json.Container No") }}
                                </label>
                                <div class="input-group">
                                    <input v-model="form.cargo_infos[idx].container_no" class="input" type="text" id="container_no" :placeholder="__('json.Container No')">
                                </div>
                            </div>
                            <div class="step-cargo__col step-cargo__col--w-100">
                                <button class="button button--outline-red button--md step-form__delete" type="button" @click="handleDelete(idx)">
                                    {{ __("json.Delete") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </article>

                <div class="step-form__buttons">
                    <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                        {{ __("json.Previous step") }}
                    </button>
                    <button class="button button--blue step-form__button" type="submit">
                        {{ __("json.Next step") }}
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import vSelect from "vue-select";
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import IconLoading from "../partials/IconLoading.vue";
import {useGetExistingTrip} from "../utils/useGetExistingTrip";

export default {
    name: "Step6",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        countries: {
            type: Object,
            required: true
        },
        tripType: {
            type: String,
            required: true
        },
        sectors: {
            type: Object,
            required: true
        },
        currencies: {
            type: Object,
            required: true
        },
        bgwTypes: {
            type: Object,
            required: true
        },
        measureTypes: {
            type: Object,
            required: true
        },
        hsn: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateTripId: {
            type: Number,
            required: false
        },
    },
    components: {
        VeeForm,
        Field,
        ErrorMessage,
        vSelect,
        IconLoading,
    },
    data() {
        return {
            currentStep: this.modelValue,
            searchTerm: "",
            hsn_temp: null,
            filteredHSNs: [],
            form: {
                cargo_infos: [],
            },
        }
    },
    setup() {
        const { getExistingTrip, existingTrip } = useGetExistingTrip();
        return {
            getExistingTrip, existingTrip
        }
    },
    mounted() {
        this.getExistingTrip(this.tripType, this.updateTripId).then((data) => {
            console.log('ddata',data);
            if (Object.keys(data).length !== 0) {
                this.updateFormData()
            }
        })
    },
    computed: {
        formWithTripType() {
            if (!('tripType' in this.form)) {
                Object.assign(this.form, { 'tripType': this.tripType })
            }
            return this.form;
        },

    },
    emits: ['update:modelValue'],
    methods: {
        addHsn(idx,$event) {
            console.log('event', $event);
            if(!this.form.cargo_infos[idx].hsn_array) {
                this.form.cargo_infos[idx].hsn_array = [];
            }
            let seperate_idx = $event.name.indexOf(', ');
            let code = $event.name.split(', ')[0];
            let name = $event.name.substr(seperate_idx+2);
            this.form.cargo_infos[idx].hsn_array.push({id: $event.id, code: code, name: name, text: ""});
            console.log('ADDED: this.form.cargo_infos[idx].hsn_array', this.form.cargo_infos[idx].hsn_array);
            this.hsn_temp = null;
        },
        async fillStep() {
            let url = "/reservation/fill-step";
            if (this.action === "update") {
                url += "/" + this.existingTrip.id;
            }
            await axios.post(url, this.formWithTripType);
        },
        async onSubmit() {
            await this.fillStep();
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit('update:modelValue', _currentStep);
        },
        handleAdd() {
            this.form.cargo_infos.push({});
        },
        handleDelete(index) {
            this.form.cargo_infos = this.form.cargo_infos.filter((cargo, arrIdx) => { return arrIdx !== index });
        },
        updateFormData() {
            this.form.cargo_infos = this.existingTrip ? this.existingTrip.cargoInfos : [];
            console.log('this.form.cargo_infos',this.form.cargo_infos);
            this.form.cargo_infos.forEach(x => {
                    x.hsn_id = x.hsn_id.map(z => {
                        //return {id: z, name:this.hsn.filter(t => t.id == z)[0].name}
                        return {id: z, name:z}
                    });
            })
            this.form.cargo_infos.forEach((cargoInfo) => {
                cargoInfo.clearance_date = this.formatDate(cargoInfo.clearance_date);
            });
        },
        formatDate(dateString) {
            if (!dateString) {
                return null;
            }
            let date = new Date(dateString);
            let year = date.toLocaleString("default", { year: "numeric" });
            let month = date.toLocaleString("default", { month: "2-digit" });
            let day = date.toLocaleString("default", { day: "2-digit" });

            return year + "-" + month + "-" + day;
        },
        deleteHSN(idx,id) {
            console.log('id',id);
            this.form.cargo_infos[idx].hsn_array.splice(id,1);
            //console.log('BEFORE: this.form.cargo_infos[idx].hsn_array', this.form.cargo_infos[idx].hsn_array);

        }
    },
}
</script>
