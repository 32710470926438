<template>
    <ul class="account-shipments" v-if="shipments.length > 0">
        
        <li class="account-shipments__item" v-for="shipment in shipments" :key="shipment.id">
            <article class="shipment-card" :class="{ 'accordion-vue': shipment.user.role_id !== 4 }">
                <div class="shipment-card__row">
                    <div class="shipment-card__col">
                        <svg class="shipment-card__icon" width="28" height="38">
                            <use xlink:href="/assets/images/new/sprite.svg#file-pdf"></use>
                        </svg>
                    </div>
                    <div class="shipment-card__col">
                        <h4 class="shipment-card__title">{{ __("json.Shipment ID") }}</h4>
                        <p class="shipment-card__text shipment-card__text--ellipsis">{{ shipment.id }}</p>
                    </div>
                    <div class="shipment-card__col">
                        <h4 class="shipment-card__title">{{ __("json.Shipment Name") }}</h4>
                        <p class="shipment-card__text">{{ shipment.ship_name }}</p>
                    </div>
                    <div class="shipment-card__col">
                        <h4 class="shipment-card__title">{{ __("json.IMO Number") }}</h4>
                        <p class="shipment-card__text">
                            {{ shipment.imo_number }}
                        </p>
                    </div>
                    <div class="shipment-card__col">
                        <h4 class="shipment-card__title">
                            {{ __("json.Status") }}
                        </h4>
                        <button class="accordion-toggle shipment-card__toggle" :class="shipment.status_class" type="button">
                            <span>
                                {{ __("json." + shipment.shipment_status) }}
                            </span>
                            <svg class="shipment-card__toggle-icon" width="13" height="8" v-if="shipment.user.role_id !== 4">
                                <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                            </svg>
                        </button>
                    </div>
                    <div class="shipment-card__col shipment-card__buttons">
                        <a v-if="shipment.is_editable" class="button button--red button--sm" :href="'/shipments/update/' + shipment.id">
                            {{ __('json.Edit') }}
                        </a>
                        
                        <a class="button-action page-header__button" v-if="!shipment.is_editable" :href="'/shipments/pdf/download/' + shipment.id" target="_blank" :title="__('json.Download document')">
                            <svg class="button-action__icon" width="18" height="20">
                                <use xlink:href="/assets/images/new/sprite.svg#download"></use>
                            </svg>
                        </a>

                        <div class="dropdown-vue shipment-card-dropdown">
                            <button class="button-action dropdown-toggle shipment-card-dropdown__toggle" type="button" aria-label="Open actions menu">
                                <svg class="button-action__icon" width="4" height="16">
                                    <use xlink:href="/assets/images/new/sprite.svg#more"></use>
                                </svg>
                            </button>
                            <ul class="dropdown-menu dropdown-menu--right shipment-card-dropdown__menu">
                                <li class="dropdown-menu__item">
                                    <a :href="'/shipments/view/' + shipment.id" class="dropdown-menu__link">
                                        {{ __("json.View document") }}
                                    </a>
                                </li>
                                <li class="dropdown-menu__item">
                                        <a href="#" class="dropdown-menu__link" @click.prevent="openShareModal(shipment)">
                                            {{ __("json.Share") }}
                                        </a>
                                    </li>
                                <li class="dropdown-menu__item" v-if="shipment.is_editable" >
                                    <a href="#" class="dropdown-menu__link" @click.prevent="openDeleteModal(shipment)">
                                        {{ __("json.Delete") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </article>
        </li>
    </ul>
    <div v-else>
        <div class="account-empty">
            <img class="account-empty__img" src="/assets/images/new/empty-img.svg" width="80" height="80" alt="empty">
            <p class="account-empty__text">
                {{ __('shipments.no_shipments_yet') }}
            </p>
        </div>
    </div>
    <button id="btn-open-share-modal" data-modal="modal-share" style="display: none"></button>
    <button id="btn-open-delete-modal" data-modal="modal-delete" style="display: none"></button>
    <button id="btn-open-info-modal" data-modal="modal-info" style="display: none"></button>
    <div class="modal-wrap">
        <div id="modal-share" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button class="modal__close" aria-label="Close modal" data-modal-close type="button">
                        <svg class="modal__close-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#close-modal"></use>
                        </svg>
                    </button>

                    <h3 class="modal__title">{{ __("json.Share this document") }}</h3>

                    <ul class="modal-share">
                        <li class="modal-share__item">
                            <a class="modal-share__link" :href="whatsappLink" target="_blank">
                                <svg class="modal-share__icon" width="39" height="38">
                                    <use xlink:href="/assets/images/new/sprite.svg#whatsapp"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="modal-share__item">
                            <a class="modal-share__link" :href="telegramLink" target="_blank">
                                <svg class="modal-share__icon" width="39" height="32">
                                    <use xlink:href="/assets/images/new/sprite.svg#telegram"></use>
                                </svg>
                            </a>
                        </li>
                    </ul>

                    <form class="modal__form">
                        <label class="label">{{ __("json.or copy link") }}</label>
                        <div class="input-group modal__form-group">
                            <input class="input" type="text" name="shareLinkInput" :value="shareShipmentUrl" readonly>
                            <button class="input-group__copy" aria-label="Copy link" type="button" @click="copyToClipboard(shareShipmentUrl)">
                                <svg class="input-group__show-pass-icon" width="14" height="18">
                                    <use xlink:href="/assets/images/new/sprite.svg#copy"></use>
                                </svg>
                            </button>
                        </div>

                        <div class="modal__form-buttons">
                            <button class="button button--red modal__button" data-modal-close type="button">
                                {{ __("json.Cancel") }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="modal-delete" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button class="modal__close" aria-label="Close modal" data-modal-close type="button">
                        <svg class="modal__close-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#close-modal"></use>
                        </svg>
                    </button>

                    <h3 class="modal__title">
                        {{ __("json.Are you sure to delete shipment") }} {{ this.shipmentToDelete?.id }}?
                    </h3>
                    <p class="modal__text">
                        {{ __("json.This shipment") }} {{ this.shipmentToDelete?.id }} {{ __("json.will be removed completely from your shipments") }}.
                    </p>
                    <div class="modal__buttons">
                        <button class="button button--outline modal__button" data-modal-close type="button">
                            {{ __("json.Cancel") }}
                        </button>
                        <button class="button button--red modal__button" type="button" :disabled="isDeleteLoading" @click="deleteShipment(shipmentToDelete)">
                            <IconLoading v-if="isDeleteLoading" />
                            <span v-else>{{ __("json.Delete") }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-info" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button class="modal__close" aria-label="Close modal" data-modal-close type="button">
                        <svg class="modal__close-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#close-modal"></use>
                        </svg>
                    </button>

                    <svg class="modal__icon" width="60" height="60">
                        <use v-if="infoModalStatusSuccess" xlink:href="/assets/images/new/sprite.svg#check-circle"></use>
                        <use v-else xlink:href="/assets/images/new/sprite.svg#close-circle"></use>
                    </svg>
                    <h3 class="modal__title">
                        {{ infoModalTitle }}
                    </h3>

                    <div class="modal__buttons">
                        <button class="button button--red modal__button" data-modal-close="" type="button">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconLoading from "../partials/IconLoading.vue";
import {useLocalTime} from "../utils/useLocalTime";

export default {
    name: "ShipmentList",
    props: ['shipmentType'],
    components: {
        IconLoading,
    },
    setup() {
        const { toLocalTime } = useLocalTime();
        return {
            toLocalTime
        }
    },
    data() {
        return {
            shipments: [],
            // reviews: JSON.parse(this.shipmentsReviews),
            shipmentToShare: null,
            shipmentToDelete: null,
            isDeleteLoading: false,
            infoModalTitle: '',
            infoModalStatusSuccess: true,
        }
    },
    mounted() {

        this.loadShipments();
        this.listenForUpdates();
    },
    methods: {
        openShareModal(shipment) {
            this.shipmentToShare = shipment;
            const openModalButton = document.getElementById('btn-open-share-modal');
            openModalButton.click();
        },
        openDeleteModal(shipment) {
            this.shipmentToDelete = shipment;
            const openModalButton = document.getElementById('btn-open-delete-modal');
            openModalButton.click();
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                alert('Copied!');
            });
        },
        deleteShipment(shipment) {
            this.isDeleteLoading = true;

            axios
                .get("/shipments/delete/" + shipment.id)
                .then(() => {
                    this.shipments = this.shipments.filter((t) => t.id !== shipment.id);
                    document.querySelector("#modal-delete [data-modal-close]").click();
                    this.infoModalTitle = this.$root.__("json.Shipment") + " " + this.shipmentToDelete?.ship_name + "["+ this.shipmentToDelete?.id +"] " + this.$root.__("deleted successfully");
                    this.infoModalStatusSuccess = true;
                    document.getElementById('btn-open-info-modal').click();
                })
                .catch((err) => {
                    console.log(err);
                    alert("Something went wrong! Please, try again.");
                    document.querySelector("#modal-delete [data-modal-close]").click();
                })
                .finally(() => {
                    this.isDeleteLoading = false;
                });
        },
        listenForUpdates() {
            if (!document.querySelector("#userId")) {
                return;
            }

            // Echo.private(`App.Models.User.${document.querySelector("#userId").value}`)
            //     .notification((notification) => {
            //         if (!notification.type_code) {
            //             return
            //         }

            //         if (notification.type_code === 'review-created') {
            //             const reviewToUpdateIndex = this.reviews[notification.shipment_id].findIndex(r => r.departmentName === notification.review.departmentName);
            //             this.reviews[notification.shipment_id][reviewToUpdateIndex] = notification.review;
            //         } else {
            //             const shipmentToUpdateIndex = this.shipments.findIndex(t => t.id === notification.shipment.id);
            //             this.shipments[shipmentToUpdateIndex] = notification.shipment;
            //         }

            //         this.infoModalTitle = notification.message;
            //         this.infoModalStatusSuccess = notification.status === 'success';
            //         document.getElementById('btn-open-info-modal').click();
            // });
        },
        loadShipments() {
            const url = new URL(window.location.href);
            const urlParams = url.searchParams;

            axios.get('/shipments/list-api/' + this.shipmentType, {
                params: {
                    page: urlParams.get('page'),
                    timestamp: new Date().getTime()
                }
            }).then(({data}) => {
                this.shipments = data.data;
                
            }).finally(() => {
                this.$nextTick(() => {
                    window.initAccordion('.accordion-vue');
                    window.initDropdown('.dropdown-vue');
                });
            });
        },
    },
    computed: {
        shareShipmentUrl() {
            return this.shipmentToShare &&
                window.location.protocol + "//" + window.location.host + '/shipments/view/' + this.shipmentToShare.id;
        },
        shareText() {
            return this.$root.__('reservations.share_text');
        },
        whatsappLink() {
            return 'https://api.whatsapp.com/send/?text=' + this.shareText + this.shareShipmentUrl;
        },
        telegramLink() {
            return 'https://t.me/share/url?url=' + this.shareShipmentUrl + '&text=' + this.shareText;
        }
    }
}
</script>
