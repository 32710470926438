<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingTrip" style="margin-left: 20px;" />
        </header>

        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div" v-if="existingTrip">
            <form @submit="handleSubmit($event, onSubmit)">
                <div class="step-form__row" v-if="tripType === 'transit'">
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Country of Arrival") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.from_country_id }">
                            <Field v-model="form.from_country_id" name="from_country_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.from_country_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select country')"
                                    :options="countries"
                                    :reduce="country => country.id"
                                    :clearable="false"
                                    @option:selected="handleFromCountryUpdate"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="from_country_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Port of Entry") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.border_from_id }">
                            <Field v-model="form.border_from_id" name="border_from_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.border_from_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select port')"
                                    :options="fromBorders"
                                    :reduce="border => border.id"
                                    :clearable="false"
                                >
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for <em>{{ search }}</em>.
                                        </template>
                                        <em v-else style="opacity: 0.5">Please, select a country.</em>
                                    </template>
                                </v-select>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="border_from_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Country of Departure") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.to_country_id }">
                            <Field v-model="form.to_country_id" name="to_country_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.to_country_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select country')"
                                    :options="countries"
                                    :reduce="country => country.id"
                                    :clearable="false"
                                    @option:selected="handleToCountryUpdate"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="to_country_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Port of Exit") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.border_to_id }">
                            <Field v-model="form.border_to_id" name="border_to_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.border_to_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select port')"
                                    :options="toBorders"
                                    :reduce="border => border.id"
                                    :clearable="false"
                                >
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for <em>{{ search }}</em>.
                                        </template>
                                        <em v-else style="opacity: 0.5">Please, select a country.</em>
                                    </template>
                                </v-select>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="border_to_id" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-form__row" v-else-if="tripType === 'external'">
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Country of Arrival") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.from_country_id }">
                            <Field v-model="form.from_country_id" name="from_country_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.from_country_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select country')"
                                    :options="countries"
                                    :reduce="country => country.id"
                                    :clearable="false"
                                    @option:selected="handleFromCountryUpdate"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="from_country_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Port of Entry") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.border_from_id }">
                            <Field v-model="form.border_from_id" name="border_from_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.border_from_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select port')"
                                    :options="fromBorders"
                                    :reduce="border => border.id"
                                    :clearable="false"
                                >
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for <em>{{ search }}</em>.
                                        </template>
                                        <em v-else style="opacity: 0.5">Please, select a country.</em>
                                    </template>
                                </v-select>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="border_from_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Location latitude and longitude") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.destination_latitude }">
                            <Field v-model="form.destination_latitude" type="text" name="destination_latitude" v-slot="{field}">
                                <input v-model="selectedLatLng" class="input" id="destination_latitude" readonly @click="openLocationModal" :placeholder="__('json.Location latitude and longitude')">
                                <button class="input-group__show-map" aria-label="Show map modal" type="button" @click="openLocationModal">
                                    <svg class="input-group__show-pass-icon" width="20" height="18">
                                        <use xlink:href="/assets/images/new/sprite.svg#map"></use>
                                    </svg>
                                </button>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="destination_latitude" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Address to") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.destination_address }">
                            <Field v-model="form.destination_address" type="text" name="destination_address" v-slot="{field}">
                                <input v-bind="field" class="input" id="destination_address" :placeholder="__('json.Address')">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="destination_address" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-form__buttons">
                    <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                        {{ __("json.Previous step") }}
                    </button>
                    <button class="button button--blue step-form__button" type="submit">
                        {{ __("json.Next step") }}
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import vSelect from "vue-select";
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import * as yup from 'yup';
import IconLoading from "../partials/IconLoading.vue";
import {useGetExistingTrip} from "../utils/useGetExistingTrip";

export default {
    name: "Step4",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        tripType: {
            type: String,
            required: true
        },
        countries: {
            type: Object,
            required: true
        },
        borders: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateTripId: {
            type: Number,
            required: false
        },
    },
    components: {
        VeeForm,
        Field,
        ErrorMessage,
        vSelect,
        IconLoading,
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: this.tripType === 'transit' ? {
                from_country_id: null,
                to_country_id: null,
                border_from_id: null,
                border_to_id: null,
            } : {
                from_country_id: null,
                border_from_id: null,
                destination_address: null,
                destination_latitude: null,
                destination_longitude: null,
            },
            center: {
                lat: 33.30,
                lng: 44.37
            },
            map: null,
            marker: null,
        }
    },
    setup() {
        const { getExistingTrip, existingTrip } = useGetExistingTrip();
        return {
            getExistingTrip, existingTrip
        }
    },
    computed: {
        formWithTripType() {
            if (!('tripType' in this.form)) {
                Object.assign(this.form, { 'tripType': this.tripType })
            }
            return this.form;
        },
        fromBorders() {
            return this.form.from_country_id
                ? this.borders.filter(border => border.country_id === this.form.from_country_id)
                : [];
        },
        toBorders() {
            return this.form.to_country_id
                ? this.borders.filter(model => model.country_id === this.form.to_country_id)
                : [];
        },
        schema() {
            return this.tripType === 'transit' ? yup.object({
                from_country_id: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Country of Arrival") })),
                to_country_id: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Country of Departure") })),
                border_from_id: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Port of Entry") })),
                border_to_id: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Port of Exit") })),
            }) : yup.object({
                from_country_id: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Country of Arrival") })),
                border_from_id: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Port of Entry") })),
                destination_latitude: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Location latitude and longitude") })),
                destination_address: yup.mixed()
                    .required(this.$root.__("validation.required", { 'attribute': this.$root.__("json.Address to") })),
            });
        },
        selectedLatLng() {
            return (this.form.destination_latitude && this.form.destination_longitude)
                && `${this.form.destination_latitude}° N, ${this.form.destination_longitude}° E`;
        },
    },
    emits: [
        'update:modelValue',
        'openLocationModal',
        'updateSelectedLatLng',
    ],
    methods: {
        async fillStep() {
            let url = "/reservation/fill-step";
            if (this.action === "update") {
                url += "/" + this.existingTrip.id;
            }
            await axios.post(url, this.formWithTripType);
        },
        onSubmit() {
            this.fillStep();
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit('update:modelValue', _currentStep);
        },
        updateFormData() {
            if (this.tripType === 'transit') {
                this.form.from_country_id = this.existingTrip ? this.existingTrip.from_country_id : null;
                this.form.to_country_id = this.existingTrip ? this.existingTrip.to_country_id : null;
                this.form.border_from_id = this.existingTrip ? this.existingTrip.border_from_id : null;
                this.form.border_to_id = this.existingTrip ? this.existingTrip.border_to_id : null;
            } else {
                this.form.from_country_id = this.existingTrip ? this.existingTrip.from_country_id : null;
                this.form.border_from_id = this.existingTrip ? this.existingTrip.border_from_id : null;
                this.form.destination_address = this.existingTrip ? this.existingTrip.destination_address : null;
                this.form.destination_latitude = this.existingTrip ? this.existingTrip.destination_latitude : null;
                this.form.destination_longitude = this.existingTrip ? this.existingTrip.destination_longitude : null;
            }
        },
        handleFromCountryUpdate() {
            this.form.border_from_id = null;
        },
        handleToCountryUpdate() {
            this.form.border_to_id = null;
        },
        openLocationModal() {
            this.initGoogleMaps();
            this.$emit('openLocationModal');
        },
        initGoogleMaps() {
            if (!this.map) {
                window.loader.load().then((google) => {
                    const mapElement = document.getElementById('location-map');
                    this.map = new google.maps.Map(mapElement, {
                        center: this.center,
                        zoom: 11,
                        clickableIcons: false,
                    });

                    if (this.form.destination_latitude && this.form.destination_longitude) {
                        let currentLatLng = {
                            lat: parseFloat(this.form.destination_latitude),
                            lng: parseFloat(this.form.destination_longitude)
                        };
                        this.placeMarker(currentLatLng);
                    }

                    this.map.addListener('click', (event) => {
                        this.form.destination_latitude = event.latLng.lat();
                        this.form.destination_longitude = event.latLng.lng();
                        this.placeMarker(event.latLng);
                    });

                    this.initSearchLocation();
                });
            }
        },
        initSearchLocation() {
            let searchInput = document.getElementById("searchLocationInput");
            let searchBox = new google.maps.places.SearchBox(searchInput);

            this.map.addListener("bounds_changed", () => {
                searchBox.setBounds(this.map.getBounds());
            });

            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                const place = places[0];

                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                // Create a marker for place.
                const placeLatLng = place.geometry.location;
                this.form.destination_latitude = placeLatLng.lat();
                this.form.destination_longitude = placeLatLng.lng();
                this.placeMarker(placeLatLng);

                const bounds = new google.maps.LatLngBounds();
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }

                this.map.fitBounds(bounds);
                this.map.setZoom(13);
            });
        },
        placeMarker(latLng) {
            if (this.marker) {
                this.marker.setPosition(latLng);
            } else {
                this.marker = new google.maps.Marker({
                    position: latLng,
                    map: this.map,
                    icon: '/assets/images/bgw.svg'
                });
            }
        },
    },
    mounted() {
        this.getExistingTrip(this.tripType, this.updateTripId).then((data) => {
            if (Object.keys(data).length !== 0) {
                this.updateFormData();
            }
        })
    },
    watch: {
        selectedLatLng() {
            this.$emit('updateSelectedLatLng', this.selectedLatLng);
        }
    },
}
</script>
