<template>
    <StepperHead
        :steps="steps"
        :current-step="currentStep"
    />

    <Step1
        v-model="currentStep"
        :steps="steps"
        :brands="data.brands"
        :models="data.models"
        :countries="data.countries"
        :trip-type="data.tripType"
        :action="action"
        :update-trip-id="updateTripId"
        v-if="this.currentStep === 1"
    />

    <Step2
        v-model="currentStep"
        :steps="steps"
        :countries="data.countries"
        :trip-type="data.tripType"
        :action="action"
        :update-trip-id="updateTripId"
        v-if="this.currentStep === 2"
    />

    <Step3
        v-model="currentStep"
        :steps="steps"
        :trip-type="data.tripType"
        :action="action"
        :update-trip-id="updateTripId"
        v-if="this.currentStep === 3"
    />

    <Step4
        v-model="currentStep"
        :steps="steps"
        :countries="data.countries"
        :borders="data.borders"
        :trip-type="data.tripType"
        @open-location-modal="openLocationModal"
        @update-selected-lat-lng="updateSelectedLatLng"
        :action="action"
        :update-trip-id="updateTripId"
        v-if="this.currentStep === 4"
    />

    <Step5
        v-model="currentStep"
        :steps="steps"
        :trip-type="data.tripType"
        :action="action"
        :update-trip-id="updateTripId"
        v-if="this.currentStep === 5"
    />

    <Step6
        v-model="currentStep"
        :steps="steps"
        :countries="data.countries"
        :sectors="data.sectors"
        :currencies="data.currencies"
        :bgw-types="data.bgwTypes"
        :measure-types="data.measureTypes"
        :hsn="data.hsn"
        :trip-type="data.tripType"
        :action="action"
        :update-trip-id="updateTripId"
        v-if="this.currentStep === 6"
    />

    <Step7
        v-model="currentStep"
        :steps="steps"
        :trip-type="data.tripType"
        @open-success-modal="openSuccessModal"
        :action="action"
        :update-trip-id="updateTripId"
        v-if="this.currentStep === 7"
    />

    <button id="btn-open-location-modal" data-modal="modal-location" style="display: none"></button>
    <button id="btn-open-success-modal" data-modal="modal-success" style="display: none"></button>
    <div class="modal-wrap">
        <div id="modal-location" class="modal">
            <div class="modal__inner">
                <div class="modal__window modal__window--big">
                    <button class="modal__close" aria-label="Close modal" data-modal-close type="button">
                        <svg class="modal__close-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#close-modal"></use>
                        </svg>
                    </button>

                    <form class="modal__form" onsubmit="return false;">
                        <label class="label label--big">
                            {{ __("json.Location latitude and longitude") }}
                        </label>
                        <div class="input-group modal__form-group">
                            <input class="input" type="text" name="searchLocationInput" id="searchLocationInput" placeholder="Search location">
                        </div>

                        <div class="modal__map-wrap">
                            <div id="location-map" class="modal__map" style="width: 100%; height: 400px;"></div>
                        </div>

                        <div class="modal__form-buttons modal__form-buttons--space-between">
                            <button class="button button--outline modal__button modal__button--auto" data-modal-close type="button">
                                {{ __("json.Cancel") }}
                            </button>
                            <button class="button button--blue modal__button modal__button--auto" data-modal-close type="button" :disabled="!selectedLatLng">
                                {{ __("json.Add location") }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="modal-success" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button class="modal__close" aria-label="Close modal" data-modal-close type="button">
                        <svg class="modal__close-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#close-modal"></use>
                        </svg>
                    </button>

                    <svg class="modal__icon" width="60" height="60">
                        <use xlink:href="/assets/images/new/sprite.svg#check-circle"></use>
                    </svg>
                    <h3 class="modal__title">
                        {{ __('json.Success') }}
                    </h3>
                    <p class="modal__text">
                        <span v-if="action === 'create'">
                            {{ __('json.Reservation has been successfully created') }}
                        </span>
                        <span v-else>
                            {{ __('json.Reservation has been successfully updated') }}
                        </span>
                    </p>
                    <div class="modal__buttons">
                        <button class="button button--red modal__button" data-modal-close type="button">
                            {{ __('json.OK') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import StepperHead from "./StepperHead.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";
import Step6 from "./Step6.vue";
import Step7 from "./Step7.vue";

export default {
    name: "Form",
    props: {
        data: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateTripId: {
            type: Number,
            required: false
        },
    },
    components: {
        StepperHead,
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
        Step7,
    },
    data() {
        return {
            steps: {
                1: this.$root.__('json.Trip information'),
                2: this.$root.__('json.Driver Information'),
                3: this.$root.__('json.Consignee'),
                4: this.$root.__('json.Border registration information'),
                5: this.$root.__('json.Sender'),
                6: this.$root.__('json.Cargo information'),
                7: this.$root.__('json.Review information'),
            },
            currentStep: 1,
            selectedLatLng: null,
        }
    },
    methods: {
        openLocationModal() {
            document.getElementById('btn-open-location-modal').click();
        },
        openSuccessModal() {
            document.getElementById('btn-open-success-modal').click();
        },
        updateSelectedLatLng(newValue) {
            this.selectedLatLng = newValue;
        },
    },
}
</script>
