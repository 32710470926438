import {ref} from "vue";

export const useGetExistingShipment = () => {
    const existingShipment = ref(null);

    const getExistingShipment = async (shipmentType, updateShipmentId) => {
        let url = '/shipments/existing/' + shipmentType;
        if (updateShipmentId) {
            url += '/' + updateShipmentId;
        }
        return await axios.get(url).then(({data}) => {
            existingShipment.value = data;
            return data;
        });
    }

    return { getExistingShipment, existingShipment };
}
