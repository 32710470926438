<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!items" style="margin-left: 20px;" />

        </header>


        <!-- <div class="step-form__row"> -->


        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div">
            <form @submit="handleSubmit($event, onSubmit)">
                <article class="accordion step-x is-active">
                    <button class="accordion-toggle step-x__toggle" type="button">
                        {{ __('json.New Item') }}
                        <svg class="account-accordion__arrow" width="18" height="12">
                            <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                        </svg>
                    </button>
                    <div class="accordion-dropdownm is-active">
                        <div class="step-x__body">
                            <div class="step-form__col">
                                <label for="article_name" class="label">{{ __("json.Article name") }} <span
                                        class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.article_name }">
                                    <Field v-model="form.article_name" type="text" name="article_name"
                                        v-slot="{ field }">
                                        <input v-bind="field" class="input" id="article_name"
                                            :placeholder="__('json.Article name')">
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="article_name" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="quantity" class="label">{{ __("json.Quantity") }} <span
                                        class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.quantity }">
                                    <Field v-model="form.quantity" type="text" name="quantity" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="quantity"
                                            :placeholder="__('json.Quantity')" type="number">
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="quantity" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="location_on_board" class="label">{{ __("json.Location on board") }} <span
                                        class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.location_on_board }">
                                    <Field v-model="form.location_on_board" type="text" name="location_on_board" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="location_on_board"
                                            :placeholder="__('json.Location on board')">
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="location_on_board" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="official_use" class="label">{{ __("json.Official use") }}<span
                                            class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.official_use }">
                                    <Field v-model="form.official_use" type="text" name="official_use" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="official_use" :placeholder="__('json.Offical use')">
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="official_use" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-x__col step-x__col--w-100">
                                <button type="submit" class="button button--outline-red button--md step-form__delete">{{
                                    __("json.Save") }}</button>
                            </div>
                        </div>
                    </div>
                </article>
            </form>
        </VeeForm>
        <div>
            <ShipmentStoreList :master-id="updateShipmentId" ref="myShipmentStoreList" />
        </div>

        <!-- </div> -->
        <div class="step-form__buttons">
            <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                {{ __("json.Previous step") }}
            </button>
            <button class="button button--blue step-form__button" type="submit" @click="handleNext">
                {{ __("json.Next step") }}
            </button>
        </div>
    </section>

    <!-- <button id="btn-open-new-modal" data-modal="modal-new" style="display: none;"></button> -->
</template>

<script>
import vSelect from "vue-select";
import { ErrorMessage, Field, Form as VeeForm } from 'vee-validate';
import { VueTelInput } from "vue-tel-input";
import * as yup from 'yup';
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import { useGetShipmentStores } from "../utils/useGetShipmentStores";
import ShipmentStoreList from "./ShipmentStoreList.vue";
import { ref } from "vue";

const myShipmentStoreList = ref(null);

const schema = yup.object({
    article_name: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Article name') })),
    quantity: yup.number().min(0)
        .required(lang('validation.required', { 'attribute': lang('json.Quantity') })),
    location_on_board: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Location on board') })),
    official_use: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Official use') })),
});

export default {
    name: "Step3",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateShipmentId: {
            type: Number,
            required: false
        },
    },
    components: {
        vSelect,
        VeeForm,
        Field,
        ErrorMessage,
        VueTelInput,
        IconLoading,
        ShipmentStoreList
    },
    setup() {
        const { getShipmentStores, items } = useGetShipmentStores();
        return {
            getShipmentStores, items
        }
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                article_name: null,
                quantity: null,
                location_on_board: null,
                official_use: null,
            },
            schema,
        }
    },
    mounted() {
        this.getShipmentStores(this.updateShipmentId).then((data) => {

        })
    },
    computed: {
        // formWithShipmentType() {
        //     if (!('shipmentType' in this.form)) {
        //         Object.assign(this.form, { 'shipmentType': this.shipmentType })
        //     }
        //     return this.form;
        // },
    },
    emits: ['update:modelValue'],
    methods: {
        async fillShipmentStore() {
            let url = "/shipments/fill-shipment-store/" + this.updateShipmentId;
            try {


                var response = await axios.post(url, this.form);


                this.$refs.myShipmentStoreList.loadItems();
            } catch {

            }

        },
        handleAdd() {
            //document.getElementById('btn-open-new-modal').click();
        },
        onSubmit() {

            this.fillShipmentStore();
        },
        handleNext() {
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit('update:modelValue', _currentStep);
        },
        // updateFormData() {
        //     this.form.driver_name = this.existingShipment ? this.existingShipment.driver_name : null;
        //     this.form.nationality_id = this.existingShipment ? this.existingShipment.nationality_id : null;
        //     this.form.passport_id = this.existingShipment ? this.existingShipment.passport_id : null;
        //     if (this.existingShipment && this.existingShipment.driver_phone) {
        //         this.form.driver_phone = this.existingShipment.driver_phone;
        //     }
        // },
    },
    // watch: {
    //     'form.driver_phone'() {
    //         this.form.driver_phone = this.form.driver_phone.replace(' ', '');
    //     }
    // },
}
</script>
