<template>
    <IconLoading v-if="isLoadingData" />
    <table class="items-table" v-if="items.length > 0">
        <thead>
            <tr >
                <td></td>
                <td>{{ __("json.ID") }}</td>
                <td>{{ __("json.Stowage") }}</td>
                <td>{{ __("json.Reference") }}</td>
                <td>{{ __("json.Marks") }}</td>
                <td>{{ __("json.UN number") }}</td>
                <td>{{ __("json.Shipping") }}</td>
                <td>{{ __("json.Risk class") }}</td>
                <td>{{ __("json.Packing") }}</td>
                <td>{{ __("json.Info") }}</td>
                <td>{{ __("json.Pkg no") }}</td>
                <td>{{ __("json.Mass/Vol") }}</td>
                <td>{{ __("json.EMS") }}</td>
                <td>{{ __("json.Shiping Agent") }}</td>
                <td>{{ __("json.Place") }}</td>
                <td>{{ __("json.Date") }}</td>
            </tr>
        </thead>
        <tbody>
            <tr class="items-list__item" v-for="item in items" :key="item.id">
                        <td>
                            <a
                                class="button button--outline-red button--sm"
                                @click.prevent="deleteItem(item)"
                            >
                                {{ __("json.Delete") }}
                            </a>
                        </td>
                        <td >
                            <p class="item-card__text item-card__text--ellipsis">
                                {{ item.id }}
                            </p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.stowage_position }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.reference_number }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.marks }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">
                                {{ item.un_number }}
                            </p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.proper_shipping_name }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.subsidiary_risk_class }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.packing_group }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.additional_information }}</p>
                        </td>
                        <td>
                            <p class="item-card__text">{{ item.packages_number_and_kind }}</p>
                        </td>
                        <td>
                            <p class="item-card__text">
                                {{ item.mass_or_volume }}
                            </p>
                        </td>
                        <td>
                            <p class="item-card__text">
                                {{ item.ems }}
                            </p>
                        </td>
                        <td>
                            <p class="item-card__text">
                                {{ item.shipping_agent }}
                            </p>
                        </td>
                        <td>
                            <p class="item-card__text">
                                {{ item.place }}
                            </p>
                        </td>
                        <td>
                            <p class="item-card__text">
                                {{ formatDate(item.date) }}
                            </p>
                        </td>
            </tr>
        </tbody>
    </table>

    <!-- <ul class="items-list" v-if="items.length > 0">
        <li class="items-list__item" v-for="item in items" :key="item.id">
            <article class="item-card">
                <div class="item-card__row">
                    <div class="item-card__col item-card__buttons">
                        <a
                            class="button button--red button--sm"
                            @click.prevent="deleteItem(item)"
                        >
                            {{ __("json.Delete") }}
                        </a>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">{{ __("json.ID") }}</h4>
                        <p class="item-card__text item-card__text--ellipsis">
                            {{ item.id }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Stowage") }}
                        </h4>
                        <p class="item-card__text">{{ item.stowage_position }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">{{ __("json.Reference") }}</h4>
                        <p class="item-card__text">{{ item.reference_number }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Marks") }}
                        </h4>
                        <p class="item-card__text">{{ item.marks }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.UN number") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.un_number }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Shipping") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.proper_shipping_name }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Risk class") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.subsidiary_risk_class }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Packing") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.packing_group }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Info") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.additional_information }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Pkg no") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.packages_number_and_kind }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Mass/Vol") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.mass_or_volume }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.EMS") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.ems }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Shiping Agent") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.shipping_agent }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Place") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.place }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Date") }}
                        </h4>
                        <p class="item-card__text">{{ formatDate(item.date) }}</p>
                    </div>
                </div>
            </article>
        </li>
    </ul> -->
    <div v-else>
        <div class="account-empty">
            <img
                class="account-empty__img"
                src="/assets/images/new/empty-img.svg"
                width="80"
                height="80"
                alt="empty"
            />
            <p class="account-empty__text">
                {{ __("items.no_items_yet") }}
            </p>
        </div>
    </div>
</template>
<style>
.items-table {
    width: 100%;
    border-collapse: collapse;
}
.items-table thead tr {
    border: 2px solid #EAEBEF;
    background: rgb(240, 56, 51);
    color: white;
}


.items-table tbody tr:nth-child(2n) {
    background: #EAEBEF;
}

.button--outline-red {
    max-width: 100px;
}
</style>
<script>
import IconLoading from "../partials/IconLoading.vue";
import { useLocalTime } from "../utils/useLocalTime";

export default {
    name: "ItemList",
    props: ["master-id"],
    components: {
        IconLoading,
    },
    setup() {
        const { toLocalTime } = useLocalTime();
        return {
            toLocalTime,
        };
    },
    data() {
        return {
            items: [],
            refreshList: "false",
            itemToDelete: null,
            isLoadingData: false,
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        formatDate(dateString) {
            if (!dateString) {
                return null;
            }
            let date = new Date(dateString);
            let year = date.toLocaleString("default", { year: "numeric" });
            let month = date.toLocaleString("default", { month: "2-digit" });
            let day = date.toLocaleString("default", { day: "2-digit" });

            return year + "-" + month + "-" + day;
        },
        deleteItem(item) {
            this.isLoadingData = true;

            axios
                .delete("/shipments/delete-good/" + item.id)
                .then(() => {
                    this.items = this.items.filter((t) => t.id !== item.id);
                })
                .catch((err) => {
                    console.log(err);
                    alert("Something went wrong! Please, try again.");
                })
                .finally(() => {
                    this.isLoadingData = false;
                });
        },
        loadItems() {
            this.isLoadingData = true;

            axios
                .get("/shipments/goods/" + this.masterId)
                .then(({ data }) => {
                    this.items = data;
                })
                .finally(() => {
                    this.$nextTick(() => {
                        window.initAccordion(".accordion-vue");
                        window.initDropdown(".dropdown-vue");
                        this.isLoadingData = false;
                    });
                });
        },
    },
    computed: {},
    expose: ["loadItems"],
};
</script>
