<template>
    <svg :width="width + 'px'" :height="height + 'px'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" stroke-width="8" :stroke="color" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
    </svg>
</template>

<script>
export default {
    name: "IconLoading",
    props: {
        width: {
            type: Number,
            default: 40
        },
        height: {
            type: Number,
            default: 40
        },
        color: {
            type: String,
            default: '#f03833'
        }
    }
}
</script>

<style scoped>

</style>
