<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingTrip" style="margin-left: 20px;" />
        </header>

        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div" v-if="existingTrip">
            <form @submit="handleSubmit($event, onSubmit)">
                <div class="step-form__row step-form__row--mb-40">
                    <div class="step-form__col">
                        <label for="mrn" class="label">{{ __("json.MRN/TIR/CMR/CIN") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.mrn }">
                            <Field v-model="form.mrn" type="text" name="mrn" v-slot="{field}">
                                <input v-bind="field" class="input" id="mrn" :placeholder="__('json.MRN/TIR/CMR/CIN')">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="mrn" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Brand") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.brand_id }">
                            <Field v-model="form.brand_id" name="brand_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.brand_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select brand')"
                                    :options="brands"
                                    :reduce="brand => brand.id"
                                    :clearable="false"
                                    @option:selected="handleBrandUpdate"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="brand_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="car_owner_name" class="label">{{ __("json.Carrier") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.car_owner_name }">
                            <Field v-model="form.car_owner_name" type="text" name="car_owner_name" v-slot="{field}">
                                <input v-bind="field" class="input" id="car_owner_name" :placeholder="__('json.Carrier')">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="car_owner_name" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Model") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.model_id }">
                            <Field v-model="form.model_id" name="model_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.model_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select model')"
                                    :options="modelsByBrand"
                                    :reduce="model => model.id"
                                    :clearable="false"
                                >
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for <em>{{ search }}</em>.
                                        </template>
                                        <em v-else style="opacity: 0.5">Please, select a brand.</em>
                                    </template>
                                </v-select>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="model_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Country of Registration") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.license_country_id }">
                            <Field v-model="form.license_country_id" name="license_country_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.license_country_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select country')"
                                    :options="countries"
                                    :reduce="country => country.id"
                                    :clearable="false"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="license_country_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="plate_number" class="label">{{ __("json.Truck Number") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.plate_number}">
                            <Field v-model="form.plate_number" type="text" name="plate_number" v-slot="{field}">
                                <input v-bind="field" class="input" id="plate_number" :placeholder="__('json.Enter Truck Number')">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="plate_number" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="date_of_arrival" class="label">{{ __("json.The Expected Date of Arrival to Iraq") }} <span class="red-color">*</span></label>
                        <div class="input-group input-group--w-50" :class="{ 'has-error': errors.date_of_arrival}">
                            <Field v-model="form.date_of_arrival" type="date" name="date_of_arrival" v-slot="{field}">
                                <input v-bind="field" class="input" type="date" id="date_of_arrival" placeholder="00/00/0000">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="date_of_arrival" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="vin" class="label">{{ __("json.VIN") }}</label>
                        <div class="input-group">
                            <input v-model="form.vin" class="input" type="text" id="vin" :placeholder="__('json.Enter VIN Code')">
                        </div>
                    </div>
                </div>
                <div class="step-form-table">
                    <label class="label">
                        {{ __('json.Upload documents') }}
                    </label>

                    <!--cert of origin-->
                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Certificate of Origin and Invoice') }} <span class="red-color">*</span>
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.cert_invoice_files}">
                            <Field v-model="form.files.cert_invoice" name="cert_invoice_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="cert_invoice_pond"
                                    name="cert_invoice_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('cert_invoice')"
                                    v-on:processfile="syncFiles('cert_invoice')"
                                    v-on:removefile="syncFiles('cert_invoice')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="cert_invoice_files" />
                            </div>
                        </div>
                    </div>
                    <!--packingList-->
                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Packing list') }} <span class="red-color">*</span>
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.packing_list_files}">
                            <Field v-model="form.files.packing_list" name="packing_list_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="packing_list_pond"
                                    name="packing_list_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('packing_list')"
                                    v-on:processfile="syncFiles('packing_list')"
                                    v-on:removefile="syncFiles('packing_list')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="packing_list_files" />
                            </div>
                        </div>
                    </div>
                    <!--Bill of Loading or Airway-->
                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Bill Of Lading Or Airway Bill') }}
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.bill_files}">
                            <Field v-model="form.files.bill" name="bill_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="bill_pond"
                                    name="bill_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('bill')"
                                    v-on:processfile="syncFiles('bill')"
                                    v-on:removefile="syncFiles('bill')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="bill_files" />
                            </div>
                        </div>
                    </div>
                    <!--Delivery order-->
                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Delivery order') }}
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.delivery_order_files}">
                            <Field v-model="form.files.delivery_order" name="delivery_order_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="delivery_order_pond"
                                    name="delivery_order_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('delivery_order')"
                                    v-on:processfile="syncFiles('delivery_order')"
                                    v-on:removefile="syncFiles('delivery_order')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="delivery_order_files" />
                            </div>
                        </div>
                    </div>
                    <!--manifst-->
                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Manifest') }}
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.manifest_files}">
                            <Field v-model="form.files.manifest" name="manifest_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="manifest_pond"
                                    name="manifest_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('manifest')"
                                    v-on:processfile="syncFiles('manifest')"
                                    v-on:removefile="syncFiles('manifest')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="manifest_files" />
                            </div>
                        </div>
                    </div>
                    <!--Other files-->
                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Other files') }}
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.other_files}">
                            <Field v-model="form.files.other" name="other_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="other_pond"
                                    name="other_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('other')"
                                    v-on:processfile="syncFiles('other')"
                                    v-on:removefile="syncFiles('other')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="other_files" />
                            </div>
                        </div>
                    </div>
                    <!--Truck Licence-->
                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Truck licence') }} <span class="red-color">*</span>
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.truck_licence_files}">
                            <Field v-model="form.files.truck_licence" name="truck_licence_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="truck_licence_pond"
                                    name="truck_licence_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('truck_licence')"
                                    v-on:processfile="syncFiles('truck_licence')"
                                    v-on:removefile="syncFiles('truck_licence')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="truck_licence_files" />
                            </div>
                        </div>
                    </div>


                </div>
                <div class="step-form__buttons">
                    <button class="button button--blue step-form__button" type="submit">
                        {{ __("json.Next step") }}
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import vSelect from "vue-select";
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import * as yup from 'yup';
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import {useGetExistingTrip} from "../utils/useGetExistingTrip";
import {useLocalTime} from "../utils/useLocalTime";

import vueFilePond from 'vue-filepond';
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    // FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
);

let startDate = new Date();
startDate.setHours(0, 0, 0, 0);
let endDate = new Date(startDate);
endDate = endDate.setFullYear(startDate.getFullYear() + 1);
endDate = new Date(endDate);

const schema = yup.object({
    car_owner_name: yup.string()
        .required(lang('validation.required', { 'attribute': lang('json.Carrier') }))
        .matches(/^([^0-9]*)$/, lang('validation.alpha', {'attribute': lang('json.Carrier')}))
        .nullable(),
    mrn: yup.string()
        .required(lang('validation.required', { 'attribute': lang('json.MRN/TIR/CMR/CIN') })),
    brand_id: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Brand') })),
    model_id: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Model') })),
    plate_number: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Truck Number') })),
    license_country_id: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Country of Registration') })),
    date_of_arrival: yup.date()
        .required(lang('validation.required', { 'attribute': lang('json.The Expected Date of Arrival to Iraq') }))
        .min(startDate, lang('validation.after', { 'attribute': lang('json.The Expected Date of Arrival to Iraq'), 'date': startDate.toLocaleDateString() }))
        .max(endDate, lang('validation.before', { 'attribute': lang('json.The Expected Date of Arrival to Iraq'), 'date': endDate.toLocaleDateString() }))
        .nullable(),
    manifest_files: yup.array()
        //.min(1, lang('validation.min.array', { 'attribute': lang('json.Manifest'), 'min': 1 }))
        .max(10, lang('validation.max.array', { 'attribute': lang('json.Manifest'), 'max': 10 }))
        //.required(lang('validation.required', { 'attribute': lang('json.Manifest') }))
        .nullable(),
    cert_invoice_files: yup.array()
        .min(1, lang('validation.min.array', { 'attribute': lang('json.Certificate of Origin and Invoice'), 'min': 1 }))
        .max(10, lang('validation.max.array', { 'attribute': lang('json.Certificate of Origin and Invoice'), 'max': 10 }))
        .required(lang('validation.required', { 'attribute': lang('json.Certificate of Origin and Invoice') }))
        .nullable(),
    truck_licence_files: yup.array()
        .min(1, lang('validation.min.array', { 'attribute': lang('json.Truck licence'), 'min': 1 }))
        .max(10, lang('validation.max.array', { 'attribute': lang('json.Truck licence'), 'max': 10 }))
        .required(lang('validation.required', { 'attribute': lang('json.Truck licence') }))
        .nullable(),
    packing_list_files: yup.array()
        .min(1, lang('validation.min.array', { 'attribute': lang('json.Packing list'), 'min': 1 }))
        .max(10, lang('validation.max.array', { 'attribute': lang('json.Packing list'), 'max': 10 }))
        .required(lang('validation.required', { 'attribute': lang('json.Packing list') }))
        .nullable(),
    bill_files: yup.array()
        .max(10, lang('validation.max.array', { 'attribute': lang('json.Bill Of Lading Or Airway Bill'), 'max': 10 }))
        .nullable(),
    delivery_order_files: yup.array()
        //.min(1, lang('validation.min.array', { 'attribute': lang('json.Delivery order'), 'min': 1 }))
        .max(10, lang('validation.max.array', { 'attribute': lang('json.Delivery order'), 'max': 10 }))
        //.required(lang('validation.required', { 'attribute': lang('json.Delivery order') }))
        .nullable(),
    other_files: yup.array()
        .max(10, lang('validation.max.array', { 'attribute': lang('json.Other files'), 'max': 10 }))
        .nullable(),
});

export default {
    name: "Step1",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        brands: {
            type: Object,
            required: true
        },
        models: {
            type: Object,
            required: true
        },
        countries: {
            type: Object,
            required: true
        },
        tripType: {
            type: String,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateTripId: {
            type: Number,
            required: false
        },
    },
    components: {
        vSelect,
        VeeForm,
        Field,
        ErrorMessage,
        IconLoading,
        FilePond,
    },
    setup() {
        const { getExistingTrip, existingTrip } = useGetExistingTrip();
        const { toLocalTime } = useLocalTime();
        return {
            getExistingTrip, existingTrip,
            toLocalTime
        }
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                mrn: null,
                brand_id: null,
                model_id: null,
                license_country_id: null,
                plate_number: null,
                car_owner_name: null,
                vin: null,
                date_of_arrival: null,
                files: {
                    manifest: [],
                    cert_invoice: [],
                    truck_licence: [],
                    packing_list: [],
                    bill: [],
                    delivery_order: [],
                    other: [],
                },
            },
            schema,
        }
    },
    mounted() {
        this.getExistingTrip(this.tripType, this.updateTripId).then((data) => {
            if (Object.keys(data).length !== 0) {
                this.updateFormData();
            }
        })
    },
    computed: {
        modelsByBrand() {
            return this.form.brand_id
                ? this.models.filter(model => model.parent_id === this.form.brand_id)
                : [];
        },
        formWithTripType() {
            if (!('tripType' in this.form)) {
                Object.assign(this.form, { 'tripType': this.tripType })
            }
            return this.form;
        }
    },
    emits: ['update:modelValue'],
    methods: {
        async fillStep() {
            let url = "/reservation/fill-step";
            if (this.action === "update") {
                url += "/" + this.existingTrip.id;
            }
            await axios.post(url, this.formWithTripType);
        },
        onSubmit() {
            this.fillStep();
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        updateFormData() {
            this.form.mrn = this.existingTrip ? this.existingTrip.mrn : null;
            this.form.brand_id = this.existingTrip ? this.existingTrip.brand_id : null;
            this.form.model_id = this.existingTrip ? this.existingTrip.model_id : null;
            this.form.license_country_id = this.existingTrip ? this.existingTrip.license_country_id : null;
            this.form.plate_number = this.existingTrip ? this.existingTrip.plate_number : null;
            this.form.car_owner_name = this.existingTrip ? this.existingTrip.car_owner_name : null;
            this.form.vin = this.existingTrip ? this.existingTrip.vin : null;
            this.form.date_of_arrival = this.existingTrip ? this.formatDate(this.existingTrip.date_of_arrival) : null;
        },
        handleBrandUpdate() {
            this.form.model_id = null;
        },
        handleFilePondInit: function (type) {
            this.$refs[type + '_pond']._pond.setOptions({
                server: {
                    process: "/filepond/upload/",
                    load: "/filepond/load/",
                    patch: "?patch=",
                    headers: {
                        "X-CSRF-TOKEN": document
                            .querySelector('meta[name="csrf-token"]')
                            .getAttribute("content"),
                        "fileType": type,
                    },
                },
            });
            this.loadExistingFiles(type);
        },
        loadExistingFiles(type) {
            let filepondUrl = "/filepond/existing-files/" + this.tripType;
            if (this.updateTripId) {
                filepondUrl += "/" + this.updateTripId;
            }
            axios.get(filepondUrl).then((response) => {
                if (response.data[type]) {
                    this.$refs[type + '_pond']._pond.setOptions({ files: response.data[type] });
                    this.syncFiles(type);
                }
            });
        },
        syncFiles(type) {
            let files = [];
            this.$refs[type + '_pond'].getFiles().forEach(file => {
                files.push(file.serverId);
            })
            this.form.files[type] = files;
        },
        formatDate(dateString) {
            let date = new Date(dateString);
            let year = date.toLocaleString("default", { year: "numeric" });
            let month = date.toLocaleString("default", { month: "2-digit" });
            let day = date.toLocaleString("default", { day: "2-digit" });

            return year + "-" + month + "-" + day;
        },
    },
}
</script>
