import moment from "moment/dist/moment";
import "moment/dist/locale/ar";
import "moment/dist/locale/ku";
moment.locale(document.querySelector("html").lang);

export const useLocalTime = () => {
    const toLocalTime = (time, format = 'H:mm DD/MM/YYYY') => {
        return moment.utc(time).local().format(format);
    }

    return { toLocalTime };
}
