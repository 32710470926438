import  '@lottiefiles/lottie-player';
import { create as createInteractive } from '@lottiefiles/lottie-interactivity';
import Splide from '@splidejs/splide';
import "./app/bootstrap";

document.addEventListener('DOMContentLoaded', () => {

    window.initDropdown = function (classSelector) {
        const dropdownAll = document.querySelectorAll(classSelector);

        dropdownAll.forEach(item => {
            const dropdownToggle = item.querySelector('.dropdown-toggle');

            dropdownToggle.addEventListener('click', e => {
                dropdownAll.forEach(item => {
                    (item !== e.target.closest(classSelector)) && item.classList.remove('is-active');
                });

                dropdownToggle.closest(classSelector).classList.toggle('is-active');
            });
        });

        document.addEventListener('click', e => {
            if (!e.target.closest(classSelector)) {
                dropdownAll.forEach(item => {
                    item.classList.remove('is-active');
                });
            }
        });
    }

    // Dropdown
    window.initDropdown('.dropdown');

    window.initAccordion = function(classSelector) {
        const accordionAll = document.querySelectorAll(classSelector);

        accordionAll.forEach(item => {
            const accordionToggle = item.querySelector('.accordion-toggle');
            const accordionDropdown = item.querySelector('.accordion-dropdown');

            if (item.classList.contains('is-active')) {
                accordionDropdown.style.maxHeight = accordionDropdown.scrollHeight + 'px'
            }

            accordionToggle.addEventListener('click', () => {
                accordionToggle.closest(classSelector).classList.toggle('is-active');

                accordionToggle.closest(classSelector + '.is-active')
                    ? accordionDropdown.style.maxHeight = accordionDropdown.scrollHeight + 'px'
                    : accordionDropdown.style.maxHeight = null
            });
        });
    }

    // Accordion
    window.initAccordion('.accordion');

    // Mobile Menu
    const mobileMenuToggle = document.querySelector('.burger');
    const mobileMenu = document.querySelector('.mobile-nav');
    const body = document.querySelector('body');

    if (mobileMenuToggle) {
        mobileMenuToggle.addEventListener('click', () => {
            mobileMenuToggle.classList.toggle('is-active');
            mobileMenu.classList.toggle('is-visible');
            body.classList.toggle('scroll-disabled');
        });
    }


    // Select
    const selectAll = document.querySelectorAll('.js-select');

    selectAll.forEach(item => {
        const selectToggle = item.querySelector('.select__toggle');
        const selectLinkAll = item.querySelectorAll('.select__link');

        selectToggle.addEventListener('click', e => {
            selectAll.forEach(item => {
                (item !== e.target.closest('.select')) && item.classList.remove('is-open');
            });

            selectToggle.closest('.select').classList.toggle('is-open');
        });

        selectLinkAll.forEach(item => {
            item.addEventListener('click', e => {
                const linkText = item.innerText;

                e.preventDefault();

                selectLinkAll.forEach(item => {
                    item.classList.remove('is-selected');
                });

                item.classList.toggle('is-selected');
                item.closest('.select').querySelector('.select__toggle span').textContent = linkText;
                item.closest('.select').querySelector('.select__toggle span').classList.remove('select__placeholder');
                item.closest('.select').classList.toggle('is-open');
            });
        });
    });

    document.addEventListener('click', e => {
        if (!e.target.closest('.js-select')) {
            selectAll.forEach(item => {
                item.classList.remove('is-open');
            });
        }
    });


    // Animation
    if (document.getElementById('about-animation-1')) {
        createInteractive({
            player: '#about-animation-1',
            mode: "cursor",
            actions: [
                {
                    type: "hold",
                    forceFlag: false,
                }
            ]
        });

        createInteractive({
            player: '#about-animation-2',
            mode: "cursor",
            actions: [
                {
                    type: "hold",
                    forceFlag: false,
                }
            ]
        });

        createInteractive({
            player: '#about-animation-3',
            mode: "cursor",
            actions: [
                {
                    type: "hold",
                    forceFlag: false,
                }
            ]
        });

        createInteractive({
            player:'#about-animation-4',
            mode:"scroll",
            actions: [
                {
                    visibility: [0.30, 1.0],
                    type: "playOnce",
                    loop: false,
                }
            ]
        });

        createInteractive({
            player:'#about-animation-5',
            mode:"scroll",
            actions: [
                {
                    visibility: [0.30, 1.0],
                    type: "playOnce",
                    loop: false,
                }
            ]
        });

        createInteractive({
            player:'#about-animation-6',
            mode:"scroll",
            actions: [
                {
                    visibility: [0.30, 1.0],
                    type: "playOnce",
                    loop: false,
                }
            ]
        });
    }


    // Slider
    if (document.querySelector('.about-slider')) {
        new Splide('.about-slider', {
            type: 'loop',
            arrows: false,
            autoplay: false,
            rewind: true,
            drag: false,
            gap: 24,
            perPage: 3,
            pagination: false,
            direction: document.dir,
            breakpoints: {
                767.98: {
                    perPage: 1,
                    pagination: true,
                    drag: true,
                }
            }
        }).mount();
    }

    if (document.querySelector('.promo-slider')) {
        new Splide('.promo-slider', {
            type: 'fade',
            arrows: false,
            autoplay: true,
            interval: 5000,
            rewind: true,
            direction: document.dir,
        }).mount();
    }

    if (document.querySelector('.devices-slider')) {
        new Splide('.devices-slider', {
            type: 'loop',
            arrows: false,
            autoplay: true,
            interval: 5000,
            rewind: true,
            direction: document.dir,
        }).mount();
    }


    // Tabs
    const tabs = document.querySelector('.tabs');
    const tabsBtn = document.querySelectorAll('.tabs__nav-btn');
    const tabsContent = document.querySelectorAll('.tabs-panel');

    if (tabs) {
        tabs.addEventListener('click', (e) => {
            if (e.target.classList.contains('tabs__nav-btn')) {
                const tabsPath = e.target.dataset.tabsPath;
                tabsBtn.forEach(el => {el.classList.remove('is-active')});
                document.querySelector(`[data-tabs-path="${tabsPath}"]`).classList.add('is-active');
                tabsHandler(tabsPath);
            }
        });
    }

    const tabsHandler = (path) => {
        tabsContent.forEach(el => {el.classList.remove('is-active')});
        document.querySelector(`[data-tabs-target="${path}"]`).classList.add('is-active');
    };


    // Up Button
    const upButton = document.querySelector('.up-button');

    if (upButton) {
        const upButtonVisibility = () => {
            if (window.scrollY > 1000) {
                upButton.classList.add('is-visible');
            } else {
                upButton.classList.remove('is-visible');
            }
        };

        upButtonVisibility();

        document.addEventListener('scroll', () => {
            upButtonVisibility();
        });
    }


    // Modal
    const header = document.querySelector(".header");
    const modalWrap = document.querySelector(".modal-wrap");
    const modalAll = document.querySelectorAll(".modal");
    const modalToggleAll = document.querySelectorAll("[data-modal]");
    const modalCloseAll = document.querySelectorAll("[data-modal-close]");
    const paddingRight = window.innerWidth - document.documentElement.clientWidth;

    modalToggleAll.forEach(item => {
        const modalID = item.getAttribute("data-modal");
        const modal = document.getElementById(modalID);

        if (modal) {
            item.addEventListener('click', e => {
                const modalVisible = document.querySelector(".modal.is-visible");

                e.preventDefault();

                if (modalVisible) {
                    modalVisible.classList.remove('is-visible');

                    setTimeout(() => {
                        openModal(modal);
                    }, 250);
                } else {
                    openModal(modal);
                }
            });
        }
    });

    modalAll.forEach(item => {
        item.addEventListener('click', e => {
            if (!e.target.closest('.modal__window')) {
                closeModal(item);
            }
        });
    });

    modalCloseAll.forEach(item => {
        item.addEventListener('click', e => {
            closeModal(item.closest(".modal"));
        });
    });

    const openModal = (modal) => {
        body.classList.add('scroll-disabled');
        body.style.paddingRight = paddingRight + 'px';
        header.style.paddingRight = paddingRight + 'px';
        modalWrap.classList.add('is-visible');
        modal.classList.add('is-visible');
    }
    const closeModal = (modal) => {
        modalWrap.classList.remove('is-visible');
        modal.classList.remove('is-visible');

        setTimeout(() => {
            body.classList.remove('scroll-disabled');
            body.style.paddingRight = '0px';
            header.style.paddingRight = '0px';
        }, 500);
    }
})
