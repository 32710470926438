<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingTrip" style="margin-left: 20px;" />
        </header>

        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div" v-if="existingTrip">
            <form @submit="handleSubmit($event, onSubmit)">
                <div class="step-form__row">
                    <div class="step-form__col">
                        <label for="driver_name" class="label">{{ __("json.Driver Name") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.driver_name }">
                            <Field v-model="form.driver_name" type="text" name="driver_name" v-slot="{field}">
                                <input v-bind="field" class="input" id="driver_name" :placeholder="__('json.Driver Name')">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="driver_name" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label class="label">{{ __("json.Nationality") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.nationality_id }">
                            <Field v-model="form.nationality_id" name="nationality_id" v-slot="{field}">
                                <v-select
                                    v-bind="field"
                                    v-model="form.nationality_id"
                                    label="name"
                                    class="v-select-uruk"
                                    :placeholder="__('json.Select Nationality')"
                                    :options="countries"
                                    :reduce="country => country.id"
                                    :clearable="false"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="nationality_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="passport_id" class="label">{{ __("json.Passport No.") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.passport_id }">
                            <Field v-model="form.passport_id" type="text" name="passport_id" v-slot="{field}">
                                <input v-bind="field" class="input" id="passport_id" :placeholder="__('json.Passport No.')">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="passport_id" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="driver_phone" class="label">{{ __("json.Mobile Number") }}</label>
                        <div class="input-group" :class="{ 'has-error': errors.driver_phone }">
                            <Field v-model="form.driver_phone" name="driver_phone" v-slot="{field}">
                                <div class="input" style="padding: 0 !important;">
                                    <vue-tel-input
                                        class="vti-uruk-input"
                                        v-model="form.driver_phone"
                                        default-country="iq"
                                        :ignored-countries="['il', 'as', 'ag', 'ai', 'bs', 'bm', 'vg', 'ky', 'dm', 'gd', 'gu', 'tc', 'vi', 'tt', 'lc', 'vc', 'kn', 'mp', 'jm', 'ms', 'sx']"
                                        :inputOptions="phoneInputOptions" />
                                </div>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="driver_phone" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-form__buttons">
                    <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                        {{ __("json.Previous step") }}
                    </button>
                    <button class="button button--blue step-form__button" type="submit">
                        {{ __("json.Next step") }}
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import vSelect from "vue-select";
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import {VueTelInput} from "vue-tel-input";
import * as yup from 'yup';
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import {useGetExistingTrip} from "../utils/useGetExistingTrip";

let phoneValidationsCount = 0;

const schema = yup.object({
    driver_name: yup.string()
        .required(lang('validation.required', { 'attribute': lang('json.Driver Name') }))
        .matches(/^([^0-9]*)$/, lang('validation.alpha', {'attribute': lang('json.Driver Name')}))
        .nullable(),
    nationality_id: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Nationality') }))
        .nullable(),
    passport_id: yup.string()
        .required(lang('validation.required', { 'attribute': lang('json.National ID / Passport ID') }))
        .nullable(),
    driver_phone: yup.string()
        .test({
            name: 'phone-regex',
            message: lang('validation.regex', {'attribute': lang('json.Mobile Number')}),
            test: (phone) => {
                phoneValidationsCount += 1;
                if (phoneValidationsCount > 2) {
                    if (phone?.length <= 0) {
                        return true;
                    }

                    let re = new RegExp(/^([+]|[00]{2})([0-9]{9,})+$/g);
                    return re.test(phone);
                }

                return true;
            }
        })
        .nullable(),
});

export default {
    name: "Step2",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        countries: {
            type: Object,
            required: true
        },
        tripType: {
            type: String,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateTripId: {
            type: Number,
            required: false
        },
    },
    components: {
        vSelect,
        VeeForm,
        Field,
        ErrorMessage,
        VueTelInput,
        IconLoading,
    },
    setup() {
        const { getExistingTrip, existingTrip } = useGetExistingTrip();
        return {
            getExistingTrip, existingTrip
        }
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                driver_name: null,
                nationality_id: null,
                passport_id: null,
                driver_phone: null,
            },
            schema,
            phoneInputOptions: {
                autoFormat: true,
                mode: 'international',
                validCharactersOnly: true,
                showDialCode: true,
                placeholder: '+12 000 000 00 00', // this.$root.__("main.phone"),
            },
        }
    },
    mounted() {
        this.getExistingTrip(this.tripType, this.updateTripId).then((data) => {
            if (Object.keys(data).length !== 0) {
                this.updateFormData();
            }
        })
    },
    computed: {
        formWithTripType() {
            if (!('tripType' in this.form)) {
                Object.assign(this.form, { 'tripType': this.tripType })
            }
            return this.form;
        },
    },
    emits: ['update:modelValue'],
    methods: {
        async fillStep() {
            let url = "/reservation/fill-step";
            if (this.action === "update") {
                url += "/" + this.existingTrip.id;
            }
            await axios.post(url, this.formWithTripType);
        },
        onSubmit() {
            this.fillStep();
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit('update:modelValue', _currentStep);
        },
        updateFormData() {
            this.form.driver_name = this.existingTrip ? this.existingTrip.driver_name : null;
            this.form.nationality_id = this.existingTrip ? this.existingTrip.nationality_id : null;
            this.form.passport_id = this.existingTrip ? this.existingTrip.passport_id : null;
            if (this.existingTrip && this.existingTrip.driver_phone) {
                this.form.driver_phone = this.existingTrip.driver_phone;
            }
        },
    },
    watch: {
        'form.driver_phone'() {
            this.form.driver_phone = this.form.driver_phone.replace(' ', '');
        }
    },
}
</script>
