export const markerTrackSatelliteIcon = `<svg width="{{width}}" height="{{height}}" transform="{{transform}}" viewBox="0 0 528 714" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="264" cy="442" r="264" fill="url(#paint0_radial_3226_75859)"/>
<g filter="url(#filter0_ddd_3226_75859)">
<path d="M210.995 272.996C210.995 258.637 222.636 246.996 236.995 246.996H292.969C307.329 246.996 318.969 258.637 318.969 272.996V642.901H210.995V272.996Z" fill="#484848"/>
</g>
<path d="M131.917 79.5872L219.333 237.405C226.028 249.492 238.757 256.993 252.574 256.993H270.646C284.052 256.993 296.467 249.928 303.315 238.403L397.002 80.7195C408.302 61.7 401.061 36.8922 380.734 28.1604C295.736 -8.35213 235.341 -10.3594 148.14 28.4413C128.612 37.1305 121.56 60.8899 131.917 79.5872Z" fill="url(#paint1_linear_3226_75859)"/>
<path d="M212.233 359.973L315.439 359.973L315.439 646.995L212.232 646.995L212.233 359.973Z" fill="#000052"/>
<path d="M203.858 378.352L323.697 378.352L323.697 646.998L203.858 646.998L203.858 378.352Z" fill="#C7C7C7"/>
<path d="M208.002 383L319.592 383L319.592 643.395L208 643.395L208.002 383Z" fill="url(#paint2_linear_3226_75859)"/>
<path d="M322.968 260.675L322.968 372.964L204.997 372.967L204.997 260.675L204.997 260.675C204.995 257.565 205.607 254.486 206.798 251.613C207.989 248.739 209.736 246.129 211.94 243.93C214.143 241.731 216.759 239.987 219.638 238.798C222.518 237.609 225.604 236.998 228.72 237L228.721 237L299.242 237L299.243 237C302.36 236.998 305.446 237.608 308.325 238.797C311.205 239.986 313.821 241.73 316.025 243.929C318.228 246.128 319.976 248.739 321.167 251.612C322.358 254.486 322.97 257.565 322.968 260.675L322.968 260.675Z" fill="url(#paint3_linear_3226_75859)" stroke="#959595" stroke-width="2"/>
<path d="M263.792 277.301C231.831 277.301 212.197 289.914 212.197 289.914L216.797 331.026C232.119 326.87 247.915 324.719 263.79 324.624C279.664 324.741 295.457 326.893 310.784 331.026L315.385 289.912C315.385 289.912 295.753 277.298 263.792 277.301Z" fill="url(#paint4_linear_3226_75859)"/>
<path d="M207.029 365.071L212.197 365.026L212.198 333.632L207.029 297.641L207.029 365.071Z" fill="#00243F"/>
<path d="M315.38 333.636L315.38 365.028L320.55 365.073L320.55 297.645L315.38 333.636Z" fill="#00243F"/>
<path d="M319.84 308.691L319.794 313.196L325.484 314.144L325.486 318.412L336.53 319.602L336.575 315.089L319.84 308.691Z" fill="#000052"/>
<path d="M191.042 319.6L202.087 318.409L202.132 314.093L207.775 313.192L207.73 308.687L191 315.091L191.042 319.6Z" fill="#000052"/>
<path d="M231.094 239.996L229.481 239.996C225.305 239.996 221.209 241.136 217.633 243.292C214.056 245.448 211.136 248.538 209.186 252.231C209.051 252.494 208.986 252.788 208.997 253.083L208.997 258.49C208.998 258.72 209.067 258.944 209.194 259.136C209.321 259.327 209.502 259.477 209.713 259.568C209.924 259.658 210.157 259.685 210.383 259.645C210.61 259.605 210.819 259.5 210.987 259.343L231.094 239.996Z" fill="#FB973B"/>
<path d="M317.074 253.085C317.056 252.792 316.992 252.503 316.884 252.231C314.934 248.538 312.014 245.447 308.437 243.291C304.861 241.135 300.764 239.996 296.588 239.996L294.975 239.996L315.081 259.344C315.248 259.502 315.458 259.608 315.685 259.648C315.911 259.688 316.145 259.661 316.357 259.57C316.568 259.48 316.749 259.329 316.876 259.137C317.003 258.945 317.071 258.72 317.072 258.49L317.074 253.085Z" fill="#FB973B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M264.195 541.349C292.472 541.349 315.395 518.426 315.395 490.149C315.395 461.872 292.472 438.949 264.195 438.949C235.918 438.949 212.995 461.872 212.995 490.149C212.995 518.426 235.918 541.349 264.195 541.349Z" fill="#2F88FF" stroke="#2F88FF" stroke-width="7.51475" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M264.197 456.199L242.417 521.54L264.197 505.982L285.977 521.54L264.197 456.199Z" fill="#43CCF8"/>
<path d="M264.197 456.199L242.417 521.54L264.197 505.982L285.977 521.54L264.197 456.199Z" fill="white"/>
<path d="M264.197 456.199L242.417 521.54L264.197 505.982L285.977 521.54L264.197 456.199Z" stroke="white" stroke-width="9.13349" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="202.997" y="344.973" width="120.971" height="28.993" fill="url(#paint5_linear_3226_75859)"/>
<rect x="202.997" y="344.973" width="120.971" height="28.993" fill="url(#paint6_linear_3226_75859)" fill-opacity="0.2"/>
<defs>
<filter id="filter0_ddd_3226_75859" x="151.995" y="197.996" width="227.974" height="515.904" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1" dy="11"/>
<feGaussianBlur stdDeviation="30"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.51 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3226_75859"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-11" dy="-2"/>
<feGaussianBlur stdDeviation="11"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_3226_75859" result="effect2_dropShadow_3226_75859"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="13" dy="-13"/>
<feGaussianBlur stdDeviation="17.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="effect2_dropShadow_3226_75859" result="effect3_dropShadow_3226_75859"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_3226_75859" result="shape"/>
</filter>
<radialGradient id="paint0_radial_3226_75859" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(264 442) rotate(89.0452) scale(288.04)">
<stop stop-color="{{colorAreal}}" stop-opacity="0.49"/>
<stop offset="0.978481"  stop-color="{{colorOffsetAreal}}" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint1_linear_3226_75859" x1="259.984" y1="89.0333" x2="264.982" y2="304.481" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFD470" stop-opacity="0"/>
<stop offset="1" stop-color="#FFD470"/>
</linearGradient>
<linearGradient id="paint2_linear_3226_75859" x1="257.024" y1="513.488" x2="369.997" y2="513.488" gradientUnits="userSpaceOnUse">
<stop stop-color="#F2F2F2"/>
<stop offset="1" stop-color="#F2F2F2" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_3226_75859" x1="270.481" y1="231.501" x2="270.481" y2="268.492" gradientUnits="userSpaceOnUse">
<stop stop-color="#BEBEBE"/>
<stop offset="1" stop-color="#DDDDDD"/>
</linearGradient>
<linearGradient id="paint4_linear_3226_75859" x1="319.969" y1="307.981" x2="212.197" y2="304.163" gradientUnits="userSpaceOnUse">
<stop offset="0.550114" stop-color="#00243F"/>
<stop offset="0.557292" stop-color="#496377"/>
<stop offset="1" stop-color="#24333F"/>
</linearGradient>
<linearGradient id="paint5_linear_3226_75859" x1="262.983" y1="351.368" x2="262.983" y2="395.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#656565" stop-opacity="0"/>
<stop offset="1" stop-color="#575757"/>
</linearGradient>
<linearGradient id="paint6_linear_3226_75859" x1="263.483" y1="363.468" x2="263.483" y2="373.966" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

`;
export const markerTrackIcon = `<svg width="{{width}}" height="{{height}}" transform="{{transform}}" viewBox="0 0 528 714" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="264" cy="442" r="264" fill="url(#paint0_radial_3226_75862)"/>
<g filter="url(#filter0_ddd_3226_75862)">
<path d="M316.545 273.006C316.545 258.643 304.901 247 290.538 247L234.577 247C220.214 247 208.571 258.643 208.571 273.006L208.571 642.905L316.545 642.905L316.545 273.006Z" fill="#484848"/>
</g>
<path d="M395.619 79.5956L308.21 237.401C301.513 249.491 288.781 256.993 274.96 256.993L256.899 256.993C243.49 256.993 231.072 249.927 224.222 238.398L130.543 80.7281C119.24 61.704 126.483 36.89 146.815 28.1564C231.806 -8.35106 292.198 -10.3579 379.391 28.4374C398.924 37.1283 405.978 60.8937 395.619 79.5956Z" fill="url(#paint1_linear_3226_75862)"/>
<path d="M315.307 359.973L212.1 359.973L212.1 646.995L315.307 646.995L315.307 359.973Z" fill="#000052"/>
<path d="M323.681 378.355L203.843 378.355L203.843 647.002L323.681 647.002L323.681 378.355Z" fill="#AB7A56"/>
<path d="M319.578 382.445L207.988 382.445L207.988 642.841L319.58 642.841L319.578 382.445Z" fill="url(#paint2_linear_3226_75862)"/>
<path d="M204.572 260.675L204.572 260.675L204.572 372.964L322.543 372.966L322.543 260.675L322.543 260.675C322.545 257.565 321.933 254.486 320.742 251.613C319.551 248.74 317.804 246.129 315.6 243.93C313.397 241.731 310.781 239.987 307.902 238.798C305.022 237.609 301.936 236.998 298.82 237L298.819 237L228.298 237L228.297 237C225.181 236.998 222.094 237.609 219.215 238.798C216.335 239.987 213.719 241.73 211.515 243.929C209.312 246.128 207.565 248.739 206.373 251.612C205.182 254.486 204.57 257.565 204.572 260.675Z" fill="url(#paint3_linear_3226_75862)" stroke="#959595" stroke-width="2.00049"/>
<path d="M263.748 277.301C295.71 277.301 315.343 289.914 315.343 289.914L310.743 331.026C295.421 326.87 279.625 324.719 263.75 324.624C247.876 324.741 232.083 326.893 216.757 331.026L212.156 289.912C212.156 289.912 231.787 277.298 263.748 277.301Z" fill="url(#paint4_linear_3226_75862)"/>
<path d="M320.511 365.071L315.343 365.026L315.342 333.632L320.511 297.641L320.511 365.071Z" fill="#00243F"/>
<path d="M212.16 333.632L212.16 365.024L206.991 365.07L206.991 297.641L212.16 333.632Z" fill="#00243F"/>
<path d="M207.701 308.688L207.746 313.192L202.056 314.14L202.055 318.408L191.011 319.598L190.965 315.085L207.701 308.688Z" fill="#000052"/>
<path d="M336.498 319.596L325.454 318.405L325.408 314.089L319.765 313.188L319.811 308.684L336.54 315.087L336.498 319.596Z" fill="#000052"/>
<path d="M296.446 239.996L298.059 239.996C302.235 239.996 306.331 241.136 309.907 243.292C313.484 245.448 316.404 248.538 318.354 252.231C318.489 252.494 318.554 252.788 318.543 253.083L318.543 258.49C318.542 258.72 318.473 258.944 318.346 259.136C318.219 259.327 318.038 259.477 317.827 259.568C317.616 259.658 317.383 259.685 317.157 259.645C316.93 259.605 316.721 259.5 316.553 259.343L296.446 239.996Z" fill="#FB973B"/>
<path d="M210.466 253.085C210.484 252.792 210.548 252.503 210.656 252.231C212.606 248.538 215.526 245.447 219.103 243.291C222.679 241.135 226.776 239.996 230.952 239.996L232.565 239.996L212.459 259.344C212.292 259.502 212.082 259.608 211.855 259.648C211.629 259.688 211.395 259.661 211.184 259.57C210.972 259.48 210.791 259.329 210.664 259.137C210.537 258.945 210.469 258.72 210.468 258.49L210.466 253.085Z" fill="#FB973B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M263.345 541.349C235.068 541.349 212.145 518.426 212.145 490.149C212.145 461.872 235.068 438.949 263.345 438.949C291.622 438.949 314.545 461.872 314.545 490.149C314.545 518.426 291.622 541.349 263.345 541.349Z" fill="#2F88FF" stroke="#2F88FF" stroke-width="7.51659" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M263.343 456.199L285.123 521.54L263.343 505.982L241.563 521.54L263.343 456.199Z" fill="#43CCF8"/>
<path d="M263.343 456.199L285.123 521.54L263.343 505.982L241.563 521.54L263.343 456.199Z" fill="white"/>
<path d="M263.343 456.199L285.123 521.54L263.343 505.982L241.563 521.54L263.343 456.199Z" stroke="white" stroke-width="9.13572" stroke-linecap="round" stroke-linejoin="round"/>
<rect width="120.971" height="28.993" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 324.543 344.973)" fill="url(#paint5_linear_3226_75862)"/>
<rect width="120.971" height="28.993" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 324.543 344.973)" fill="url(#paint6_linear_3226_75862)" fill-opacity="0.2"/>
<defs>
<filter id="filter0_ddd_3226_75862" x="149.556" y="197.988" width="228.004" height="515.934" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.00024" dy="11.0027"/>
<feGaussianBlur stdDeviation="30.0073"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.51 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3226_75862"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-11.0027" dy="-2.00049"/>
<feGaussianBlur stdDeviation="11.0027"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_3226_75862" result="effect2_dropShadow_3226_75862"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="13.0032" dy="-13.0032"/>
<feGaussianBlur stdDeviation="17.5043"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="effect2_dropShadow_3226_75862" result="effect3_dropShadow_3226_75862"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_3226_75862" result="shape"/>
</filter>
<radialGradient id="paint0_radial_3226_75862" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(264 442) rotate(89.0452) scale(288.04)">
<stop stop-color="{{colorAreal}}" stop-opacity="0.49"/>
<stop offset="0.978481" stop-color="{{colorOffsetAreal}}" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint1_linear_3226_75862" x1="267.557" y1="89.0333" x2="262.558" y2="304.481" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFD470" stop-opacity="0"/>
<stop offset="1" stop-color="#FFD470"/>
</linearGradient>
<linearGradient id="paint2_linear_3226_75862" x1="207.988" y1="512.643" x2="319.58" y2="512.643" gradientUnits="userSpaceOnUse">
<stop stop-color="#C09E83"/>
<stop offset="0.0001" stop-color="#A78265"/>
<stop offset="1" stop-color="#CFA98A"/>
</linearGradient>
<linearGradient id="paint3_linear_3226_75862" x1="257.059" y1="231.501" x2="257.059" y2="268.492" gradientUnits="userSpaceOnUse">
<stop stop-color="#BEBEBE"/>
<stop offset="1" stop-color="#DDDDDD"/>
</linearGradient>
<linearGradient id="paint4_linear_3226_75862" x1="207.571" y1="307.981" x2="315.343" y2="304.163" gradientUnits="userSpaceOnUse">
<stop offset="0.550114" stop-color="#00243F"/>
<stop offset="0.557292" stop-color="#496377"/>
<stop offset="1" stop-color="#24333F"/>
</linearGradient>
<linearGradient id="paint5_linear_3226_75862" x1="59.9856" y1="6.39552" x2="59.9856" y2="50.3114" gradientUnits="userSpaceOnUse">
<stop stop-color="#656565" stop-opacity="0"/>
<stop offset="1" stop-color="#575757"/>
</linearGradient>
<linearGradient id="paint6_linear_3226_75862" x1="60.4854" y1="18.4955" x2="60.4854" y2="28.993" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>
`;
export const clusterIcon = `<svg width="70" height="63" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.1299" cy="19" r="12" fill="#E98E49"/>
<path d="M38.1298 19C38.1298 16.5049 37.6384 14.0342 36.6835 11.729C35.7287 9.42383 34.3292 7.32928 32.5648 5.56497C30.8005 3.80066 28.706 2.40113 26.4008 1.44629C24.0956 0.491449 21.6249 -1.09065e-07 19.1298 0V5.7C20.8764 5.7 22.6059 6.04401 24.2195 6.7124C25.8331 7.38079 27.2993 8.36046 28.5343 9.59548C29.7694 10.8305 30.749 12.2967 31.4174 13.9103C32.0858 15.5239 32.4298 17.2534 32.4298 19H38.1298Z" fill="url(#paint0_radial_2_9542)"/>
<path d="M9.62988 35.4545C11.7907 36.702 14.1761 37.5118 16.6499 37.8375C19.1237 38.1631 21.6373 37.9984 24.0474 37.3526C26.4575 36.7068 28.7168 35.5926 30.6964 34.0737C32.6759 32.5548 34.3368 30.6608 35.5844 28.5L30.648 25.65C29.7747 27.1626 28.6121 28.4884 27.2264 29.5516C25.8408 30.6149 24.2592 31.3948 22.5722 31.8468C20.8851 32.2989 19.1255 32.4142 17.3939 32.1862C15.6622 31.9582 13.9925 31.3914 12.4799 30.5181L9.62988 35.4545Z" fill="url(#paint1_radial_2_9542)"/>
<path d="M9.5 2.41564C7.33917 3.66319 5.44522 5.32414 3.92629 7.30365C2.40736 9.28316 1.29319 11.5425 0.647411 13.9526C0.00162691 16.3627 -0.16313 18.8763 0.162548 21.3501C0.488226 23.8239 1.29796 26.2093 2.54552 28.3701L7.48186 25.5201C6.60857 24.0075 6.04176 22.3378 5.81378 20.6061C5.58581 18.8745 5.70114 17.1149 6.15319 15.4278C6.60524 13.7408 7.38515 12.1592 8.4484 10.7736C9.51165 9.38794 10.8374 8.22527 12.35 7.35198L9.5 2.41564Z" fill="url(#paint2_radial_2_9542)"/>
<defs>
<radialGradient id="paint0_radial_2_9542" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19 19) rotate(90) scale(22.5 22.5769)">
<stop offset="0.619792" stop-color="#FAA51A"/>
<stop offset="0.901042" stop-color="white" stop-opacity="0.42"/>
</radialGradient>
<radialGradient id="paint1_radial_2_9542" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19 19) rotate(90) scale(22.5 22.5769)">
<stop offset="0.619792" stop-color="#FAA51A"/>
<stop offset="0.901042" stop-color="white" stop-opacity="0.42"/>
</radialGradient>
<radialGradient id="paint2_radial_2_9542" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19 19) rotate(90) scale(22.5 22.5769)">
<stop offset="0.619792" stop-color="#FAA51A"/>
<stop offset="0.901042" stop-color="white" stop-opacity="0.42"/>
</radialGradient>
</defs>
</svg>
`;
