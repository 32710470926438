<template>
    <section class="search-trip-section">
        <form class="search-form search-trip-section__form" @submit.prevent="search">
            <div class="input-group search-form__group" :class="{ 'has-error': hasError }">
                <input v-model="tripId" class="input search-form__input" type="text" name="searchInput" :placeholder="__('main.enter_trip_id')" required>
                <button class="button button--red search-form__button" type="submit" :disabled="isLoading">
                        <span class="search-form__button-title">
                            <IconLoading v-if="isLoading" />
                            <span v-else>
                                {{ __('main.search') }}
                            </span>
                        </span>
                    <svg class="search-form__button-icon" width="16" height="16">
                        <use xlink:href="/assets/images/new/sprite.svg#search"></use>
                    </svg>
                </button>
                <div class="input-group__error">{{ errorMessage }}</div>
            </div>
        </form>

        <div class="search-trip-section__empty" v-if="trip && trip.expired && tripExpiredDaysAgo">
            <div class="info">
                <img class="info__img" src="/assets/images/new/info-img.svg" alt="info">
                <h4 class="info__title">{{ __('trip-search.trip_closed') }} {{ tripClosedDate }}</h4>
                <p class="info__text">{{ __('json.For any question or assistant, you may send email to') }} <a href="mailto:support@uruk.gov.iq">support@uruk.gov.iq</a></p>
                <div class="info__buttons">
                    <a href="/#contact" class="button button--outline-blue info__button">
                        {{ __('main.contact') }}
                    </a>
                    <a href="/" class="button button--red info__button">
                        {{ __('trip-search.go_to_main_page') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="search-trip" v-else>
            <div class="search-trip__map-wrap">
                <div id="map" ref="map" class="search-trip__map" style="width: 100%; height: 615px;"></div>
            </div>
            <div class="search-trip__legend">
                <TripInfo v-if="trip" :trip="trip" :info-data="infoData"
                          @open-confirm-delivery-modal="openConfirmDeliveryModal"
                          @place-alert-marker="placeAlertMarker"
                />
                <div class="trip-code" v-else-if="showSmsCodeInput">
                    <h2 class="trip-code__title">
                        {{ __('trip-search.view_details') }}
                    </h2>
                    <p class="trip-code__text">{{ __('trip-search.message_with_code_sent') }}</p>
                    <form class="trip-code__form" @submit.prevent="getTripInfo">
                        <div class="input-group trip-code__group" :class="{ 'has-error': !isValidSmsCode }">
                            <div class="trip-code__grid">
                                <input @input="handleCodeInput($event, 1)" class="input trip-code__input" type="text" :value="smsCode[1]" name="searchCode1" ref="searchCode1" maxlength="1" required>
                                <input @input="handleCodeInput($event, 2)" class="input trip-code__input" type="text" :value="smsCode[2]" name="searchCode2" ref="searchCode2" maxlength="1" required>
                                <input @input="handleCodeInput($event, 3)" class="input trip-code__input" type="text" :value="smsCode[3]" name="searchCode3" ref="searchCode3" maxlength="1" required>
                                <input @input="handleCodeInput($event, 4)" class="input trip-code__input" type="text" :value="smsCode[4]" name="searchCode4" ref="searchCode4" maxlength="1" required>
                                <input @input="handleCodeInput($event, 5)" class="input trip-code__input" type="text" :value="smsCode[5]" name="searchCode5" ref="searchCode5" maxlength="1" required>
                                <input @input="handleCodeInput($event, 6)" class="input trip-code__input" type="text" :value="smsCode[6]" name="searchCode6" ref="searchCode6" maxlength="1" required>
                            </div>
                            <div class="input-group__error input-group__error--center">
                                {{ __('trip-search.invalid_code') }}
                            </div>
                        </div>
                        <button class="button button--red trip-code__button" :disabled="!canSubmitSmsCode || isGettingTripInfo">
                            <IconLoading v-if="isGettingTripInfo" />
                            <span v-else>
                                {{ __('main.continue') }}
                            </span>
                        </button>
                    </form>
                </div>
                <div class="info" v-else>
                    <img class="info__img" src="/assets/images/new/info-img.svg" alt="info">
                    <h4 class="info__title">{{ __('trip-search.enter_id_to_view_info') }}</h4>
                    <p class="info__text">{{ __('trip-search.enter_id_to_view_info_full_text') }}</p>
                </div>
            </div>
        </div>
    </section>

    <button id="btn-open-success-modal" data-modal="modal-success" style="display: none"></button>
    <button id="btn-open-confirm-delivery-modal" data-modal="modal-confirm-delivery" style="display: none"></button>

    <div class="modal-wrap">
        <div id="modal-confirm-delivery" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button class="modal__close" aria-label="Close modal" data-modal-close type="button">
                        <svg class="modal__close-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#close-modal"></use>
                        </svg>
                    </button>

                    <h3 class="modal__title">
                        {{ __('trip-search.confirm_delivery_question') }}
                    </h3>
                    <p class="modal__text">&nbsp;</p>
                    <div class="modal__buttons">
                        <button class="button button--outline-blue modal__button" data-modal-close type="button">
                            {{ __('json.Cancel') }}
                        </button>
                        <button class="button button--red modal__button" type="button" :disabled="isConfirming" @click.prevent="confirmDelivery">
                            <IconLoading v-if="isConfirming" />
                            <span v-else>{{ __('trip-search.confirm') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="modal-success" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button class="modal__close" aria-label="Close modal" data-modal-close type="button">
                        <svg class="modal__close-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#close-modal"></use>
                        </svg>
                    </button>

                    <svg class="modal__icon" width="60" height="60">
                        <use xlink:href="/assets/images/new/sprite.svg#check-circle"></use>
                    </svg>
                    <h3 class="modal__title">
                        {{ __('json.Success') }}
                    </h3>
                    <p class="modal__text">
                        {{ __('trip-search.confirmed_successfully') }}
                    </p>

                    <div class="modal__buttons">
                        <button class="button button--red modal__button" data-modal-close="" type="button">
                            {{ __('json.OK') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconLoading from "./partials/IconLoading.vue";
import TripInfo from "./partials/TripSearch/TripInfo.vue";
import {markerTrackIcon} from "./partials/TripSearch/markerTrack.js";
import { blueFlag, redFlag, alertIcon } from "./partials/TripSearch/flagIcon.js";

export default {
    props: ['id', 'code'],
    components: {
        IconLoading,
        TripInfo,
    },
    data() {
        return {
            center: {
                lat: 33.5,
                lng: 44.2
            },
            tripId: this.id,
            isValidTripId: false,
            hasError: false,
            errorMessage: '',
            map: null,
            infoData: null,
            trip: null,
            isLoading: false,
            isGettingTripInfo: false,
            showSmsCodeInput: false,
            smsCode: {},
            isValidSmsCode: true,
            truckMarker: null,
            alertMarker: null,
            isConfirming: false,
        }
    },
    mounted() {
        if (this.code) {
            this.code.split('').forEach((c, idx) => {
                this.smsCode[idx + 1] = c;
            });
        }
        if (this.tripId) {
            this.checkTripId();
        }
        this.initGoogleMaps();
        this.getUserCurrentPosition();
    },
    computed: {
        canSubmitSmsCode() {
            return this.smsCode && Object.keys(this.smsCode).length === 6;
        },
        stringifiedSmsCode() {
            return this.smsCode && Object.keys(this.smsCode).map(key => this.smsCode[key]).join('');
        },
        tripClosedDate() {
            let closedDate = new Date(this.trip.expiration_time);
            return closedDate.toLocaleString();
        },
        tripExpiredDaysAgo() {
            let closedDate = new Date(this.trip.expiration_time);
            closedDate.setDate(closedDate.getDate() + 7);
            let now = new Date();
            return now > closedDate;
        }
    },
    watch: {
        tripId: function (newValue) {
            this.hasError = false;
            this.syncUrl(newValue);
        }
    },
    methods: {
        getUserCurrentPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    // this.map.setCenter(this.center);
                });
            }
        },
        initGoogleMaps() {
            window.loader.load().then(() => {
                if (this.$refs.map) {
                    this.setGoogleMap();
                } else {
                    this.$nextTick(() => {
                        this.setGoogleMap();
                    })
                }
            });
        },
        setGoogleMap() {
            this.map = new google.maps.Map(this.$refs.map, {
                center: this.center,
                zoom: 11,
            });
        },
        search() {
            if (this.trip && this.trip.expired) {
                this.initGoogleMaps();
            }
            this.showSmsCodeInput = false;
            this.checkTripId();
        },
        async getTripInfo() {
            this.isGettingTripInfo = true;
            try {
                let {data} = await axios.post('/api/trips/search', {
                    trip_id: this.tripId,
                    sms_code: this.stringifiedSmsCode
                });
                this.infoData = data.data;
                this.trip = this.infoData.trip;
                this.updateMap();
            } catch (error) {
                console.log(error);
                if (error.response.status === 404) {
                    this.isValidSmsCode = false;
                }
            } finally {
                this.isGettingTripInfo = false;
            }
        },
        updateMap() {
            this.setMapCenter(parseFloat(this.infoData.route.center.lat), parseFloat(this.infoData.route.center.lng));
            this.buildBaseRoute();
            this.buildRoute();
            this.placeFlags();
            this.placeTruck();
        },
        setMapCenter(latitude, longitude) {
            this.map.setCenter({ lat: latitude, lng: longitude });
        },
        placeMarker(latitude, longitude, icon) {
            return new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: this.map,
                icon: icon
            });
        },
        buildBaseRoute() {
            const color = '#A9ACB1';
            this.placePolyline(this.infoData.route.base_route, color);
        },
        buildRoute() {
            const color = '#129453';
            const strokeWeight = 3;
            this.placePolyline(this.infoData.route.route, color, strokeWeight);
        },
        placePolyline(coords, color, weight = 2) {
            const route = new google.maps.Polyline({
                path: coords,
                geodesic: true,
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: weight,
            });

            route.setMap(this.map);
        },
        placeFlags() {
            let startCoords = this.infoData.route.route[0];
            let finishCoords = this.infoData.route.route[this.infoData.route.route.length - 1];

            let redFlagIcon = {
                url: `data:image/svg+xml;charset=utf-8,
                    ${encodeURIComponent(
                    redFlag
                        .replace("{{width}}", "24")
                        .replace("{{height}}", "24")
                )}`
            };

            let blueFlagIcon = {
                url: `data:image/svg+xml;charset=utf-8,
                    ${encodeURIComponent(
                    blueFlag
                        .replace("{{width}}", "24")
                        .replace("{{height}}", "24")
                )}`
            };

            this.placeMarker(startCoords.lat, startCoords.lng, blueFlagIcon);
            this.placeMarker(finishCoords.lat, finishCoords.lng, redFlagIcon);
        },
        async placeTruck() {
            let truckCoords = this.infoData.route.route[this.infoData.route.route.length - 1];

            let iconOptions = {
                image: markerTrackIcon,
                colorAreal: '#FFFFFF',
                colorOffsetAreal: '#FFFFFF',
                rotation: 0,
                width: 72,
                height: 72,
                anchorX: 36,
                anchorY: 36,
            };
            let icon = this.getImageIcon(iconOptions);

            this.truckMarker = this.placeMarker(truckCoords.lat, truckCoords.lng, icon);

            let updateTruckLocationInterval = setInterval(async () => {
                try {
                    let {data} = await axios.post('/api/trips/current-location', {
                        trip_id: this.tripId,
                        sms_code: this.stringifiedSmsCode
                    });
                    let currentLocation = data.data;
                    if (currentLocation.lat && currentLocation.lng) {
                        iconOptions.rotation = currentLocation.azimuth;
                        icon = this.getImageIcon(iconOptions);
                        this.truckMarker.setIcon(icon);
                        this.truckMarker.setPosition(new google.maps.LatLng(currentLocation.lat, currentLocation.lng));
                    } else {
                        clearInterval(updateTruckLocationInterval);
                    }
                } catch (error) {
                    console.log(error);
                    clearInterval(updateTruckLocationInterval);
                }
            }, 5000);
        },
        async checkTripId() {
            this.isLoading = true;
            this.isValidTripId = false;
            this.trip = null;

            try {
                let {data} = await axios.post('/api/trips/exist', {
                    trip_id: this.tripId
                });
                if (data.exist) {
                    this.showSmsCodeInput = true;
                    this.isValidTripId = true;
                    this.isLoading = false;
                    if (this.canSubmitSmsCode) {
                        await this.getTripInfo();
                    } else {
                        this.handleSmsCodeInputFocus(1);
                    }
                } else {
                    this.hasError = true;
                    this.errorMessage = this.$root.__('trip-search.wrong_trip_id');
                }
            } catch(error) {
                this.hasError = true;
                this.errorMessage = this.$root.__('trip-search.went_wrong_try_again');
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        handleCodeInput(event, id) {
            this.isValidSmsCode = true;

            if (event.inputType === 'insertText') {
                this.smsCode[id] = +event.target.value;
                if (id < 6) {
                    this.handleSmsCodeInputFocus(id + 1);
                }
            } else {
                delete this.smsCode[id];
            }
        },
        handleSmsCodeInputFocus(id) {
            this.$nextTick(() => {
                let ref = 'searchCode' + id;
                let input = this.$refs[ref];
                input.focus();
            });
        },
        syncUrl(value) {
            window.history.replaceState("", "", "/search-trip/" + value);
        },
        getImageIcon(options) {
            return {
                url: `data:image/svg+xml;charset=utf-8,
                    ${encodeURIComponent(
                        options.image
                            .replace("{{width}}", options.width)
                            .replace("{{height}}", options.height)
                            .replace("{{transform}}", `rotate(${options.rotation},0,0)`)
                            .replace("{{colorAreal}}", options.colorAreal)
                            .replace("{{colorOffsetAreal}}", options.colorOffsetAreal)
                    )}`,
                anchor: new window.google.maps.Point(options.anchorX, options.anchorY),
                origin: new window.google.maps.Point(0, 0),
                scale: 1,
            };
        },
        confirmDelivery() {
            this.isConfirming = true;

            axios
                .post('/api/trips/confirm-delivery', {
                    trip_id: this.tripId,
                    sms_code: this.stringifiedSmsCode
                })
                .then(() => {
                    this.trip.is_cargo_delivered = true;
                    document.querySelector("#modal-confirm-delivery [data-modal-close]").click();
                    document.getElementById('btn-open-success-modal').click();
                })
                .catch((err) => {
                    console.log(err);
                    alert("Something went wrong! Please, try again.");
                    document.querySelector("#modal-confirm-delivery [data-modal-close]").click();
                })
                .finally(() => {
                    this.isConfirming = false;
                });
        },
        openConfirmDeliveryModal() {
            document.getElementById('btn-open-confirm-delivery-modal').click();
        },
        placeAlertMarker(latLng) {
            let alertIconUrl = {
                url: `data:image/svg+xml;charset=utf-8,
                    ${encodeURIComponent(
                    alertIcon
                        .replace("{{width}}", "24")
                        .replace("{{height}}", "24")
                )}`
            };

            this.alertMarker = this.placeMarker(latLng.lat, latLng.lng, alertIconUrl);
        },
    }
}
</script>
