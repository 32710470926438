<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingShipment" style="margin-left: 20px;" />
        </header>

        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div" v-if="existingShipment">
            <form @submit="handleSubmit($event, onSubmit)">
                <div class="step-form__row step-form__row--mb-40">

                    <div class="step-form__col">
                        <label for="ship_name" class="label">{{ __("json.Ship name") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.ship_name }">
                            <Field v-model="form.ship_name" type="text" name="ship_name" v-slot="{field}">
                                <input v-bind="field" class="input" id="ship_name" :placeholder="__('json.Ship name')">
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="ship_name" />
                            </div>
                        </div>
                    </div>

                    <div class="step-form__col">
                            <label for="imo_number" class="label">{{ __("json.IMO Number") }} <span class="red-color">*</span></label>
                            <div class="input-group" :class="{ 'has-error': errors.imo_number }">
                                <Field v-model="form.imo_number" type="text" name="imo_number" v-slot="{ field }">
                                    <input v-bind="field" class="input" id="imo_number" :placeholder="__('json.IMO number')">
                                </Field>
                                <div class="input-group__error">
                                    <ErrorMessage name="imo_number" />
                                </div>
                            </div>
                        </div>

                        <div class="step-form__col">
                                <label for="call_sign" class="label">{{ __("json.Call sign") }} <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.call_sign }">
                                    <Field v-model="form.call_sign" type="text" name="call_sign" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="call_sign" :placeholder="__('json.Call sign')">
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="call_sign" />
                                    </div>
                                </div>
                            </div>

                             <div class="step-form__col">
                                <label for="voyage_number" class="label">{{ __("json.Voyage Number") }} <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.voyage_number }">
                                    <Field v-model="form.voyage_number" type="text" name="voyage_number" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="voyage_number" :placeholder="__('json.Voyage number')">
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="voyage_number" />
                                    </div>
                                </div>
                            </div>

                        <div class="step-form__col">
                            <label class="label">{{ __("json.Port") }} <span class="red-color">*</span></label>
                            <div class="input-group" :class="{ 'has-error': errors.port_id }">
                                <Field v-model="form.port_id" name="port_id" v-slot="{ field }">
                                    <v-select
                                        v-bind="field"
                                        v-model="form.port_id"
                                        label="name"
                                        class="v-select-uruk"
                                        :placeholder="__('json.Select Port')"
                                        :options="ports"
                                        :reduce="port => port.id"
                                        :clearable="false"
                                    />
                                </Field>
                                <div class="input-group__error">
                                    <ErrorMessage name="port_id" />
                                </div>
                            </div>
                        </div>
                        <div class="step-form__col">
                            <label for="shipment_date" class="label">{{ __("json.Shipment date") }} <span class="red-color">*</span></label>
                            <div class="input-group input-group--w-50" :class="{ 'has-error': errors.shipment_date }">
                                <Field v-model="form.shipment_date" type="date" name="shipment_date" v-slot="{ field }">
                                    <input v-bind="field" class="input" type="date" id="shipment_date" placeholder="00/00/0000">
                                </Field>
                                <div class="input-group__error">
                                    <ErrorMessage name="shipment_date" />
                                </div>
                            </div>
                        </div>

                        <div class="step-form__col">
                                <label class="label">{{ __("json.Flag") }} <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.flag_country_id }">
                                    <Field v-model="form.flag_country_id" name="flag_country_id" v-slot="{ field }">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.flag_country_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select country')"
                                            :options="countries"
                                            :reduce="country => country.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="flag_country_id" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                    <label for="master_name" class="label">{{ __("json.Master name") }} <span class="red-color">*</span></label>
                                    <div class="input-group" :class="{ 'has-error': errors.master_name }">
                                        <Field v-model="form.master_name" type="text" name="master_name" v-slot="{ field }">
                                            <input v-bind="field" class="input" id="master_name" :placeholder="__('json.Master name')">
                                        </Field>
                                        <div class="input-group__error">
                                            <ErrorMessage name="master_name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="step-form__col">
                                    <label class="label">{{ __("json.Last country") }} <span class="red-color">*</span></label>
                                    <div class="input-group" :class="{ 'has-error': errors.last_country_id }">
                                        <Field v-model="form.last_country_id" name="last_country_id" v-slot="{ field }">
                                            <v-select
                                                v-bind="field"
                                                v-model="form.last_country_id"
                                                label="name"
                                                class="v-select-uruk"
                                                :placeholder="__('json.Select Country')"
                                                :options="countries"
                                                :reduce="country => country.id"
                                                :clearable="false"
                                            />
                                        </Field>
                                        <div class="input-group__error">
                                            <ErrorMessage name="last_country_id" />
                                        </div>
                                    </div>
                                </div>
                                <div class="step-form__col">
                                <label class="label">{{ __("json.Last port") }} <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.last_port_id }">
                                    <Field v-model="form.last_port_id" name="last_port_id" v-slot="{ field }">
                                        <v-select
                                            v-bind="field"
                                            v-model="form.last_port_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="__('json.Select Port')"
                                            :options="ports"
                                            :reduce="port => port.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="last_port_id" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                        <label class="label">{{ __("json.Next country") }} <span class="red-color">*</span></label>
                                        <div class="input-group" :class="{ 'has-error': errors.next_country_id }">
                                            <Field v-model="form.next_country_id" name="next_country_id" v-slot="{ field }">
                                                <v-select
                                                    v-bind="field"
                                                    v-model="form.next_country_id"
                                                    label="name"
                                                    class="v-select-uruk"
                                                    :placeholder="__('json.Select Country')"
                                                    :options="countries"
                                                    :reduce="country => country.id"
                                                    :clearable="false"
                                                />
                                            </Field>
                                            <div class="input-group__error">
                                                <ErrorMessage name="next_country_id" />
                                            </div>
                                        </div>
                                    </div>
                                <div class="step-form__col">
                                    <label class="label">{{ __("json.Next port") }} <span class="red-color">*</span></label>
                                    <div class="input-group" :class="{ 'has-error': errors.next_port_id }">
                                        <Field v-model="form.next_port_id" name="next_port_id" v-slot="{ field }">
                                            <v-select
                                                v-bind="field"
                                                v-model="form.next_port_id"
                                                label="name"
                                                class="v-select-uruk"
                                                :placeholder="__('json.Select Port')"
                                                :options="ports"
                                                :reduce="port => port.id"
                                                :clearable="false"
                                            />
                                        </Field>
                                        <div class="input-group__error">
                                            <ErrorMessage name="next_port_id" />
                                        </div>
                                    </div>
                                </div>
                                <div class="step-form__col">
                                        <label class="label">{{ __("json.Certificate registry port") }} <span class="red-color">*</span></label>
                                        <div class="input-group" :class="{ 'has-error': errors.cert_reg_port_id }">
                                            <Field v-model="form.cert_reg_port_id" name="cert_reg_port_id" v-slot="{ field }">
                                                <v-select
                                                    v-bind="field"
                                                    v-model="form.cert_reg_port_id"
                                                    label="name"
                                                    class="v-select-uruk"
                                                    :placeholder="__('json.Select Port')"
                                                    :options="ports"
                                                    :reduce="port => port.id"
                                                    :clearable="false"
                                                />
                                            </Field>
                                            <div class="input-group__error">
                                                <ErrorMessage name="cert_reg_port_id" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-form__col">
                                <label for="cert_reg_date" class="label">{{ __("json.Certificate registry date") }} <span class="red-color">*</span></label>
                                <div class="input-group input-group--w-50" :class="{ 'has-error': errors.cert_reg_date }">
                                    <Field v-model="form.cert_reg_date" type="date" name="cert_reg_date" v-slot="{ field }">
                                        <input v-bind="field" class="input" type="date" id="cert_reg_date" placeholder="00/00/0000">
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="cert_reg_date" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                        <label for="cert_reg_number" class="label">{{ __("json.Certificate registry number") }} <span class="red-color">*</span></label>
                                        <div class="input-group" :class="{ 'has-error': errors.cert_reg_number }">
                                            <Field v-model="form.cert_reg_number" type="text" name="cert_reg_number" v-slot="{ field }">
                                                <input v-bind="field" class="input" id="cert_reg_number" :placeholder="__('json.Certificate registry number')">
                                            </Field>
                                            <div class="input-group__error">
                                                <ErrorMessage name="cert_reg_number" />
                                            </div>
                                        </div>
                                  </div>
                                  <div class="step-form__col">
                                            <label for="agent_full_name" class="label">{{ __("json.Agent name") }} <span class="red-color">*</span></label>
                                            <div class="input-group" :class="{ 'has-error': errors.agent_full_name }">
                                                <Field v-model="form.agent_full_name" type="text" name="agent_full_name" v-slot="{ field }">
                                                    <input v-bind="field" class="input" id="agent_full_name" :placeholder="__('json.Agent name')">
                                                </Field>
                                                <div class="input-group__error">
                                                    <ErrorMessage name="agent_full_name" />
                                                </div>
                                            </div>
                                      </div>
                                       <div class="step-form__col">
                                                <label for="agent_address" class="label">{{ __("json.Agent address") }} <span class="red-color">*</span></label>
                                                <div class="input-group" :class="{ 'has-error': errors.agent_address }">
                                                    <Field v-model="form.agent_address" type="text" name="agent_address" v-slot="{ field }">
                                                        <input v-bind="field" class="input" id="agent_address" :placeholder="__('json.Agent address')">
                                                    </Field>
                                                    <div class="input-group__error">
                                                        <ErrorMessage name="agent_address" />
                                                    </div>
                                                </div>
                                          </div>
                                          <div class="step-form__col">
                                                    <label for="agent_email" class="label">{{ __("json.Agent email") }} <span class="red-color">*</span></label>
                                                    <div class="input-group" :class="{ 'has-error': errors.agent_email }">
                                                        <Field v-model="form.agent_email" type="text" name="agent_email" v-slot="{ field }">
                                                            <input v-bind="field" class="input" type="email" id="agent_email" :placeholder="__('json.Agent email')">
                                                        </Field>
                                                        <div class="input-group__error">
                                                            <ErrorMessage name="agent_email" />
                                                        </div>
                                                    </div>
                                              </div>
                                              <div class="step-form__col">
                                                    <label for="agent_phone_number" class="label">{{ __("json.Agent phone") }} <span class="red-color">*</span></label>
                                                    <div class="input-group" :class="{ 'has-error': errors.agent_phone_number }">
                                                        <Field v-model="form.agent_phone_number" type="text" name="agent_phone_number" v-slot="{ field }">
                                                            <input v-bind="field" class="input" id="agent_phone_number" :placeholder="__('json.Agent phone')">
                                                        </Field>
                                                        <div class="input-group__error">
                                                            <ErrorMessage name="agent_phone_number" />
                                                        </div>
                                                    </div>
                                              </div>
                                              <div class="step-form__col">
                                                    <label for="gross_tonnage" class="label">{{ __("json.Gross tonnage") }} <span class="red-color">*</span></label>
                                                    <div class="input-group" :class="{ 'has-error': errors.gross_tonnage }">
                                                        <Field v-model="form.gross_tonnage" type="text" name="gross_tonnage" v-slot="{ field }">
                                                            <input v-bind="field" class="input" id="gross_tonnage" :placeholder="__('json.Gross tonnage')">
                                                        </Field>
                                                        <div class="input-group__error">
                                                            <ErrorMessage name="gross_tonnage" />
                                                        </div>
                                                    </div>
                                              </div>
                                              <div class="step-form__col">
                                                        <label for="net_tonnage" class="label">{{ __("json.Net tonnage") }} <span class="red-color">*</span></label>
                                                        <div class="input-group" :class="{ 'has-error': errors.net_tonnage }">
                                                            <Field v-model="form.net_tonnage" type="text" name="net_tonnage" v-slot="{ field }">
                                                                <input v-bind="field" class="input" id="net_tonnage" :placeholder="__('json.Net tonnage')">
                                                            </Field>
                                                            <div class="input-group__error">
                                                                <ErrorMessage name="net_tonnage" />
                                                            </div>
                                                        </div>
                                                  </div>

                                                  <div class="step-form__col">
                                                        <label for="voyage_brief" class="label">{{ __("json.Voyage brief") }} <span class="red-color">*</span></label>
                                                        <div class="input-group" :class="{ 'has-error': errors.voyage_brief }">
                                                            <Field v-model="form.voyage_brief" type="text" name="voyage_brief" v-slot="{ field }">
                                                                <textarea rows="3" v-bind="field" class="input" id="voyage_brief" :placeholder="__('json.Voyage brief')"></textarea>
                                                            </Field>
                                                            <div class="input-group__error">
                                                                <ErrorMessage name="voyage_brief" />
                                                            </div>
                                                        </div>
                                                  </div>
                                                  <div class="step-form__col">
                                                            <label for="cargo_brief" class="label">{{ __("json.Cargo brief") }} <span class="red-color">*</span></label>
                                                            <div class="input-group" :class="{ 'has-error': errors.cargo_brief }">
                                                                <Field v-model="form.cargo_brief" type="text" name="cargo_brief" v-slot="{ field }">
                                                                    <textarea rows="3" v-bind="field" class="input" id="cargo_brief" :placeholder="__('json.Cargo brief')"></textarea>
                                                                </Field>
                                                                <div class="input-group__error">
                                                                    <ErrorMessage name="cargo_brief" />
                                                                </div>
                                                            </div>
                                                      </div>
                                                      <div class="step-form__col">
                                                                <label for="remarks" class="label">{{ __("json.Remarks") }} <span class="red-color">*</span></label>
                                                                <div class="input-group" :class="{ 'has-error': errors.remarks }">
                                                                    <Field v-model="form.remarks" type="text" name="remarks" v-slot="{ field }">
                                                                        <textarea rows="3" v-bind="field" class="input" id="remarks" :placeholder="__('json.Remarks')"></textarea>
                                                                    </Field>
                                                                    <div class="input-group__error">
                                                                        <ErrorMessage name="remarks" />
                                                                    </div>
                                                                </div>
                                                          </div>
                                                          <div class="step-form__col">
                                                            <label for="days_in_arrival_port" class="label">{{ __("json.Days in arrival port") }} <span class="red-color">*</span></label>
                                                            <div class="input-group" :class="{ 'has-error': errors.days_in_arrival_port }">
                                                                <Field v-model="form.days_in_arrival_port" type="text" name="days_in_arrival_port" v-slot="{ field }">
                                                                    <input v-bind="field" class="input" type="number" id="days_in_arrival_port" :placeholder="__('json.Days in arrival port')">
                                                                </Field>
                                                                <div class="input-group__error">
                                                                    <ErrorMessage name="days_in_arrival_port" />
                                                                </div>
                                                            </div>
                                                      </div>
                                                      <div class="step-form__col">
                                                                    <label for="waste_description" class="label">{{ __("json.Waste description") }} <span class="red-color">*</span></label>
                                                                    <div class="input-group" :class="{ 'has-error': errors.waste_description }">
                                                                        <Field v-model="form.waste_description" type="text" name="waste_description" v-slot="{ field }">
                                                                            <textarea rows="3" v-bind="field" class="input" id="waste_description" :placeholder="__('json.Waste description')"></textarea>
                                                                        </Field>
                                                                        <div class="input-group__error">
                                                                            <ErrorMessage name="waste_description" />
                                                                        </div>
                                                                    </div>
                                                              </div>
                                                              <div class="step-form__col">
                                                                <label for="person_count" class="label">{{ __("json.Persons count") }} <span class="red-color">*</span></label>
                                                                <div class="input-group" :class="{ 'has-error': errors.person_count }">
                                                                    <Field v-model="form.person_count" type="text" name="person_count" v-slot="{ field }">
                                                                        <input v-bind="field" class="input" type="number" id="person_count" :placeholder="__('json.Persons count')">
                                                                    </Field>
                                                                    <div class="input-group__error">
                                                                        <ErrorMessage name="person_count" />
                                                                    </div>
                                                                </div>
                                                          </div>













                </div>
                <div class="step-form-table">
                    <label class="label">
                        {{ __('json.Upload documents') }}
                    </label>

                    <div class="step-form-table__item">
                        <div class="step-form-table__document">
                            <h4 class="step-form-table__title">
                                {{ __('json.Files') }} <span class="red-color">*</span>
                            </h4>
                        </div>

                        <div class="input-group" :class="{ 'has-error': errors.shipment_files}">
                            <Field v-model="form.files.shipment" name="shipment_files" v-slot="{field}">
                                <file-pond
                                    credits="false"
                                    ref="shipment_pond"
                                    name="shipment_files"
                                    accepted-file-types="image/png, image/jpeg, image/jpg, application/pdf"
                                    max-file-size="1MB"
                                    max-files="10"
                                    v-bind:allow-multiple="true"
                                    v-on:init="handleFilePondInit('shipment')"
                                    v-on:processfile="syncFiles('shipment')"
                                    v-on:removefile="syncFiles('shipment')"
                                />
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="shipment_files" />
                            </div>
                        </div>
                    </div>










                </div>
                <div class="step-form__buttons">
                    <button class="button button--blue step-form__button" type="submit">
                        {{ __("json.Next step") }}
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import vSelect from "vue-select";
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import * as yup from 'yup';
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import {useGetExistingShipment} from "../utils/useGetExistingShipment";
import {useLocalTime} from "../utils/useLocalTime";

import vueFilePond from 'vue-filepond';
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    // FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
);

let startDate = new Date();
startDate.setHours(0, 0, 0, 0);
let endDate = new Date(startDate);
endDate = endDate.setFullYear(startDate.getFullYear() + 1);
endDate = new Date(endDate);

const schema = yup.object({
    ship_name: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Ship name') })),
    imo_number:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.IMO Number') })),
    call_sign: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Call sign') })),
    voyage_number: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Voyage Number') })),
    port_id:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Port') })),
    shipment_date:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Shipment date') })),
    flag_country_id: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Flag') })),
    master_name: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Master name') })),
    last_port_id:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Last port') })),
    last_country_id:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Last country') })),
    next_country_id: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Next country') })),
    next_port_id:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Next port') })),
    cert_reg_port_id:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Certificate registry port') })),
    cert_reg_date:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Certificate registry date') })),
    cert_reg_number:yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Certificate registry number') })),
    agent_full_name: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Agent name') })),
    agent_address: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Agent address') })),
    agent_email: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Agent email') })),
    agent_phone_number: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Agent phone') })),
    gross_tonnage: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Gross tonnage') })),
    net_tonnage: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Net tonnage') })),
    voyage_brief: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Voyage brief') })),
    cargo_brief: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Cargo brief') })),
    remarks: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Remarks') })),
    days_in_arrival_port: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Days in arrival port') })),
    waste_description: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Waste description') })),
    person_count: yup.mixed().required(lang('validation.required', { 'attribute': lang('json.Persons count') })),
    // car_owner_name: yup.string()
    //     .required(lang('validation.required', { 'attribute': lang('json.Owner name') }))
    //     .matches(/^([^0-9]*)$/, lang('validation.alpha', {'attribute': lang('json.Owner name')}))
    //     .nullable(),
    // brand_id: yup.mixed()
    //     .required(lang('validation.required', { 'attribute': lang('json.Brand') })),
    // model_id: yup.mixed()
    //     .required(lang('validation.required', { 'attribute': lang('json.Model') })),
    // plate_number: yup.mixed()
    //     .required(lang('validation.required', { 'attribute': lang('json.Plate number') })),
    // license_country_id: yup.mixed()
    //     .required(lang('validation.required', { 'attribute': lang('json.Vehicles registration country') })),
    // date_of_arrival: yup.date()
    //     .required(lang('validation.required', { 'attribute': lang('json.The Expected Date of Arrival to Iraq') }))
    //     .min(startDate, lang('validation.after', { 'attribute': lang('json.The Expected Date of Arrival to Iraq'), 'date': startDate.toLocaleDateString() }))
    //     .max(endDate, lang('validation.before', { 'attribute': lang('json.The Expected Date of Arrival to Iraq'), 'date': endDate.toLocaleDateString() }))
    //     .nullable(),
    // manifest_files: yup.array()
    //     .min(0, lang('validation.min.array', { 'attribute': lang('json.Manifest'), 'min': 1 }))
    //     .max(10, lang('validation.max.array', { 'attribute': lang('json.Manifest'), 'max': 10 }))
    //     .required(lang('validation.required', { 'attribute': lang('json.Manifest') }))
    //     .nullable(),


});

export default {
    name: "Step1",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        ports: {
            type: Object,
            required: true
        },
        countries: {
            type: Object,
            required: true
        },
        shipmentType: {
            type: String,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateShipmentId: {
            type: Number,
            required: false
        },
    },
    components: {
        vSelect,
        VeeForm,
        Field,
        ErrorMessage,
        IconLoading,
        FilePond,
    },
    setup() {
        const { getExistingShipment, existingShipment } = useGetExistingShipment();
        const { toLocalTime } = useLocalTime();
        return {
            getExistingShipment, existingShipment,
            toLocalTime
        }
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
ship_name:null,
imo_number:null,
call_sign:null,
voyage_number:null,
port_id:null,
shipment_date:null,
flag_country_id:null,
master_name:null,
last_port_id:null,
last_country_id:null,
next_country_id:null,
next_port_id:null,
cert_reg_port_id:null,
cert_reg_date:null,
cert_reg_number:null,
agent_full_name:null,
agent_address:null,
agent_email:null,
agent_phone_number:null,
gross_tonnage:null,
net_tonnage:null,
voyage_brief:null,
cargo_brief:null,
remarks:null,
days_in_arrival_port:null,
waste_description:null,
person_count:null,
                files: {
                    shipment_files: []
                },
            },
            schema,
        }
    },
    mounted() {
        this.getExistingShipment(this.shipmentType, this.updateShipmentId).then((data) => {
            if (Object.keys(data).length !== 0) {
                this.updateFormData();
            }
        })
    },
    computed: {

        formWithShipmentType() {
            if (!('shipmentType' in this.form)) {
                Object.assign(this.form, { 'shipmentType': this.shipmentType })
            }
            return this.form;
        }
    },
    emits: ['update:modelValue'],
    methods: {
        async fillStep() {
            let url = "/shipments/fill-step";
            if (this.action === "update") {
                url += "/" + this.existingShipment.id;
            }
            await axios.post(url, this.formWithShipmentType);
        },
        onSubmit() {
            this.fillStep();
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        updateFormData() {
this.form.ship_name=this.existingShipment ? this.existingShipment.ship_name : null;
this.form.imo_number=this.existingShipment ? this.existingShipment.imo_number : null;
this.form.call_sign=this.existingShipment ? this.existingShipment.call_sign : null;
this.form.voyage_number=this.existingShipment ? this.existingShipment.voyage_number : null;
this.form.port_id=this.existingShipment ? this.existingShipment.port_id : null;
this.form.shipment_date=this.existingShipment ? this.formatDate( this.existingShipment.shipment_date) : null;
this.form.flag_country_id=this.existingShipment ? this.existingShipment.flag_country_id : null;
this.form.master_name=this.existingShipment ? this.existingShipment.master_name : null;
this.form.last_port_id=this.existingShipment ? this.existingShipment.last_port_id : null;
this.form.last_country_id=this.existingShipment ? this.existingShipment.last_country_id : null;
this.form.next_country_id=this.existingShipment ? this.existingShipment.next_country_id : null;
this.form.next_port_id=this.existingShipment ? this.existingShipment.next_port_id : null;
this.form.cert_reg_port_id=this.existingShipment ? this.existingShipment.cert_reg_port_id : null;
this.form.cert_reg_date=this.existingShipment ? this.formatDate(this.existingShipment.cert_reg_date) : null;
this.form.cert_reg_number=this.existingShipment ? this.existingShipment.cert_reg_number : null;
this.form.agent_full_name=this.existingShipment ? this.existingShipment.agent_full_name : null;
this.form.agent_address=this.existingShipment ? this.existingShipment.agent_address : null;
this.form.agent_email=this.existingShipment ? this.existingShipment.agent_email : null;
this.form.agent_phone_number=this.existingShipment ? this.existingShipment.agent_phone_number : null;
this.form.gross_tonnage=this.existingShipment ? this.existingShipment.gross_tonnage : null;
this.form.net_tonnage=this.existingShipment ? this.existingShipment.net_tonnage : null;
this.form.voyage_brief=this.existingShipment ? this.existingShipment.voyage_brief : null;
this.form.cargo_brief=this.existingShipment ? this.existingShipment.cargo_brief : null;
this.form.remarks=this.existingShipment ? this.existingShipment.remarks : null;
this.form.days_in_arrival_port=this.existingShipment ? this.existingShipment.days_in_arrival_port : null;
this.form.waste_description=this.existingShipment ? this.existingShipment.waste_description : null;
this.form.person_count=this.existingShipment ? this.existingShipment.person_count : null;

        },

        handleFilePondInit: function (type) {
            this.$refs[type + '_pond']._pond.setOptions({
                server: {
                    process: "/filepond/upload/",
                    load: "/filepond/load/",
                    patch: "?patch=",
                    headers: {
                        "X-CSRF-TOKEN": document
                            .querySelector('meta[name="csrf-token"]')
                            .getAttribute("content"),
                        "fileType": type,
                    },
                },
            });
            this.loadExistingFiles(type);
        },
        loadExistingFiles(type) {
            let filepondUrl = "/filepond/existing-files-shipment/" + this.shipmentType;
            if (this.updateShipmentId) {
                filepondUrl += "/" + this.updateShipmentId;
            }
            axios.get(filepondUrl).then((response) => {
                if (response.data[type]) {
                    this.$refs[type + '_pond']._pond.setOptions({ files: response.data[type] });
                    this.syncFiles(type);
                }
            });
        },
        syncFiles(type) {
            let files = [];
            this.$refs[type + '_pond'].getFiles().forEach(file => {
                files.push(file.serverId);
            })
            this.form.files[type] = files;
        },
        formatDate(dateString) {
            let date = new Date(dateString);
            let year = date.toLocaleString("default", { year: "numeric" });
            let month = date.toLocaleString("default", { month: "2-digit" });
            let day = date.toLocaleString("default", { day: "2-digit" });

            return year + "-" + month + "-" + day;
        },
    },
}
</script>
