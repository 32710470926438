<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingTrip" style="margin-left: 20px;" />
        </header>

        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div" v-if="existingTrip">
            <form @submit="handleSubmit($event, onSubmit)">
                <div class="step-form__row">
                    <div class="step-form__col">
                        <label for="cargo_company_name" class="label">{{ __("json.Sender") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.cargo_company_name }">
                            <Field v-model="form.cargo_company_name" type="text" name="cargo_company_name" v-slot="{field}">
                                <textarea v-bind="field" rows="4" class="input" id="cargo_company_name" :placeholder="__('json.Sender')" style="height: 100px">
                                </textarea>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="cargo_company_name" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="cargo_owner_poc_phone" class="label">{{ __("json.Phone Number") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.cargo_owner_poc_phone }">
                            <Field v-model="form.cargo_owner_poc_phone" name="cargo_owner_poc_phone" v-slot="{field}">
                                <div class="input" style="padding: 0 !important;">
                                    <vue-tel-input
                                        class="vti-uruk-input"
                                        v-model="form.cargo_owner_poc_phone"
                                        default-country="iq"
                                        :ignored-countries="['il', 'as', 'ag', 'ai', 'bs', 'bm', 'vg', 'ky', 'dm', 'gd', 'gu', 'tc', 'vi', 'tt', 'lc', 'vc', 'kn', 'mp', 'jm', 'ms', 'sx']"
                                        :inputOptions="phoneInputOptions" />
                                </div>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="cargo_owner_poc_phone" />
                            </div>
                        </div>
                    </div>
                    <div class="step-form__col">
                        <label for="sender_address" class="label">{{ __("json.Address") }} <span class="red-color">*</span></label>
                        <div class="input-group" :class="{ 'has-error': errors.sender_address }">
                            <Field v-model="form.sender_address" type="text" name="sender_address" v-slot="{field}">
                                <textarea v-bind="field" rows="4" class="input" id="sender_address" :placeholder="__('json.Sender')" style="height: 100px">
                                </textarea>
                            </Field>
                            <div class="input-group__error">
                                <ErrorMessage name="sender_address" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-form__buttons">
                    <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                        {{ __("json.Previous step") }}
                    </button>
                    <button class="button button--blue step-form__button" type="submit">
                        {{ __("json.Next step") }}
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import {VueTelInput} from "vue-tel-input";
import * as yup from 'yup';
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import {useGetExistingTrip} from "../utils/useGetExistingTrip";

let phoneValidationsCount = 0;

const schema = yup.object({
    cargo_company_name: yup.string()
        .required(lang('validation.required', { 'attribute': lang('json.Sender') }))
        .nullable(),
    sender_address: yup.string()
        .required(lang('validation.required', { 'attribute': lang('json.Address') }))
        .nullable(),
    cargo_owner_poc_phone: yup.string()
        .required(lang('validation.required', { 'attribute': lang('json.Phone Number') }))
        .test({
            name: 'phone-regex',
            message: lang('validation.regex', {'attribute': lang('json.Sender phone number')}),
            test: (phone) => {
                phoneValidationsCount += 1;
                if (phoneValidationsCount > 2 && phone?.length) {
                    let re = new RegExp(/^([+]|[00]{2})([0-9]{9,})+$/g);
                    return re.test(phone);
                }
                return true;
            }
        })
        .nullable(),
});

export default {
    name: "Step5",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        tripType: {
            type: String,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateTripId: {
            type: Number,
            required: false
        },
    },
    components: {
        VeeForm,
        Field,
        ErrorMessage,
        VueTelInput,
        IconLoading,
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                cargo_company_name: null,
                cargo_owner_poc_phone: null,
                sender_address: null
            },
            schema,
            phoneInputOptions: {
                autoFormat: true,
                mode: 'international',
                validCharactersOnly: true,
                showDialCode: true,
                placeholder: '+12 000 000 00 00', // this.$root.__("main.phone"),
            },
        }
    },
    setup() {
        const { getExistingTrip, existingTrip } = useGetExistingTrip();
        return {
            getExistingTrip, existingTrip
        }
    },
    mounted() {
        this.getExistingTrip(this.tripType, this.updateTripId).then((data) => {
            if (Object.keys(data).length !== 0) {
                this.updateFormData();
            }
        })
    },
    computed: {
        formWithTripType() {
            if (!('tripType' in this.form)) {
                Object.assign(this.form, { 'tripType': this.tripType })
            }
            return this.form;
        },
    },
    emits: ['update:modelValue'],
    methods: {
        async fillStep() {
            let url = "/reservation/fill-step";
            if (this.action === "update") {
                url += "/" + this.existingTrip.id;
            }
            await axios.post(url, this.formWithTripType);
        },
        onSubmit() {
            this.fillStep();
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit('update:modelValue', _currentStep);
        },
        updateFormData() {
            this.form.cargo_company_name = this.existingTrip ? this.existingTrip.cargo_company_name : null;
            if (this.existingTrip && this.existingTrip.cargo_owner_poc_phone) {
                this.form.cargo_owner_poc_phone = this.existingTrip.cargo_owner_poc_phone;
            }
            if (this.existingTrip && this.existingTrip.sender_address) {
                this.form.sender_address = this.existingTrip.sender_address;
            }
        },
    },
    watch: {
        'form.cargo_owner_poc_phone'() {
            this.form.cargo_owner_poc_phone = this.form.cargo_owner_poc_phone.replace(' ', '');
        }
    },
}
</script>
