<template>
    <div class="container contacts-section__container">
        <span class="title-label">{{ __('main.reach_out') }}</span>
        <h2 class="section-title contacts-section__title">{{ __('main.contact') }}</h2>

        <form class="contacts-form" @submit.prevent="submit">
            <div class="contacts-form__col">
                <label for="firstNameInput" class="label contacts-form__label">{{ __('main.first_name') }}</label>
                <div class="input-group" :class="{ 'has-error': errors['first_name'] }">
                    <input class="input contacts-form__input" type="text" v-model="firstName" id="firstNameInput" :placeholder="__('main.first_name')">
                    <div class="input-group__error">{{ errors['first_name']?.[0] }}</div>
                </div>
            </div>
            <div class="contacts-form__col">
                <label for="lastNameInput" class="label contacts-form__label">{{ __('main.last_name') }}</label>
                <div class="input-group" :class="{ 'has-error': errors['last_name'] }">
                    <input class="input contacts-form__input" type="text" v-model="lastName" id="lastNameInput" :placeholder="__('main.last_name')">
                    <div class="input-group__error">{{ errors['last_name']?.[0] }}</div>
                </div>
            </div>
            <div class="contacts-form__col">
                <label for="emailInput" class="label contacts-form__label">{{ __('main.email') }}</label>
                <div class="input-group" :class="{ 'has-error': errors['email'] }">
                    <input class="input contacts-form__input" type="email" v-model="email" id="emailInput" :placeholder="__('main.email')">
                    <div class="input-group__error">{{ errors['email']?.[0] }}</div>
                </div>
            </div>
            <div class="contacts-form__col">
                <label for="phoneInput" class="label contacts-form__label">{{ __('main.phone') }}</label>
                <div class="input-group" :class="{ 'has-error': errors['phone'] }">
                    <vue-tel-input
                        class="input contacts-form__input"
                        v-model="phone"
                        default-country="iq"
                        :ignored-countries="['il']"
                        :inputOptions="phoneInputOptions" />
                    <div class="input-group__error">{{ errors['phone']?.[0] }}</div>
                </div>
            </div>
            <div class="contacts-form__col contacts-form__col--w-100">
                <label for="commentInput" class="label contacts-form__label">{{ __('main.comment') }}</label>
                <div class="input-group">
                    <input class="input contacts-form__input" type="text" v-model="comment" id="commentInput" :placeholder="__('main.comment')">
                </div>
            </div>
            <div class="contacts-form__col contacts-form__col--w-100">
                <button class="button button--red contacts-form__button" type="submit" :disabled="isLoading">
                    <IconLoading v-if="isLoading" />
                    <span v-else>{{ __('main.contact') }}</span>
                </button>
            </div>
        </form>
    </div>

    <button id="btn-open-success-modal" data-modal="modal-success" style="display: none"></button>
</template>

<script>
import IconLoading from "./partials/IconLoading.vue";
import {VueTelInput} from "vue-tel-input";

export default {
    props: [],
    components: {
        IconLoading,
        VueTelInput,
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            comment: '',
            phoneInputOptions: {
                autoFormat: true,
                mode: 'international',
                validCharactersOnly: true,
                placeholder: this.$root.__("main.phone"),
                showDialCode: true,
            },
            errors: [],
            isLoading: false,
        }
    },
    methods: {
        submit() {
            this.isLoading = true;

            axios.post("/api/contacts", {
                first_name: this.firstName,
                last_name: this.lastName,
                comment: this.comment,
                email: this.email,
                phone: this.phone,
            }).then((response) => {
                if (response.status === 200) {
                    this.firstName = "";
                    this.lastName = "";
                    this.comment = "";
                    this.email = "";
                    this.phone = "";

                    this.openModal();
                }
            }).catch((e) => {
                let errors = Object.entries(e.response.data.errors);
                for (const [key, value] of errors) {
                    this.errors[key] = value;
                }

                if (e.response.status !== 422) {
                    alert("Something went wrong. Please, try again.");
                    console.log(e);
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        openModal() {
            document.getElementById('btn-open-success-modal').click();
        }
    },
    watch: {
        firstName() {
            this.errors['first_name'] = null;
        },
        lastName() {
            this.errors['last_name'] = null;
        },
        email() {
            this.errors['email'] = null;
        },
        phone() {
            this.errors['phone'] = null;
        },
    }
}
</script>
