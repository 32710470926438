<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingShipment" style="margin-left: 20px" />
        </header>

        <article
            class="document document--grey-header step-section__document"
            v-if="existingShipment"
        >
            <header class="document__header">
                <h3 class="document__header-title">
                    {{ __("main." + shipmentType + "_shipment") }} #{{
                        existingShipment.id_formatted
                    }}
                </h3>
            </header>
            <div class="document__body">
                <div class="document__block">
                    <h4 class="document__title document__title--blue">
                        {{ __("json.Shipment information") }}
                    </h4>
                    <div class="document__grid">
                        <div class="document__row">
                            <p>{{ __("json.Shipment ID") }}</p>
                            <p>{{ existingShipment.id }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Ship name") }}</p>
                            <p>{{ existingShipment.ship_name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.IMO Number") }}</p>
                            <p>{{ existingShipment.imo_number }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Call sign") }}</p>
                            <p>{{ existingShipment.call_sign }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Voyage Number") }}</p>
                            <p>{{ existingShipment.voyage_number }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Port") }}</p>
                            <p>{{ existingShipment.port.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Shipment date") }}</p>
                            <p>{{ shipmentDateFormatted }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Flag") }}</p>
                            <p>{{ existingShipment.flagCountry.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Master name") }}</p>
                            <p>{{ existingShipment.master_name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Last country") }}</p>
                            <p>{{ existingShipment.lastCountry.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Last port") }}</p>
                            <p>{{ existingShipment.lastPort.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Next country") }}</p>
                            <p>{{ existingShipment.nextCountry.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Next port") }}</p>
                            <p>{{ existingShipment.nextPort.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Certificate registry port") }}</p>
                            <p>
                                {{
                                    existingShipment.certificateRegistryPort
                                        .name
                                }}
                            </p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Certificate registry date") }}</p>
                            <p>{{ certificateRegistryDateFormatted }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Certificate regisrty number") }}</p>
                            <p>{{ existingShipment.cert_reg_number }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Agent name") }}</p>
                            <p>{{ existingShipment.agent_full_name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Agent  Address") }}</p>
                            <p>{{ existingShipment.agent_address }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Agent email") }}</p>
                            <p>{{ existingShipment.agent_email }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Agent phone") }}</p>
                            <p>{{ existingShipment.agent_phone_number }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Gross tonnage") }}</p>
                            <p>{{ existingShipment.gross_tonnage }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Net tonnage") }}</p>
                            <p>{{ existingShipment.net_tonnage }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Voyage brief") }}</p>
                            <p>{{ existingShipment.voyage_brief }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Cargo brief") }}</p>
                            <p>{{ existingShipment.cargo_brief }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Remarks") }}</p>
                            <p>{{ existingShipment.remarks }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Days in arrival port") }}</p>
                            <p>{{ existingShipment.days_in_arrival_port }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Waste description") }}</p>
                            <p>{{ existingShipment.waste_description }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Persons count") }}</p>
                            <p>{{ existingShipment.person_count }}</p>
                        </div>
                    </div>
                </div>
                <div class="document__block">
                    <h4 class="document__title document__title--blue">
                        {{ __("json.Cargo manifests") }}
                    </h4>
                    <div class="document__grid">
                        <div class="document__row">
                                <p>{{ __("json.count") }}</p>
                                <p>{{ existingShipment.cargoManifests.length }}</p>
                            </div>
                    </div>
                </div>
                <div class="document__block">
                        <h4 class="document__title document__title--blue">
                            {{ __("json.Shipment stores") }}
                        </h4>
                        <div class="document__grid">
                            <div class="document__row">
                                    <p>{{ __("json.count") }}</p>
                                    <p>{{ existingShipment.shipmentStores.length }}</p>
                                </div>
                        </div>
                    </div>
                    <div class="document__block">
                        <h4 class="document__title document__title--blue">
                            {{ __("json.Crew") }}
                        </h4>
                        <div class="document__grid">
                            <div class="document__row">
                                    <p>{{ __("json.count") }}</p>
                                    <p>{{ existingShipment.crews.length }}</p>
                                </div>
                        </div>
                    </div>
                    <div class="document__block">
                        <h4 class="document__title document__title--blue">
                            {{ __("json.Passengers") }}
                        </h4>
                        <div class="document__grid">
                            <div class="document__row">
                                    <p>{{ __("json.count") }}</p>
                                    <p>{{ existingShipment.passengers.length }}</p>
                                </div>
                        </div>
                    </div>
                    <div class="document__block">
                        <h4 class="document__title document__title--blue">
                            {{ __("json.Goods") }}
                        </h4>
                        <div class="document__grid">
                            <div class="document__row">
                                    <p>{{ __("json.count") }}</p>
                                    <p>{{ existingShipment.goods.length }}</p>
                                </div>
                        </div>
                    </div>

                <template v-for="(files, type) in typedFiles" :key="type">
                    <div class="document__block" v-if="files.length">
                        <h4 class="document__title document__title--blue">
                            {{ getTypedFilesTitle(type) }}
                        </h4>
                        <div class="file" v-for="file in files" :key="file.id">
                            <div class="file__pic">
                                <svg class="file__icon" width="18" height="24">
                                    <use
                                        xlink:href="/assets/images/new/sprite.svg#file-image"
                                    ></use>
                                </svg>
                            </div>
                            <div class="file__body">
                                <div class="file__descr">
                                    <h4 class="file__title">
                                        {{ file.original_name }}
                                    </h4>
                                    <p class="file__size" v-if="false">
                                        Size: 240 KB
                                    </p>
                                </div>
                                <time class="file__date">
                                    {{ toLocalTime(file.created_at) }}
                                </time>
                            </div>
                            <div class="file__actions">
                                <a
                                    :href="file.temp_url"
                                    target="_blank"
                                    class="button-action file__button file__button--hidden-md"
                                    aria-label="View file"
                                >
                                    <svg
                                        class="button-action__icon"
                                        width="22"
                                        height="16"
                                    >
                                        <use
                                            xlink:href="/assets/images/new/sprite.svg#view"
                                        ></use>
                                    </svg>
                                </a>
                                <div v-if="false">
                                    <button
                                        class="button-action file__button file__button--hidden-md"
                                        type="button"
                                        aria-label="Download file"
                                    >
                                        <svg
                                            class="button-action__icon"
                                            width="22"
                                            height="16"
                                        >
                                            <use
                                                xlink:href="/assets/images/new/sprite.svg#download"
                                            ></use>
                                        </svg>
                                    </button>
                                    <button
                                        class="button-action file__button"
                                        type="button"
                                        aria-label="Delete file"
                                    >
                                        <svg
                                            class="button-action__icon"
                                            width="18"
                                            height="19"
                                        >
                                            <use
                                                xlink:href="/assets/images/new/sprite.svg#trash"
                                            ></use>
                                        </svg>
                                    </button>
                                    <button
                                        class="button-action file__button"
                                        type="button"
                                        aria-label="Close file"
                                    >
                                        <svg
                                            class="button-action__icon"
                                            width="22"
                                            height="22"
                                        >
                                            <use
                                                xlink:href="/assets/images/new/sprite.svg#close"
                                            ></use>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </article>

        <VeeForm
            v-slot="{ handleSubmit, errors }"
            :validation-schema="schema"
            as="div"
            v-if="existingShipment"
        >
            <form @submit="handleSubmit($event, onSubmit)">
                <label class="label label--bold" for="yourComment">
                    {{ __("json.Comment") }}
                    <span class="grey-color">{{
                        __("json.(Please add your comment to the shipment)")
                    }}</span>
                </label>
                <textarea
                    v-model="form.comment"
                    class="textarea"
                    id="yourComment"
                    placeholder="Comments"
                    rows="8"
                ></textarea>

                <div class="step-form__buttons">
                    <button
                        class="button button--outline step-form__button"
                        type="button"
                        @click="handlePrev"
                    >
                        {{ __("json.Previous step") }}
                    </button>
                    <button
                        :disabled="isSubmitting"
                        class="button button--blue step-form__button"
                        type="submit"
                    >
                        <IconLoading v-if="isSubmitting" />
                        <span v-else>{{ __("json.Confirm") }}</span>
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import * as yup from "yup";
import IconLoading from "../partials/IconLoading.vue";
import { useGetExistingShipment } from "../utils/useGetExistingShipment";
import { useLocalTime } from "../utils/useLocalTime";

const schema = yup.object({});

export default {
    name: "Step7",
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        steps: {
            type: Object,
            required: true,
        },
        shipmentType: {
            type: String,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
        updateShipmentId: {
            type: Number,
            required: false,
        },
    },
    components: {
        VeeForm,
        Field,
        ErrorMessage,
        IconLoading,
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                comment: null,
            },
            schema,
            isSubmitting: false,
            existingFiles: [],
        };
    },
    setup() {
        const { getExistingShipment, existingShipment } =
            useGetExistingShipment();
        const { toLocalTime } = useLocalTime();
        return {
            getExistingShipment,
            existingShipment,
            toLocalTime,
        };
    },
    mounted() {
        this.getExistingShipment(this.shipmentType, this.updateShipmentId).then(
            (data) => {
                if (Object.keys(data).length !== 0) {
                    this.updateFormData();
                }
            }
        );
    },
    computed: {
        formWithShipmentType() {
            if (!("shipmentType" in this.form)) {
                Object.assign(this.form, { shipmentType: this.shipmentType });
            }
            return this.form;
        },
        shipmentDateFormatted() {
            return (
                this.existingShipment &&
                this.existingShipment.shipment_date &&
                this.toLocalTime(
                    this.existingShipment.shipment_date,
                    "YYYY-MM-DD"
                )
            );
        },
        certificateRegistryDateFormatted() {
            return (
                this.existingShipment &&
                this.existingShipment.cert_reg_date &&
                this.toLocalTime(
                    this.existingShipment.cert_reg_date,
                    "YYYY-MM-DD"
                )
            );
        },
        typedFiles() {
            let typedFiles = {};
            this.existingFiles.forEach((file) => {
                if (Array.isArray(typedFiles[file.type])) {
                    typedFiles[file.type].push(file);
                } else {
                    typedFiles[file.type] = [file];
                }
            });

            return typedFiles;
        },
    },
    emits: ["update:modelValue", "openSuccessModal"],
    methods: {
        onSubmit() {
            this.isSubmitting = true;

            let formActionUrl = "/shipments/create";
            if (this.action === "update") {
                formActionUrl = "/shipments/update/" + this.existingShipment.id;
            }

            axios
                .post(formActionUrl, this.formWithShipmentType)
                .then(({ data }) => {
                    this.$emit("openSuccessModal");
                    setTimeout(function () {
                        window.location.href = data.returnUrl;
                        this.isSubmitting = false;
                    }, 3000);
                })
                .catch(({ response }) => {
                    console.log(response);
                    let errorString = response.data.message + `\n`;
                    for (const [key, value] of Object.entries(
                        response.data.errors
                    )) {
                        errorString += `\n${value}`;
                    }
                    errorString += `\n\nPlease, fix and try again.`;
                    alert(errorString);
                    this.isSubmitting = false;
                });
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit("update:modelValue", _currentStep);
        },
        updateFormData() {
            this.existingFiles = this.existingShipment.files;
            this.form = this.existingShipment;

            let files = {};
            this.existingShipment.files.forEach((file) => {
                if (Array.isArray(files[file.type])) {
                    files[file.type].push(file.id);
                } else {
                    files[file.type] = [file.id];
                }
            });
            this.form.files = files;

            this.form.shipment_date = this.formatDate(this.form.shipment_date);
            this.form.cert_reg_date = this.formatDate(this.form.cert_reg_date);
        },
        getTypedFilesTitle(type) {
            switch (type) {
                case "shipment":
                    return this.$root.__("json.Shipment");
            }
        },
        formatDate(dateString) {
            if (!dateString) {
                return null;
            }
            let date = new Date(dateString);
            let year = date.toLocaleString("default", { year: "numeric" });
            let month = date.toLocaleString("default", { month: "2-digit" });
            let day = date.toLocaleString("default", { day: "2-digit" });

            return year + "-" + month + "-" + day;
        },
    },
};
</script>
