<template>
    <ul class="account-trips" v-if="trips.length > 0">
        <li class="account-trips__item" v-for="trip in trips" :key="trip.id">
            <article
                class="trip-card"
                :class="{ 'accordion-vue': trip.user.role_id !== 4 }"
            >
                <div class="trip-card__row">
                    <div class="trip-card__col">
                        <svg class="trip-card__icon" width="28" height="38">
                            <use
                                xlink:href="/assets/images/new/sprite.svg#file-pdf"
                            ></use>
                        </svg>
                    </div>
                    <div class="trip-card__col">
                        <h4 class="trip-card__title">
                            {{ __("json.Reservation ID") }}
                        </h4>
                        <p class="trip-card__text trip-card__text--ellipsis">
                            {{ trip.id_formatted }}
                        </p>
                    </div>
                    <div class="trip-card__col">
                        <h4 class="trip-card__title">
                            {{ __("json.Person of Contact") }}
                        </h4>
                        <p class="trip-card__text">
                            {{ trip.car_owner_name ?? trip.car_poc_name }}
                        </p>
                    </div>
                    <div class="trip-card__col">
                        <h4 class="trip-card__title">
                            {{ __("json.Border registration") }}
                        </h4>
                        <p class="trip-card__text">
                            {{ trip.from_border ? trip.from_border.name : "" }}
                            -
                            {{ trip.to_border ? trip.to_border.name : "" }}
                        </p>
                    </div>
                    <div class="trip-card__col">
                        <h4 class="trip-card__title">
                            {{ __("json.Status") }}
                        </h4>
                        <button
                            class="accordion-toggle trip-card__toggle"
                            :class="trip.status_class"
                            type="button"
                        >
                            <span v-if="trip.user.role_id !== 4">
                                {{
                                    trip.status_for_display ==
                                    "Waiting for approval"
                                        ? __("json.Waiting for Approval")
                                        : __("json." + trip.status_for_display)
                                }}
                            </span>
                            <span v-else>
                                {{ __("json.Created by BGW Supervisor") }}
                            </span>
                            <svg
                                class="trip-card__toggle-icon"
                                width="13"
                                height="8"
                                v-if="trip.user.role_id !== 4"
                            >
                                <use
                                    xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"
                                ></use>
                            </svg>
                        </button>
                    </div>
                    <div class="trip-card__col trip-card__buttons">
                        <a
                            v-if="trip.is_rejected_status || trip.is_itms_rejected"
                            class="button button--red button--sm"
                            :href="'/reservation/update/' + trip.id"
                        >
                            {{ __("json.Edit") }}
                        </a>
                        <a
                            v-if="trip.is_ready_for_pay"
                            :href="'/reservation/pay/' + trip.id"
                            class="button button--green button--sm text-center"
                            target="_blank"
                        >
                            {{ __("json.To Pay") }}
                        </a>
                        <button
                            v-else-if="trip.is_paid_status"
                            class="button button--sm"
                            type="button"
                            disabled
                        >
                            {{ __("json.Paid") }}
                        </button>
                        <a
                            class="button-action page-header__button"
                            v-if="trip.is_approved_status"
                            :href="'/reservation/pdf/download/' + trip.id"
                            target="_blank"
                            :title="__('json.Download document')"
                        >
                            <svg
                                class="button-action__icon"
                                width="18"
                                height="20"
                            >
                                <use
                                    xlink:href="/assets/images/new/sprite.svg#download"
                                ></use>
                            </svg>
                        </a>

                        <div class="dropdown-vue trip-card-dropdown">
                            <button
                                class="button-action dropdown-toggle trip-card-dropdown__toggle"
                                type="button"
                                aria-label="Open actions menu"
                            >
                                <svg
                                    class="button-action__icon"
                                    width="4"
                                    height="16"
                                >
                                    <use
                                        xlink:href="/assets/images/new/sprite.svg#more"
                                    ></use>
                                </svg>
                            </button>
                            <ul
                                class="dropdown-menu dropdown-menu--right trip-card-dropdown__menu"
                            >
                                <li class="dropdown-menu__item">
                                    <a
                                        :href="'/reservation/view/' + trip.id"
                                        class="dropdown-menu__link"
                                    >
                                        {{ __("json.View document") }}
                                    </a>
                                </li>
                                <li class="dropdown-menu__item">
                                    <a
                                        :href="
                                            '/reservation/invoice/' + trip.id
                                        "
                                        v-if="trip.is_paid_status"
                                        class="dropdown-menu__link"
                                    >
                                        {{ __("json.View invoice") }}
                                    </a>
                                </li>
                                <li class="dropdown-menu__item">
                                    <a
                                        href="#"
                                        class="dropdown-menu__link"
                                        @click.prevent="openShareModal(trip)"
                                    >
                                        {{ __("json.Share") }}
                                    </a>
                                </li>
                                <li class="dropdown-menu__item"
                                v-if="trip.is_rejected_status" >
                                    <a
                                        href="#"
                                        class="dropdown-menu__link"
                                        @click.prevent="showRejectReason(trip)"
                                    >
                                        {{ __("json.Show reject reason") }}
                                    </a>
                                </li>
                                <li
                                    class="dropdown-menu__item"
                                    v-if="!trip.is_paid_status"
                                >
                                    <a
                                        href="#"
                                        class="dropdown-menu__link"
                                        @click.prevent="openDeleteModal(trip)"
                                    >
                                        {{ __("json.Delete") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-dropdown trip-card__dropdown"
                    v-if="trip.user.role_id !== 4"
                >
                    <ul class="reviewers-list trip-card__reviewers-list">
                        <!-- <li
                            class="reviewers-list__item"
                            v-if="trip.itms_status == 2"
                            :key="10000"
                        >
                            <article class="reviewers-list-card">
                                <header class="reviewers-list-card__header">
                                    <div class="reviewers-list-card__left">
                                        <h4 class="reviewers-list-card__title">
                                            {{ __("json.ITMS user") }}
                                        </h4>
                                    </div>
                                    <div class="reviewers-list-card__right">
                                        <span
                                            class="reviewers-list-card__info"
                                        >
                                            {{
                                                __(
                                                    "json. Rejection Reason"
                                                )
                                            }}
                                        </span>
                                    </div>
                                </header>
                                <div
                                    class="reviewers-list-card__text"
                                >
                                    <p>{{ trip.notes }}</p>
                                </div>
                            </article>
                        </li> -->
                        <li
                            class="reviewers-list__item"
                            v-if="trip.itms_status == 2"
                            :key="-1"
                        >
                            <article class="reviewers-list-card">
                                <header class="reviewers-list-card__header">
                                    <div class="reviewers-list-card__left">
                                        <h4 class="reviewers-list-card__title">
                                            {{ __("json.ITMS user") }}
                                        </h4>
                                    </div>
                                    <div class="reviewers-list-card__right">
                                        <span
                                            class="reviewers-list-card__info"
                                        >
                                            {{
                                                __(
                                                    "json. Rejection Reason"
                                                )
                                            }}
                                        </span>
                                    </div>
                                </header>
                                <div
                                    class="reviewers-list-card__text"
                                >
                                    <p>{{ trip.notes }}</p>
                                </div>
                            </article>
                        </li>
                        <li
                            class="reviewers-list__item"
                            v-for="(review, index) in reviews[trip.id]"
                            :key="index"
                        >
                            <article class="reviewers-list-card">
                                <header class="reviewers-list-card__header">
                                    <div class="reviewers-list-card__left">
                                        <h4 class="reviewers-list-card__title">
                                            {{ review.departmentName }}
                                        </h4>
                                        <p
                                            class="reviewers-list-card__name"
                                            v-if="review.reviewDate"
                                        >
                                            <!-- <span>Abdula Aziz</span> -->
                                            <time
                                                >({{
                                                    toLocalTime(
                                                        review.reviewDate
                                                    )
                                                }})</time
                                            >
                                        </p>
                                    </div>
                                    <div class="reviewers-list-card__right">
                                        <svg
                                            v-if="
                                                review.reviewStatus ===
                                                'Approved'
                                            "
                                            class="reviewers-list-card__icon"
                                            width="20"
                                            height="20"
                                        >
                                            <use
                                                xlink:href="/assets/images/new/sprite.svg#check-circle"
                                            ></use>
                                        </svg>
                                        <svg
                                            v-else-if="
                                                review.reviewStatus ===
                                                'Rejected'
                                            "
                                            class="reviewers-list-card__icon"
                                            width="20"
                                            height="20"
                                        >
                                            <use
                                                xlink:href="/assets/images/new/sprite.svg#close-circle"
                                            ></use>
                                        </svg>
                                        <span
                                            v-else
                                            class="reviewers-list-card__info"
                                        >
                                            {{
                                                __(
                                                    "json. Waiting information..."
                                                )
                                            }}
                                        </span>
                                    </div>
                                </header>
                                <div
                                    class="reviewers-list-card__text"
                                    v-if="review.reviewComment"
                                >
                                    <p>{{ review.reviewComment }}</p>
                                </div>
                            </article>
                        </li>
                    </ul>
                </div>
            </article>
        </li>
    </ul>
    <div v-else>
        <div class="account-empty">
            <img
                class="account-empty__img"
                src="/assets/images/new/empty-img.svg"
                width="80"
                height="80"
                alt="empty"
            />
            <p class="account-empty__text">
                {{ __("reservations.no_reservations_yet") }}
            </p>
        </div>
    </div>
    <button
        id="btn-open-share-modal"
        data-modal="modal-share"
        style="display: none"
    ></button>
    <button
        id="btn-open-show-reject-modal"
        data-modal="modal-reject-details"
        style="display: none"
    ></button>
    <button
        id="btn-open-delete-modal"
        data-modal="modal-delete"
        style="display: none"
    ></button>
    <button
        id="btn-open-info-modal"
        data-modal="modal-info"
        style="display: none"
    ></button>
    <div class="modal-wrap">
        <div id="modal-share" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button
                        class="modal__close"
                        aria-label="Close modal"
                        data-modal-close
                        type="button"
                    >
                        <svg class="modal__close-icon" width="16" height="16">
                            <use
                                xlink:href="/assets/images/new/sprite.svg#close-modal"
                            ></use>
                        </svg>
                    </button>

                    <h3 class="modal__title">
                        {{ __("json.Share this document") }}
                    </h3>

                    <ul class="modal-share">
                        <li class="modal-share__item">
                            <a
                                class="modal-share__link"
                                :href="whatsappLink"
                                target="_blank"
                            >
                                <svg
                                    class="modal-share__icon"
                                    width="39"
                                    height="38"
                                >
                                    <use
                                        xlink:href="/assets/images/new/sprite.svg#whatsapp"
                                    ></use>
                                </svg>
                            </a>
                        </li>
                        <li class="modal-share__item">
                            <a
                                class="modal-share__link"
                                :href="telegramLink"
                                target="_blank"
                            >
                                <svg
                                    class="modal-share__icon"
                                    width="39"
                                    height="32"
                                >
                                    <use
                                        xlink:href="/assets/images/new/sprite.svg#telegram"
                                    ></use>
                                </svg>
                            </a>
                        </li>
                    </ul>

                    <form class="modal__form">
                        <label class="label">{{
                            __("json.or copy link")
                        }}</label>
                        <div class="input-group modal__form-group">
                            <input
                                class="input"
                                type="text"
                                name="shareLinkInput"
                                :value="shareTripUrl"
                                readonly
                            />
                            <button
                                class="input-group__copy"
                                aria-label="Copy link"
                                type="button"
                                @click="copyToClipboard(shareTripUrl)"
                            >
                                <svg
                                    class="input-group__show-pass-icon"
                                    width="14"
                                    height="18"
                                >
                                    <use
                                        xlink:href="/assets/images/new/sprite.svg#copy"
                                    ></use>
                                </svg>
                            </button>
                        </div>

                        <div class="modal__form-buttons">
                            <button
                                class="button button--red modal__button"
                                data-modal-close
                                type="button"
                            >
                                {{ __("json.Cancel") }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="modal-reject-details" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button
                        class="modal__close"
                        aria-label="Close modal"
                        data-modal-close
                        type="button"
                    >
                        <svg class="modal__close-icon" width="16" height="16">
                            <use
                                xlink:href="/assets/images/new/sprite.svg#close-modal"
                            ></use>
                        </svg>
                    </button>

                    <h3 class="modal__title">
                        {{ __("json.Reject details") }}
                    </h3>

                    <form class="modal__form trip-card__dropdown">
                        <ul class="reviewers-list trip-card__reviewers-list">
                            <li
                                class="reviewers-list__item"
                                v-for="(review, index) in reviews[tripToShowReject?.id]?.filter(x => x.reviewStatus === 'Rejected')"
                                :key="index"
                            >
                                <article class="reviewers-list-card">
                                    <header class="reviewers-list-card__header">
                                        <div class="reviewers-list-card__left">
                                            <h4 class="reviewers-list-card__title">
                                                {{ review.departmentName }}
                                            </h4>
                                            <p
                                                class="reviewers-list-card__name"
                                                v-if="review.reviewDate"
                                            >
                                                <!-- <span>Abdula Aziz</span> -->
                                                <time
                                                    >({{
                                                        toLocalTime(
                                                            review.reviewDate
                                                        )
                                                    }})</time
                                                >
                                            </p>
                                        </div>
                                        <div class="reviewers-list-card__right">
                                            <svg
                                                v-if="
                                                    review.reviewStatus ===
                                                    'Approved'
                                                "
                                                class="reviewers-list-card__icon"
                                                width="20"
                                                height="20"
                                            >
                                                <use
                                                    xlink:href="/assets/images/new/sprite.svg#check-circle"
                                                ></use>
                                            </svg>
                                            <svg
                                                class="reviewers-list-card__icon"
                                                width="20"
                                                height="20"
                                            >
                                                <use
                                                    xlink:href="/assets/images/new/sprite.svg#close-circle"
                                                ></use>
                                            </svg>
                                        </div>
                                    </header>
                                    <div
                                        class="reviewers-list-card__text"
                                        v-if="review.reviewComment"
                                    >
                                        <p>{{ review.reviewComment }}</p>
                                    </div>
                                </article>
                            </li>
                        </ul>


                        <!-- <label class="label">{{
                            __("json.Rejected by") + ": Itms"
                        }}</label>
                        <label class="label">{{
                            __("json.Reject reason")
                        }}</label>
                        <div class="input-group modal__form-group">
                            <input
                                class="input"
                                type="text"
                                name="shareLinkInput"
                                :value="tripToShowReject?.notes"
                                readonly
                            />
                        </div> -->

                        <div class="modal__form-buttons">
                            <button
                                class="button button--red modal__button"
                                data-modal-close
                                type="button"
                            >
                                {{ __("json.Close") }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="modal-delete" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button
                        class="modal__close"
                        aria-label="Close modal"
                        data-modal-close
                        type="button"
                    >
                        <svg class="modal__close-icon" width="16" height="16">
                            <use
                                xlink:href="/assets/images/new/sprite.svg#close-modal"
                            ></use>
                        </svg>
                    </button>

                    <h3 class="modal__title">
                        {{ __("json.Are you sure to delete trip") }}
                        {{ this.tripToDelete?.id_formatted }}?
                    </h3>
                    <p class="modal__text">
                        {{ __("json.This trip") }}
                        {{ this.tripToDelete?.id_formatted }}
                        {{
                            __(
                                "json.will be removed completely from your reservations"
                            )
                        }}.
                    </p>
                    <div class="modal__buttons">
                        <button
                            class="button button--outline modal__button"
                            data-modal-close
                            type="button"
                        >
                            {{ __("json.Cancel") }}
                        </button>
                        <button
                            class="button button--red modal__button"
                            type="button"
                            :disabled="isDeleteLoading"
                            @click="deleteTrip(tripToDelete)"
                        >
                            <IconLoading v-if="isDeleteLoading" />
                            <span v-else>{{ __("json.Delete") }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-info" class="modal">
            <div class="modal__inner">
                <div class="modal__window">
                    <button
                        class="modal__close"
                        aria-label="Close modal"
                        data-modal-close
                        type="button"
                    >
                        <svg class="modal__close-icon" width="16" height="16">
                            <use
                                xlink:href="/assets/images/new/sprite.svg#close-modal"
                            ></use>
                        </svg>
                    </button>

                    <svg class="modal__icon" width="60" height="60">
                        <use
                            v-if="infoModalStatusSuccess"
                            xlink:href="/assets/images/new/sprite.svg#check-circle"
                        ></use>
                        <use
                            v-else
                            xlink:href="/assets/images/new/sprite.svg#close-circle"
                        ></use>
                    </svg>
                    <h3 class="modal__title">
                        {{ infoModalTitle }}
                    </h3>

                    <div class="modal__buttons">
                        <button
                            class="button button--red modal__button"
                            data-modal-close=""
                            type="button"
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconLoading from "../partials/IconLoading.vue";
import { useLocalTime } from "../utils/useLocalTime";

export default {
    name: "ReservationList",
    props: ["tripType", "tripsReviews"],
    components: {
        IconLoading,
    },
    setup() {
        const { toLocalTime } = useLocalTime();
        return {
            toLocalTime,
        };
    },
    data() {
        return {
            trips: [],
            reviews: JSON.parse(this.tripsReviews),
            tripToShare: null,
            tripToShowReject: null,
            tripToDelete: null,
            isDeleteLoading: false,
            infoModalTitle: "",
            infoModalStatusSuccess: true,
        };
    },
    mounted() {
        this.loadTrips();
        this.listenForUpdates();
    },
    methods: {
        openShareModal(trip) {
            this.tripToShare = trip;
            const openModalButton = document.getElementById(
                "btn-open-share-modal"
            );
            openModalButton.click();
        },
        showRejectReason(trip) {
            this.tripToShowReject = trip;
            const openModalButton = document.getElementById(
                "btn-open-show-reject-modal"
            );
            openModalButton.click();
        },
        openDeleteModal(trip) {
            this.tripToDelete = trip;
            const openModalButton = document.getElementById(
                "btn-open-delete-modal"
            );
            openModalButton.click();
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                alert("Copied!");
            });
        },
        deleteTrip(trip) {
            this.isDeleteLoading = true;

            axios
                .get("/reservation/delete/" + trip.id)
                .then(() => {
                    this.trips = this.trips.filter((t) => t.id !== trip.id);
                    document
                        .querySelector("#modal-delete [data-modal-close]")
                        .click();
                    this.infoModalTitle =
                        this.$root.__("json.Trip") +
                        this.tripToDelete?.id_formatted +
                        this.$root.__("deleted successfully");
                    this.infoModalStatusSuccess = true;
                    document.getElementById("btn-open-info-modal").click();
                })
                .catch((err) => {
                    console.log(err);
                    alert("Something went wrong! Please, try again.");
                    document
                        .querySelector("#modal-delete [data-modal-close]")
                        .click();
                })
                .finally(() => {
                    this.isDeleteLoading = false;
                });
        },
        listenForUpdates() {
            if (!document.querySelector("#userId")) {
                return;
            }

            Echo.private(
                `App.Models.User.${document.querySelector("#userId").value}`
            ).notification((notification) => {
                if (!notification.type_code) {
                    return;
                }

                if (notification.type_code === "review-created") {
                    const reviewToUpdateIndex = this.reviews[
                        notification.trip_id
                    ].findIndex(
                        (r) =>
                            r.departmentName ===
                            notification.review.departmentName
                    );
                    this.reviews[notification.trip_id][reviewToUpdateIndex] =
                        notification.review;
                } else {
                    const tripToUpdateIndex = this.trips.findIndex(
                        (t) => t.id === notification.trip.id
                    );
                    this.trips[tripToUpdateIndex] = notification.trip;
                }

                this.infoModalTitle = notification.message;
                this.infoModalStatusSuccess = notification.status === "success";
                document.getElementById("btn-open-info-modal").click();
            });
        },
        loadTrips() {
            const url = new URL(window.location.href);
            const urlParams = url.searchParams;

            axios
                .get("/reservation/list-api/" + this.tripType, {
                    params: {
                        page: urlParams.get("page"),
                        timestamp: new Date().getTime(),
                    },
                })
                .then(({ data }) => {
                    this.trips = data.data;
                })
                .finally(() => {
                    this.$nextTick(() => {
                        window.initAccordion(".accordion-vue");
                        window.initDropdown(".dropdown-vue");
                    });
                });
        },
    },
    computed: {
        // rejectedReviews() {
        //     let res = [];
        //     for (let index = 0; index < this.reviews.length; index++) {
        //         const element = reviews[index];
        //         let tempRes = [];
        //         for (let j = 0; j < element.length; j++) {
        //             const elem = element[index];

        //         }
        //         if(element.reviewStatus == 'Rejected') {
        //             res.push(element);
        //         }
        //     }
        //     return res;
        // },
        shareTripUrl() {
            return (
                this.tripToShare &&
                window.location.protocol +
                    "//" +
                    window.location.host +
                    "/reservation/view/" +
                    this.tripToShare.id
            );
        },
        shareText() {
            return this.$root.__("reservations.share_text");
        },
        whatsappLink() {
            return (
                "https://api.whatsapp.com/send/?text=" +
                this.shareText +
                this.shareTripUrl
            );
        },
        telegramLink() {
            return (
                "https://t.me/share/url?url=" +
                this.shareTripUrl +
                "&text=" +
                this.shareText
            );
        },
    },
};
</script>
