<template>
    <div class="add-comment">
        <div class="add-comment__group">
            <label class="add-comment__label" for="comment">
                {{ __("json.Comment") }}
                <span class="grey-color">
                    ({{
                        __(
                            "json.(Please add your comment to reject the reservation)"
                        )
                    }})
                </span>
            </label>
            <textarea
                class="textarea"
                v-model="comment"
                id="comment"
                rows="6"
            ></textarea>
        </div>
        <div class="add-comment__buttons">
            <button
                class="button button--red add-comment__button"
                @click="approve"
                :disabled="comment.length > 0 || isApproveLoading"
            >
                <IconLoading v-if="isApproveLoading" />
                <span v-else>{{ __("json.Approve") }}</span>
            </button>
            <button
                class="button button--blue add-comment__button"
                @click="reject"
                :disabled="comment.length <= 0 || isRejectLoading"
            >
                <IconLoading color="#0749B1" v-if="isRejectLoading" />
                <span v-else>{{ __("json.Reject") }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import IconLoading from "../partials/IconLoading.vue";

export default {
    name: "ReviewerViewForm",
    props: ["id"],
    components: {
        IconLoading,
    },
    data() {
        return {
            isApproveLoading: false,
            isRejectLoading: false,
            comment: "",
        };
    },
    methods: {
        approve() {
            this.isApproveLoading = true;

            axios
                .post("/reviewer/reservation/approve/" + this.id)
                .then((response) => {
                        window.location = "/reviewer/reservation/list";
                    
                })
                .catch((e) => {
                    alert(e.response.data.msg);
                    console.log(e);
                })
                .finally(() => {
                    this.isApproveLoading = false;
                });
        },
        reject() {
            this.isRejectLoading = true;

            axios
                .post("/reviewer/reservation/reject/" + this.id, {
                    comment: this.comment,
                })
                .then((response) => {
                    
                        window.location = "/reviewer/reservation/list";
                    
                })
                .catch((e) => {
                    alert(e.response.data.msg);
                    console.log(e);
                })
                .finally(() => {
                    this.isRejectLoading = false;
                });
        },
    },
};
</script>
