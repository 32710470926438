<template>
    <div>
        <label class="label filter-form__label">{{ label }}</label>
        <div class="input-group">
            <div class="select"
                 :class="{ 'is-open': isOpen }"
                 @click="isOpen = !isOpen"
                 ref="selectRef"
            >
                <button class="select__toggle" aria-label="Open select" type="button">
                    <span :class="{ 'select__placeholder': !selected }">
                        {{ selected ?? placeholder }}
                    </span>
                </button>
                <div class="select__dropdown">
                    <ul class="select__list">
                        <li class="select__item"
                            v-for="(option, index) in options" :key="index"
                            @click="$emit('updateSelected', option)"
                        >
                            <a href="#" @click.prevent class="select__link" :class="{ 'is-selected': option === selected }">
                                {{ option }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FilterSelect",
    props: {
        label: {
            type: String,
            default: 'Label'
        },
        placeholder: {
            type: String,
            default: 'Select item'
        },
        options: {
            type: Array,
            required: true
        },
        selected: {
            type: [String, Number]
        }
    },
    data() {
        return {
            isOpen: false,
        }
    },
    mounted() {
        this.detectOutsideClick();
    },
    methods: {
        detectOutsideClick() {
            let self = this;
            document.addEventListener('click', (e)=> {
                if (self.$refs.selectRef !== undefined && self.$refs.selectRef.contains(e.target) === false) {
                    self.isOpen = false;
                }
            });
        }
    },
    emits: ['updateSelected']
}
</script>
