import {ref} from "vue";

export const useGetPassengers = () => {
    const items = ref(null);

    const getPassengers = async (updateShipmentId) => {
        let url = '/shipments/passengers/' + updateShipmentId;

        return await axios.get(url).then(({data}) => {
            items.value = data;
            return data;
        });
    }

    return { getPassengers, items };
}
