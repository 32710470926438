<template>
    <nav class="tabs search-trip__tabs">
        <ul class="tabs__nav">
            <li class="tabs__nav-item">
                <button class="tabs__nav-btn" :class="{ 'is-active': isInfoTabActive }" @click="isInfoTabActive = true;" type="button">
                    {{ __('main.information') }}
                </button>
            </li>
            <li class="tabs__nav-item">
                <button class="tabs__nav-btn" :class="{ 'is-active': !isInfoTabActive }" @click="isInfoTabActive = false;" type="button">
                    {{ __('main.alerts') }}
                </button>
            </li>
        </ul>
    </nav>

    <div class="tabs-content search-trip__tabs-content">
        <div class="tabs-panel" :class="{ 'is-active': isInfoTabActive }">
            <div class="info-item">
                <h5 class="info-item__title">{{ __('trip-search.trip_id') }}</h5>
                <p class="info-item__text">{{ trip.tripID }}</p>
            </div>
            <div class="info-item">
                <h5 class="info-item__title">{{ __('json.Driver information') }}</h5>
                <div class="info-item__row">
                    <picture class="info-item__avatar">
                        <img class="info-item__avatar-img" src="/assets/images/new/profile-avatar.jpg" srcset="/assets/images/new/profile-avatar@2x.jpg 2x" width="48" height="48" alt="avatar">
                    </picture>
                    <div class="info-item__col">
                        <p class="info-item__text">{{ trip.driver_name }}</p>
                        <p class="info-item__label">{{ trip.driver_phone }}</p>
                    </div>
                    <a :href="'tel:' + trip.driver_phone" class="button button--md button--green info-item__call-button" aria-label="Call button">
                        <svg width="18" height="18">
                            <use xlink:href="/assets/images/new/sprite.svg#phone"></use>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="info-item">
                <h5 class="info-item__title">{{ __('main.truck_info') }}</h5>
                <div class="info-item__row info-item__row--grid">
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.brand') }}</p>
                        <p class="info-item__text">{{ trip.brand }}</p>
                    </div>
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.model') }}</p>
                        <p class="info-item__text">{{ trip.model }}</p>
                    </div>
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.plate_number') }}</p>
                        <p class="info-item__text">{{ trip.plate_number }}</p>
                    </div>
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.license_country') }}</p>
                        <p class="info-item__text">{{ trip.license_country }}</p>
                    </div>
                </div>
            </div>
            <div class="info-item">
                <h5 class="info-item__title">{{ __('main.route_detail') }}</h5>
                <div class="info-item__route">
                    <p class="info-item__label">{{ __('main.from_border') }}</p>
                    <p class="info-item__text">{{ trip.starting_position }}</p>
                </div>
                <div class="info-item__route">
                    <p class="info-item__label">{{ __('main.to_border') }}</p>
                    <p class="info-item__text">{{ trip.destination }}</p>
                </div>
            </div>
            <div class="info-item">
                <h5 class="info-item__title">{{ __('main.cargo_supplier') }}</h5>
                <div class="info-item__row info-item__row--mb">
                    <div class="info-item__col">
                        <p class="info-item__label">{{ __('main.company_name') }}</p>
                        <p class="info-item__text">{{ trip.cargo_supplier_company }}</p>
                    </div>
                </div>
                <div class="info-item__row info-item__row--mb">
                    <div class="info-item__col">
                        <p class="info-item__label">{{ __('main.poc_contact') }}</p>
                        <p class="info-item__text">{{ trip.cargo_supplier_contact_details }}</p>
                    </div>
                </div>
                <div class="info-item__row info-item__row--mb">
                    <div class="info-item__col">
                        <p class="info-item__label">{{ __('main.poc_phone') }}</p>
                        <p class="info-item__text">{{ trip.cargo_supplier_phone }}</p>
                    </div>
                    <button class="button button--md button--green info-item__call-button" type="button" aria-label="Call button" disabled>
                        <svg width="18" height="18">
                            <use xlink:href="/assets/images/new/sprite.svg#phone"></use>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="info-item" v-for="(cargo, index) in trip.cargo" :key="trip.tripID + '-' + index">
                <h5 class="info-item__title">{{ __('main.cargo_info') }}</h5>
                <div class="info-item__row info-item__row--grid">
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.register_number') }}</p>
                        <p class="info-item__text">{{ trip.imei }}</p>
                    </div>
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.transporter_number') }}</p>
                        <p class="info-item__text">TN6984</p>
                    </div>
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.cargo_type') }}</p>
                        <p class="info-item__text">{{ cargo.hsn_description }}</p>
                    </div>
                    <div class="info-item__col info-item__col--6">
                        <p class="info-item__label">{{ __('main.gross_weight') }}</p>
                        <p class="info-item__text">{{ cargo.weight }} {{ cargo.measure }}</p>
                    </div>
                </div>
            </div>
            <div class="info-item" v-if="trip.device_type || trip.lockID">
                <h5 class="info-item__title">{{ __('main.device_info') }}</h5>
                <div class="info-item__row info-item__row--mb" v-if="trip.device_type">
                    <div class="info-item__col">
                        <p class="info-item__label">GPS device</p>
                        <p class="info-item__text">{{ trip.device_type }}</p>
                    </div>
                    <a class="info-item__qr-code" href="#" aria-label="QR Code">
                        <svg width="24" height="24">
                            <use xlink:href="/assets/images/new/sprite.svg#qr"></use>
                        </svg>
                    </a>
                </div>
                <div class="info-item__row info-item__row--mb" v-if="trip.lockID">
                    <div class="info-item__col">
                        <p class="info-item__label">{{ __('trip-search.lock_seal_id') }}</p>
                        <p class="info-item__text">{{ trip.lockID }}</p>
                    </div>
                    <a class="info-item__qr-code" href="#" aria-label="QR Code">
                        <svg width="24" height="24">
                            <use xlink:href="/assets/images/new/sprite.svg#qr"></use>
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <div class="tabs-panel" :class="{ 'is-active': !isInfoTabActive }">
            <div class="alert-item" style="cursor: pointer"
                 v-for="alert in infoData.alerts" :key="alert.id"
                 @click="$emit('placeAlertMarker', { lat: alert.lat, lng: alert.lng })"
            >
                <div class="alert-item__row">
                    <h5 class="alert-item__title">{{ alert.name }}</h5>
                    <p class="alert-item__speed">80 km/h</p>
                </div>
                <div class="alert-item__row">
                    <p class="alert-item__location">{{ alert.lng }}° N, {{ alert.lat }}° E</p>
                    <time class="alert-item__time">{{ alert.time }}</time>
                </div>
            </div>
        </div>
    </div>

    <button
        class="button button--red button--md search-trip__button"
        @click.prevent="$emit('openConfirmDeliveryModal')"
        type="button"
        v-if="trip.trip_type_id === 2 && !trip.is_cargo_delivered"
    >
        {{ __('trip-search.confirm_delivery') }}
    </button>
</template>

<script>
export default {
    name: "TripInfo",
    props: {
        trip: {
            type: Object,
            required: true
        },
        infoData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isInfoTabActive: true,
        }
    },
    emits: ['openConfirmDeliveryModal', 'placeAlertMarker'],
}
</script>
