<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!items" style="margin-left: 20px;" />
            <button class="button button--outline-blue button--sm" type="button" data-modal="modal-success" >
                {{ __("json.Add new cargo") }}
            </button>
        </header>


                <!-- <div class="step-form__row"> -->


                    <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div">
                        <form @submit="handleSubmit($event, onSubmit)">
                            <article class="accordion step-cargo is-active">
                                <button class="accordion-toggle step-cargo__toggle" type="button">
                                    {{ __('json.New cargo manifest') }}
                                    <svg class="account-accordion__arrow" width="18" height="12">
                                        <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                                    </svg>
                                </button>
                                <div class="accordion-dropdownm is-active">
                                    <div class="step-cargo__body">
                                        <div class="step-form__col">
                                            <label for="goods_description" class="label">{{ __("json.Goods description") }} <span class="red-color">*</span></label>
                                            <div class="input-group" :class="{ 'has-error': errors.goods_description }">
                                                <Field v-model="form.goods_description" type="text" name="goods_description" v-slot="{field}">
                                                    <input v-bind="field" class="input" id="goods_description" :placeholder="__('json.Goods description')">
                                                </Field>
                                                <div class="input-group__error">
                                                    <ErrorMessage name="goods_description" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="step-form__col">
                                            <label for="gross_weight" class="label">{{ __("json.Gross weight") }} <span class="red-color">*</span></label>
                                            <div class="input-group" :class="{ 'has-error': errors.gross_weight }">
                                                <Field v-model="form.gross_weight" type="text" name="gross_weight" v-slot="{field}">
                                                    <input v-bind="field" class="input" id="gross_weight" :placeholder="__('json.Gross weight')">
                                                </Field>
                                                <div class="input-group__error">
                                                    <ErrorMessage name="gross_weight" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="step-form__col">
                                            <label for="measurement" class="label">{{ __("json.Measurement") }} <span class="red-color">*</span></label>
                                            <div class="input-group" :class="{ 'has-error': errors.measurement }">
                                                <Field v-model="form.measurement" type="text" name="measurement" v-slot="{field}">
                                                    <input v-bind="field" class="input" id="measurement" :placeholder="__('json.Measurement')">
                                                </Field>
                                                <div class="input-group__error">
                                                    <ErrorMessage name="measurement" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="step-form__col">
                                            <label for="marks" class="label">{{ __("json.Marks") }}</label>
                                            <div class="input-group" :class="{ 'has-error': errors.marks }">
                                                <Field v-model="form.marks" type="text" name="marks" v-slot="{field}">
                                                    <input v-bind="field" class="input" id="marks" :placeholder="__('json.Marks')">
                                                </Field>
                                                <div class="input-group__error">
                                                    <ErrorMessage name="marks" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="step-cargo__col step-cargo__col--w-100">
                                            <button type="submit" class="button button--outline-red button--md step-form__delete">{{ __("json.Save") }}</button>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </form>
                    </VeeForm>
<div>
    <CargoManifestList :master-id="updateShipmentId" ref="myCargoManifestList"/>
</div>

                <!-- </div> -->
                <div class="step-form__buttons">
                    <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                        {{ __("json.Previous step") }}
                    </button>
                    <button class="button button--blue step-form__button" type="submit" @click="handleNext">
                        {{ __("json.Next step") }}
                    </button>
                </div>
    </section>

    <!-- <button id="btn-open-new-modal" data-modal="modal-new" style="display: none;"></button> -->
    <div class="modal-wrap">
            
            <div id="modal-success" class="modal">
                <div class="modal__inner">
                    <div class="modal__window">
                        <button
                            class="modal__close"
                            aria-label="Close modal"
                            data-modal-close
                            type="button"
                        >
                            <svg class="modal__close-icon" width="16" height="16">
                                <use
                                    xlink:href="/assets/images/new/sprite.svg#close-modal"
                                ></use>
                            </svg>
                        </button>

                        <svg class="modal__icon" width="60" height="60">
                            <use
                                xlink:href="/assets/images/new/sprite.svg#check-circle"
                            ></use>
                        </svg>
                        <h3 class="modal__title">
                            {{ __("json.Success") }}
                        </h3>
                        <p class="modal__text">
                            <span v-if="action === 'create'">
                                {{
                                    __(
                                        "json.Reservation has been successfully created"
                                    )
                                }}
                            </span>
                            <span v-else>
                                {{
                                    __(
                                        "json.Reservation has been successfully updated"
                                    )
                                }}
                            </span>
                        </p>
                        <div class="modal__buttons">
                            <button
                                class="button button--red modal__button"
                                data-modal-close
                                type="button"
                            >
                                {{ __("json.OK") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


</template>

<script>
import vSelect from "vue-select";
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import {VueTelInput} from "vue-tel-input";
import * as yup from 'yup';
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import {useGetCargoManifests} from "../utils/useGetCargoManifests";
import CargoManifestList from "./CargoManifestList.vue";
import { ref } from "vue";

const myCargoManifestList = ref(null);

const schema = yup.object({
    goods_description: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Goods description') })),
    gross_weight: yup.number().min(0)
        .required(lang('validation.required', { 'attribute': lang('json.Gross weight') })),
    measurement: yup.mixed()
        .required(lang('validation.required', { 'attribute': lang('json.Measurement') })),
    marks: yup.string()
        .nullable()
});

export default {
    name: "Step2",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateShipmentId: {
            type: Number,
            required: false
        },
    },
    components: {
        vSelect,
        VeeForm,
        Field,
        ErrorMessage,
        VueTelInput,
        IconLoading,
        CargoManifestList
    },
    setup() {
        const { getCargoManifests, items } = useGetCargoManifests();
        return {
            getCargoManifests, items
        }
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                goods_description: null,
                gross_weight: null,
                measurement: null,
                marks: null,
            },
            schema,
            isOpen:false
        }
    },
    mounted() {
        this.getCargoManifests(this.updateShipmentId).then((data) => {

        })
    },
    computed: {
        // formWithShipmentType() {
        //     if (!('shipmentType' in this.form)) {
        //         Object.assign(this.form, { 'shipmentType': this.shipmentType })
        //     }
        //     return this.form;
        // },
    },
    emits: ['update:modelValue'],
    methods: {
        async fillCargoManifest() {
            let url = "/shipments/fill-cargo-manifests/"+this.updateShipmentId;
            try{


            var response = await axios.post(url, this.form);


                this.$refs.myCargoManifestList.loadItems();
            }catch{

            }

        },
        handleAdd() {
            //document.getElementById('btn-open-new-modal').click();
            this.isOpen = !this.isOpen;
        },
        onSubmit() {

            this.fillCargoManifest();
        },
        handleNext() {
            const _currentStep = this.modelValue + 1;
            this.$emit('update:modelValue', _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit('update:modelValue', _currentStep);
        },
        // updateFormData() {
        //     this.form.driver_name = this.existingShipment ? this.existingShipment.driver_name : null;
        //     this.form.nationality_id = this.existingShipment ? this.existingShipment.nationality_id : null;
        //     this.form.passport_id = this.existingShipment ? this.existingShipment.passport_id : null;
        //     if (this.existingShipment && this.existingShipment.driver_phone) {
        //         this.form.driver_phone = this.existingShipment.driver_phone;
        //     }
        // },
    },
    // watch: {
    //     'form.driver_phone'() {
    //         this.form.driver_phone = this.form.driver_phone.replace(' ', '');
    //     }
    // },
}
</script>
