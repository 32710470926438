import {ref} from "vue";

export const useGetExistingTrip = () => {
    const existingTrip = ref(null);

    const getExistingTrip = async (tripType, updateTripId) => {
        let url = '/reservation/existing/' + tripType;
        if (updateTripId) {
            url += '/' + updateTripId;
        }
        return await axios.get(url).then(({data}) => {
            existingTrip.value = data;
            return data;
        });
    }

    return { getExistingTrip, existingTrip };
}
