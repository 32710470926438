<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!items" style="margin-left: 20px" />
        </header>

        <!-- <div class="step-form__row"> -->

        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div">
            <form @submit="handleSubmit($event, onSubmit)">
                <article class="accordion step-x is-active">
                    <button class="accordion-toggle step-x__toggle" type="button">
                        {{ __("json.New Item") }}
                        <svg class="account-accordion__arrow" width="18" height="12">
                            <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                        </svg>
                    </button>
                    <div class="accordion-dropdownm is-active">
                        <div class="step-x__body">
                            <div class="step-form__col">
                                <label for="stowage_position" class="label">{{ __("json.Stowage position") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.stowage_position }">
                                    <Field v-model="form.stowage_position" type="text" name="stowage_position" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="stowage_position" :placeholder="__('json.Stowage position')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="stowage_position" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="reference_number" class="label">{{ __("json.Reference number") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.reference_number }">
                                    <Field v-model="form.reference_number" type="text" name="reference_number" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="reference_number" :placeholder="__('json.Reference number')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="reference_number" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="marks" class="label">{{ __("json.Reference number") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.marks }">
                                    <Field v-model="form.marks" type="text" name="marks" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="marks" :placeholder="__('json.Marks')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="marks" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="un_number" class="label">{{ __("json.UN number") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.un_number }">
                                    <Field v-model="form.un_number" type="text" name="un_number" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="un_number" :placeholder="__('json.UN number')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="un_number" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="proper_shipping_name" class="label">{{ __("json.Proper shipping name") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.proper_shipping_name }">
                                    <Field v-model="form.proper_shipping_name" type="text" name="proper_shipping_name" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="proper_shipping_name" :placeholder="__('json.Proper shipping name')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="proper_shipping_name" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="subsidiary_risk_class" class="label">{{ __("json.Subsidiary risk class") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.subsidiary_risk_class }">
                                    <Field v-model="form.subsidiary_risk_class" type="text" name="subsidiary_risk_class" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="subsidiary_risk_class" :placeholder="__('json.Subsidiary risk class')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="subsidiary_risk_class" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="packing_group" class="label">{{ __("json.Packing group") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.packing_group }">
                                    <Field v-model="form.packing_group" type="text" name="packing_group" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="packing_group" :placeholder="__('json.Packing group')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="packing_group" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="additional_information" class="label">{{ __("json.Additional information") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.additional_information }">
                                    <Field v-model="form.additional_information" type="text" name="additional_information" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="additional_information" :placeholder="__('json.Additional information')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="additional_information" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="packages_number_and_kind" class="label">{{ __("json.Packages number and kind") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.packages_number_and_kind }">
                                    <Field v-model="form.packages_number_and_kind" type="text" name="packages_number_and_kind" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="packages_number_and_kind" :placeholder="__('json.Packages number and kind')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="packages_number_and_kind" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="mass_or_volume" class="label">{{ __("json.Mass or volume") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.mass_or_volume }">
                                    <Field v-model="form.mass_or_volume" type="text" name="mass_or_volume" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="mass_or_volume" :placeholder="__('json.Mass or volume')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="mass_or_volume" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="ems" class="label">{{ __("json.EMS") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.ems }">
                                    <Field v-model="form.ems" type="text" name="ems" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="ems" :placeholder="__('json.EMS')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="ems" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="shipping_agent" class="label">{{ __("json.Shipping agent") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.shipping_agent }">
                                    <Field v-model="form.shipping_agent" type="text" name="shipping_agent" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="shipping_agent" :placeholder="__('json.Shipping agent')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="shipping_agent" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="place" class="label">{{ __("json.Place") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.place }">
                                    <Field v-model="form.place" type="text" name="place" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="place" :placeholder="__('json.Place')" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="place" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="date" class="label">{{ __("json.Date") }}
                                    <span class="red-color">*</span></label>
                                <div class="input-group" :class="{ 'has-error': errors.date }">
                                    <Field v-model="form.date" type="text" name="date" v-slot="{ field }">
                                        <input v-bind="field" class="input" id="date" :placeholder="__('json.Date')" type="date" />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="date" />
                                    </div>
                                </div>
                            </div>



                            <div class="step-x__col step-x__col--w-100">
                                <button type="submit" class="button button--outline-red button--md step-form__delete">
                                    {{ __("json.Save") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </article>
            </form>
        </VeeForm>
        <div>
            <GoodList :master-id="updateShipmentId" ref="myGoodList" />
        </div>

        <!-- </div> -->
        <div class="step-form__buttons">
            <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                {{ __("json.Previous step") }}
            </button>
            <button class="button button--blue step-form__button" type="submit" @click="handleNext">
                {{ __("json.Next step") }}
            </button>
        </div>
    </section>

    <!-- <button id="btn-open-new-modal" data-modal="modal-new" style="display: none;"></button> -->
</template>

<script>
import vSelect from "vue-select";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import { VueTelInput } from "vue-tel-input";
import * as yup from "yup";
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import { useGetGoods } from "../utils/useGetGoods";
import GoodList from "./GoodList.vue";
import { ref } from "vue";

const myGoodList = ref(null);

const schema = yup.object({
stowage_position: yup.mixed().required(lang("validation.required", { attribute: lang("json.Stowage position") })),
reference_number: yup.mixed().required(lang("validation.required", { attribute: lang("json.Reference number") })),
marks: yup.mixed().required(lang("validation.required", { attribute: lang("json.Marks") })),
un_number: yup.mixed().required(lang("validation.required", { attribute: lang("json.UN number") })),
proper_shipping_name: yup.mixed().required(lang("validation.required", { attribute: lang("json.Proper shipping name") })),
subsidiary_risk_class: yup.mixed().required(lang("validation.required", { attribute: lang("json.Subsidiary risk class") })),
packing_group: yup.mixed().required(lang("validation.required", { attribute: lang("json.Packing group") })),
additional_information: yup.mixed().required(lang("validation.required", { attribute: lang("json.Additional information") })),
packages_number_and_kind: yup.mixed().required(lang("validation.required", { attribute: lang("json.Packages number and kind") })),
mass_or_volume: yup.mixed().required(lang("validation.required", { attribute: lang("json.Mass or volume") })),
ems: yup.mixed().required(lang("validation.required", { attribute: lang("json.EMS") })),
shipping_agent: yup.mixed().required(lang("validation.required", { attribute: lang("json.Shipping agent") })),
place: yup.mixed().required(lang("validation.required", { attribute: lang("json.Place") })),
date: yup.mixed().required(lang("validation.required", { attribute: lang("json.Date") })),
});

export default {
    name: "Step6",
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        steps: {
            type: Object,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
        updateShipmentId: {
            type: Number,
            required: false,
        },
    },
    components: {
        vSelect,
        VeeForm,
        Field,
        ErrorMessage,
        VueTelInput,
        IconLoading,
        GoodList,
    },
    setup() {
        const { getGoods, items } = useGetGoods();
        return {
            getGoods,
            items,
        };
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                stowage_position: null,
                reference_number: null,
                marks: null,
                un_number: null,
                proper_shipping_name: null,
                subsidiary_risk_class: null,
                packing_group: null,
                additional_information: null,
                packages_number_and_kind: null,
                mass_or_volume: null,
                ems: null,
                shipping_agent: null,
                place: null,
                date: null,
            },
            schema,
        };
    },
    mounted() {
        this.getGoods(this.updateShipmentId).then((data) => { });
    },
    computed: {
        // formWithShipmentType() {
        //     if (!('shipmentType' in this.form)) {
        //         Object.assign(this.form, { 'shipmentType': this.shipmentType })
        //     }
        //     return this.form;
        // },
    },
    emits: ["update:modelValue"],
    methods: {
        async fillGood() {
            let url = "/shipments/fill-good/" + this.updateShipmentId;
            try {
                var response = await axios.post(url, this.form);

                this.$refs.myGoodList.loadItems();
            } catch { }
        },
        handleAdd() {
            //document.getElementById('btn-open-new-modal').click();
        },
        onSubmit() {
            console.log('====================================');
            console.log(this.form);
            console.log('====================================');
            this.fillGood();
        },
        handleNext() {
            const _currentStep = this.modelValue + 1;
            this.$emit("update:modelValue", _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit("update:modelValue", _currentStep);
        },
        // updateFormData() {
        //     this.form.driver_name = this.existingShipment ? this.existingShipment.driver_name : null;
        //     this.form.nationality_id = this.existingShipment ? this.existingShipment.nationality_id : null;
        //     this.form.passport_id = this.existingShipment ? this.existingShipment.passport_id : null;
        //     if (this.existingShipment && this.existingShipment.driver_phone) {
        //         this.form.driver_phone = this.existingShipment.driver_phone;
        //     }
        // },
    },
    // watch: {
    //     'form.driver_phone'() {
    //         this.form.driver_phone = this.form.driver_phone.replace(' ', '');
    //     }
    // },
};
</script>
