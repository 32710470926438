<template>
    <main class="login-page">
        <section class="login-page__content">
            <header class="login-page__header">
                <div class="logo login-page__logo">
                    <a href="/" class="logo__link" aria-label="logo link">
                        <img class="logo__img" src="/assets/images/new/logo.svg" width="209" height="80" alt="logo">
                    </a>
                </div>

                <div class="dropdown login-page__header-lang">
                    <button class="dropdown-toggle" type="button" aria-label="Сhoose language">
                        {{ __("main." + currentLocale) }}
                        <svg class="dropdown-toggle__arrow" width="13" height="8">
                            <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                        </svg>
                    </button>
                    <ul class="dropdown-menu">
                        <li class="dropdown-menu__item" v-for="locale in locales" :key="locale">
                            <a :href="'/locale/' + locale" class="dropdown-menu__link" v-if="locale !== currentLocale">
                                {{ __("main." + locale) }}
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <form class="login-form" @submit.prevent="handleSubmit">
                <div class="login-form__header">
                    <h1 class="login-form__title">
                        {{ __('main.login') }}
                    </h1>
                </div>

                <div class="login-form__group">
                    <div class="input-group" :class="{ 'has-error': hasError }">
                        <input v-model="email" class="input" type="email" name="email" placeholder="Email" autofocus required>
                    </div>
                </div>

                <div class="login-form__group">
                    <div class="input-group" :class="{ 'has-error': hasError }">
                        <input v-model="password" ref="password" class="input" type="password" name="password" placeholder="Password" required>
                        <button class="input-group__show-pass"
                            :class="{ 'is-active': !showPassword }"
                            @click="toggleShowPassword"
                            aria-label="Show password" type="button"
                        >
                            <svg class="input-group__show-pass-icon" width="18" height="16">
                                <use xlink:href="/assets/images/new/sprite.svg#eye"></use>
                            </svg>
                        </button>
                        <div class="input-group__error">
                            {{ __("json.Email or password was incorrect.") }}
                        </div>
                    </div>
                </div>

                <div class="login-form__group login-form__group--flex">
                    <div class="checkbox login-form__checkbox">
                        <input v-model="remember" class="checkbox__input" id="remember" type="checkbox" name="remember">
                        <label class="checkbox__label" for="remember">
                            {{ __('main.remember') }}
                        </label>
                    </div>
                    <a class="login-form__link" href="/forgot-password">
                        {{ __('main.forgot_password') }}
                    </a>
                </div>

                <div class="login-form__group">
                    <button type="submit" class="button button--red login-form__button" :disabled="isLoading">
                        <IconLoading v-if="isLoading" />
                        <span v-else>{{ __("main.continue") }}</span>
                    </button>
                </div>

                <div class="social-login" v-if="isSocialLogin">
                    <h4 class="social-login__title"><span>OR</span></h4>
                    <ul class="social-login__list">
                        <li class="social-login__item">
                            <a class="social-login__link" aria-label="Login with Apple" href="#">
                                <svg width="38" height="38">
                                    <use xlink:href="/assets/images/new/sprite.svg#apple"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="social-login__item">
                            <a class="social-login__link" aria-label="Login with Google" href="#">
                                <svg width="38" height="38">
                                    <use xlink:href="/assets/images/new/sprite.svg#google"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="social-login__item">
                            <a class="social-login__link" aria-label="Login with Facebook" href="#">
                                <svg width="38" height="38">
                                    <use xlink:href="/assets/images/new/sprite.svg#facebook"></use>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </form>

            <footer class="login-page__footer">
                <p class="login-page__info">
                    {{ __("json.Don’t have an account yet?") }}
                    <a href="/auth/register">
                        {{ __('main.register') }}
                    </a>
                </p>

                <div class="dropdown login-page__footer-lang">
                    <button class="dropdown-toggle" type="button" aria-label="Сhoose language">
                        {{ __("main." + currentLocale) }}
                        <svg class="dropdown-toggle__arrow" width="13" height="8">
                            <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                        </svg>
                    </button>
                    <ul class="dropdown-menu">
                        <li class="dropdown-menu__item" v-for="locale in locales" :key="locale">
                            <a :href="'/locale/' + locale" class="dropdown-menu__link" v-if="locale !== currentLocale">
                                {{ __("main." + locale) }}
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        </section>

        <picture class="login-page__picture">
            <img class="login-page__img" src="/assets/images/new/login-img.webp"
                 srcset="/assets/images/new/login-img@2x.webp 2x" width="756" height="1024" alt="image">
        </picture>
    </main>
</template>

<script>
import IconLoading from "../partials/IconLoading.vue";

export default {
    name: "Login",
    components: {
        IconLoading
    },
    data() {
        return {
            isSocialLogin: false,
            isLoading: false,
            hasError: false,
            showPassword: false,
            email: '',
            password: '',
            remember: '',
            currentLocale: window.default_locale,
            locales: window.supported_locales
        }
    },
    methods: {
        handleSubmit() {
            this.isLoading = true;
            this.hasError = false;

            let formData = {
                email: this.email,
                password: this.password,
                remember: this.remember
            };

            axios.post('/auth/login', formData)
                .then((response) => {
                    window.location.href = response.data.url;
                })
                .catch(() => {
                    this.hasError = true;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
            this.$refs.password.type === 'text'
                ? this.$refs.password.type = 'password'
                : this.$refs.password.type = 'text';
        }
    },
    watch: {
        email() {
            this.hasError = false;
        },
        password() {
            this.hasError = false;
        }
    }
}
</script>
