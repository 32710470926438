<template>
    <IconLoading v-if="isLoadingData" />
    <table class="items-table" v-if="items.length > 0">
        <thead>
            <tr >
                <td></td>
                <td>{{ __("json.ID") }}</td>
                <td>{{ __("json.Number") }}</td>
                <td>{{ __("json.Name") }}</td>
                <td>{{ __("json.Family") }}</td>
                <td>{{ __("json.Gender") }}</td>
                    <td>{{ __("json.Nationality") }}</td>
                    <td>#</td>
            </tr>
        </thead>
        <tbody>
            <template v-for="item in items" :key="item.id">
            <tr class="items-list__item"  data-toggle="collapse" :data-target="'.cls' + item.id">
                            <td>
                                <button
                                    class="button button--outline-red button--sm icon-btn-trash"
                                    @click.prevent="deleteItem(item)"
                                >
                                    {{ __("json.X") }}
                                </button>
                            </td>
                            <td >
                                <p class="item-card__text item-card__text--ellipsis">
                                    {{ item.id }}
                                </p>
                            </td>
                            <td >
                                <p class="item-card__text">{{ item.number }}</p>
                            </td>
                            <td >
                                <p class="item-card__text">{{ item.given_name }}</p>
                            </td>
                            <td >
                                <p class="item-card__text">{{ item.family_name }}</p>
                            </td>
                            <td >
                                    <p class="item-card__text">{{ item.gender }}</p>
                                </td>

    <td >
                                    <p class="item-card__text">
                                        {{ item.nationality.name }}
                                    </p>
                                </td>
                                <td><button class="button button--outline-red button--sm" @click="toggleRow(item.id)">+</button></td>



                </tr>
                <tr class="items-list__item my-hide" :class="'cls'+item.id">
                    <td colspan="8">
                        <table class="items-table">
                            <thead>
                                <tr>
                                    <td>{{ __("json.Rank") }}</td>
                    <td>{{ __("json.Ineligible effects") }}</td>
                    <td>{{ __("json.Birthdate") }}</td>
                    <td>{{ __("json.Birth place") }}</td>
                    <td>{{ __("json.ID nature") }}</td>
                    <td>{{ __("json.ID number") }}</td>
                    <td>{{ __("json.Issuance") }}</td>
                    <td>{{ __("json.Expiry") }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td >
                                    <p class="item-card__text">{{ item.rank }}</p>
                                </td>
                                <td >
                                    <p class="item-card__text">
                                        {{ item.ineligible_effects }}
                                    </p>
                                </td>

                                <td >
                                    <p class="item-card__text">{{ formatDate(item.birth_date) }}</p>
                                </td>
                                <td >
                                    <p class="item-card__text">{{ item.birth_place }}</p>
                                </td>

                                <td >
                                    <p class="item-card__text">{{ item.id_doc_nature }}</p>
                                </td>
                                <td>
                                    <p class="item-card__text">{{ item.id_doc_number }}</p>
                                </td>
                                <td>
                                    <p class="item-card__text">
                                        {{ item.idDocIssuingState.name }}
                                    </p>
                                </td>
                                <td>
                                    <p class="item-card__text">
                                        {{ formatDate(item.id_doc_expiry_date) }}
                                    </p>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>

                    </tr>
            </template>

        </tbody>
    </table>
    <!-- <ul class="items-list" v-if="items.length > 0">
        <li class="items-list__item" v-for="item in items" :key="item.id">
            <article class="item-card">
                <div class="item-card__row">
                    <div class="item-card__col item-card__buttons">
                        <a
                            class="button button--red button--sm"
                            @click.prevent="deleteItem(item)"
                        >
                            {{ __("json.Delete") }}
                        </a>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">{{ __("json.ID") }}</h4>
                        <p class="item-card__text item-card__text--ellipsis">
                            {{ item.id }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Number") }}
                        </h4>
                        <p class="item-card__text">{{ item.number }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">{{ __("json.Name") }}</h4>
                        <p class="item-card__text">{{ item.given_name }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Family") }}
                        </h4>
                        <p class="item-card__text">{{ item.family_name }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">{{ __("json.Rank") }}</h4>
                        <p class="item-card__text">{{ item.rank }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Ineligible effects") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.ineligible_effects }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Nationality") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.nationality.name }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Birthdate") }}
                        </h4>
                        <p class="item-card__text">{{ formatDate( item.birth_date) }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Birth place") }}
                        </h4>
                        <p class="item-card__text">{{ item.birth_place }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Gender") }}
                        </h4>
                        <p class="item-card__text">{{ item.gender }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.ID nature") }}
                        </h4>
                        <p class="item-card__text">{{ item.id_doc_nature }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.ID number") }}
                        </h4>
                        <p class="item-card__text">{{ item.id_doc_number }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Issuance") }}
                        </h4>
                        <p class="item-card__text">
                            {{ item.idDocIssuingState.name }}
                        </p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">
                            {{ __("json.Expiry") }}
                        </h4>
                        <p class="item-card__text">
                            {{ formatDate( item.id_doc_expiry_date )}}
                        </p>
                    </div>
                </div>
            </article>
        </li>
    </ul> -->
    <div v-else>
        <div class="account-empty">
            <img
                class="account-empty__img"
                src="/assets/images/new/empty-img.svg"
                width="80"
                height="80"
                alt="empty"
            />
            <p class="account-empty__text">
                {{ __("items.no_items_yet") }}
            </p>
        </div>
    </div>
</template>
<style>
.items-table {
    width: 100%;
    border-collapse: collapse;
}
.items-table thead tr {
    border: 2px solid #EAEBEF;
    background: rgb(240, 56, 51);
    color: white;
}


.items-table tbody tr:nth-child(2n) {
    background: #EAEBEF;
}

.width100 {
    max-width: 100px;
}
tr.collapse.in {
        display: table-row;
      }

      .my-hide{
        display: none;

      }
</style>
<script>
import IconLoading from "../partials/IconLoading.vue";
import { useLocalTime } from "../utils/useLocalTime";

export default {
    name: "ItemList",
    props: ["master-id"],
    components: {
        IconLoading,
    },
    setup() {
        const { toLocalTime } = useLocalTime();
        return {
            toLocalTime,
        };
    },
    data() {
        return {
            items: [],
            refreshList: "false",
            itemToDelete: null,
            isLoadingData: false,
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
    toggleRow(id){
        console.log('====================================');
        console.log(id);
        console.log('====================================');
        document.getElementsByClassName('cls'+id)[0].classList.toggle('my-hide');
    },
        formatDate(dateString) {
            if (!dateString) {
                return null;
            }
            let date = new Date(dateString);
            let year = date.toLocaleString("default", { year: "numeric" });
            let month = date.toLocaleString("default", { month: "2-digit" });
            let day = date.toLocaleString("default", { day: "2-digit" });

            return year + "-" + month + "-" + day;
        },
        deleteItem(item) {
            this.isLoadingData = true;

            axios
                .delete("/shipments/delete-crew/" + item.id)
                .then(() => {
                    this.items = this.items.filter((t) => t.id !== item.id);
                })
                .catch((err) => {
                    console.log(err);
                    alert("Something went wrong! Please, try again.");
                })
                .finally(() => {
                    this.isLoadingData = false;
                });
        },
        loadItems() {
            this.isLoadingData = true;

            axios
                .get("/shipments/crews/" + this.masterId)
                .then(({ data }) => {
                    this.items = data;
                })
                .finally(() => {
                    this.$nextTick(() => {
                        window.initAccordion(".accordion-vue");
                        window.initDropdown(".dropdown-vue");
                        this.isLoadingData = false;
                    });
                });
        },
    },
    computed: {},
    expose: ["loadItems"],
};
</script>
