<template>
    <IconLoading v-if="isLoadingData" />
    <table class="items-table" v-if="items.length > 0">
        <thead>
            <tr >
                <td></td>
                <td>{{ __("json.ID") }}</td>
                <td>{{ __("json.Goods description") }}</td>
                <td>{{ __("json.Gross weight") }}</td>
                <td>{{ __("json.Measurement") }}</td>
                <td>{{ __("json.Marks") }}</td>
            </tr>
        </thead>
        <tbody>
            <tr class="items-list__item" v-for="item in items" :key="item.id">
                        <td>
                            <a
                                class="button button--outline-red button--sm"
                                @click.prevent="deleteItem(item)"
                            >
                                {{ __("json.Delete") }}
                            </a>
                        </td>
                        <td >
                            <p class="item-card__text item-card__text--ellipsis">
                                {{ item.id }}
                            </p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.goods_description }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.gross_weight }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.measurement }}</p>
                        </td>
                        <td >
                            <p class="item-card__text">{{ item.marks }}</p>
                        </td>
            </tr>
        </tbody>
    </table>

    <!-- <ul class="cargomanifest-list" v-if="items.length > 0">

        <li class="cargomanifest-list__item" v-for="item in items" :key="item.id">
            <article class="item-card">
                <div class="item-card__row">
                    <div class="item-card__col item-card__buttons">
                            <a class="button button--red button--sm" @click.prevent="deleteItem(item)">
                                {{ __('json.Delete') }}
                            </a></div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">{{ __("json.ID") }}</h4>
                        <p class="item-card__text item-card__text--ellipsis">{{ item.id }}</p>
                    </div>
                    <div class="item-card__col">
                        <h4 class="item-card__title">{{ __("json.Goods description") }}</h4>
                        <p class="item-card__text">{{ item.goods_description }}</p>
                    </div>
                    <div class="item-card__col">
                            <h4 class="item-card__title">{{ __("json.Gross weight") }}</h4>
                            <p class="item-card__text">
                                {{ item.gross_weight }}
                            </p>
                        </div><div class="item-card__col">
                            <h4 class="item-card__title">{{ __("json.Measurement") }}</h4>
                            <p class="item-card__text">
                                {{ item.measurement }}
                            </p>
                        </div>
                        <div class="item-card__col">
                                <h4 class="item-card__title">{{ __("json.Marks") }}</h4>
                                <p class="item-card__text">
                                    {{ item.marks }}
                                </p>
                            </div>


                </div>
            </article>
        </li>
    </ul> -->
    <div v-else>
        <div class="account-empty">
            <img class="account-empty__img" src="/assets/images/new/empty-img.svg" width="80" height="80" alt="empty">
            <p class="account-empty__text">
                {{ __('items.no_items_yet') }}
            </p>
        </div>
    </div>
</template>
<style>
.items-table {
    width: 100%;
    border-collapse: collapse;
}
.items-table thead tr {
    border: 2px solid #EAEBEF;
    background: rgb(240, 56, 51);
    color: white;
}

.items-table thead th {
    border: 0;
    list-style: none;
    background: rgb(240, 56, 51);
}

.items-table tbody tr:nth-child(2n) {
    background: #EAEBEF;
}

.button--outline-red {
    max-width: 100px;
}
</style>
<script>
import IconLoading from "../partials/IconLoading.vue";
import {useLocalTime} from "../utils/useLocalTime";

export default {
    name: "ItemList",
    props: ['master-id'],
    components: {
        IconLoading,
    },
    setup() {
        const { toLocalTime } = useLocalTime();
        return {
            toLocalTime
        }
    },
    data() {
        return {
            items: [],
            refreshList: 'false',
            itemToDelete: null,
            isLoadingData:false,
        }
    },
    mounted() {

        this.loadItems();
    },
    methods: {

        deleteItem(item) {
            this.isLoadingData = true;

            axios
                .delete("/shipments/delete-cargo-manifest/" + item.id)
                .then(() => {
                    this.items = this.items.filter((t) => t.id !== item.id);
                })
                .catch((err) => {
                    console.log(err);
                    alert("Something went wrong! Please, try again.");
                })
                .finally(() => {
                    this.isLoadingData = false;
                });
        },
        loadItems() {
            this.isLoadingData = true;

            axios.get('/shipments/cargo-manifests/' + this.masterId).then(({data}) => {
                this.items = data;

            }).finally(() => {
                this.$nextTick(() => {
                    window.initAccordion('.accordion-vue');
                    window.initDropdown('.dropdown-vue');
                    this.isLoadingData = false;
                });
            });
        },
    },
    computed: {

    },
    expose:['loadItems']
}
</script>
