<template>
    <main class="login-page">
        <section class="login-page__content">
            <header class="login-page__header">
                <div class="logo login-page__logo">
                    <a href="/" class="logo__link" aria-label="logo link">
                        <img class="logo__img" src="/assets/images/new/logo.svg" width="209" height="80" alt="logo">
                    </a>
                </div>

                <div class="dropdown login-page__header-lang">
                    <button class="dropdown-toggle" type="button" aria-label="Сhoose language">
                        {{ __("main." + currentLocale) }}
                        <svg class="dropdown-toggle__arrow" width="13" height="8">
                            <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                        </svg>
                    </button>
                    <ul class="dropdown-menu">
                        <li class="dropdown-menu__item" v-for="locale in locales" :key="locale">
                            <a :href="'/locale/' + locale" class="dropdown-menu__link" v-if="locale !== currentLocale">
                                {{ __("main." + locale) }}
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <form class="login-form" @submit.prevent="handleSubmit">
                <div class="login-form__header">
                    <h1 class="login-form__title">
                        {{ __('main.register') }}
                    </h1>
                </div>

                <div class="login-form__group">
                    <div class="input-group" :class="{ 'has-error': errors['name'] }">
                        <input v-model="name" class="input" type="text" name="name" :placeholder="__('main.name')" autofocus required>
                        <div class="input-group__error">{{ errors['name']?.[0] }}</div>
                    </div>
                </div>

                <div class="login-form__group">
                    <div class="input-group" :class="{ 'has-error': errors['email'] }">
                        <input v-model="email" class="input" type="email" name="email" :placeholder="__('main.email')" required>
                        <div class="input-group__error">{{ errors['email']?.[0] }}</div>
                    </div>
                </div>

                <div class="login-form__group">
                    <div class="input-group" :class="{ 'has-error': errors['phone'] }">
                        <input v-model="phone" class="input" type="tel" name="phone" id="phone" :placeholder="__('main.phone')" required>
                        <div class="input-group__error">{{ errors['phone']?.[0] }}</div>
                    </div>
                </div>

                <div class="login-form__group">
                    <div class="input-group" :class="{ 'has-error': errors['password'] }">
                        <input v-model="password" ref="password" class="input" type="password" name="password" :placeholder="__('main.password')" required>
                        <button class="input-group__show-pass"
                                :class="{ 'is-active': !showPassword }"
                                @click="toggleShowPassword"
                                aria-label="Show password" type="button"
                        >
                            <svg class="input-group__show-pass-icon" width="18" height="16">
                                <use xlink:href="/assets/images/new/sprite.svg#eye"></use>
                            </svg>
                        </button>
                        <div class="input-group__error">{{ errors['password']?.[0] }}</div>
                    </div>
                </div>

                <div class="login-form__group login-form__group--flex">
                    <div class="checkbox login-form__checkbox">
                        <input v-model="remember" class="checkbox__input" id="remember" type="checkbox" name="remember">
                        <label class="checkbox__label" for="remember">
                            {{ __('main.remember') }}
                        </label>
                    </div>
                </div>

                <div class="login-form__group login-form__captcha">
                    <div class="input-group" :class="{ 'has-error': errors['g-recaptcha-response'] }">
                        <VueRecaptcha
                            :sitekey="siteKey"
                            :loadRecaptchaScript="true"
                            ref="recaptcha"
                            type="invisible"
                            @verify="onCaptchaVerified"
                            @expired="onCaptchaExpired"
                            :style="{'display': 'inline-block'}"
                        >
                        </VueRecaptcha>
                        <div class="input-group__error">Please, pass recaptcha.</div>
                    </div>
                </div>

                <div class="login-form__group">
                    <button type="submit" class="button button--red login-form__button" :disabled="isLoading">
                        <IconLoading v-if="isLoading" />
                        <span v-else>{{ __("main.continue") }}</span>
                    </button>
                </div>
            </form>

            <footer class="login-page__footer">
                <p class="login-page__info">
                    {{ __("json.Do you have account?") }}
                    <a href="/auth/login">
                        {{ __('main.login') }}
                    </a>
                </p>

                <div class="dropdown login-page__footer-lang">
                    <button class="dropdown-toggle" type="button" aria-label="Сhoose language">
                        {{ __("main." + currentLocale) }}
                        <svg class="dropdown-toggle__arrow" width="13" height="8">
                            <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                        </svg>
                    </button>
                    <ul class="dropdown-menu">
                        <li class="dropdown-menu__item" v-for="locale in locales" :key="locale">
                            <a :href="'/locale/' + locale" class="dropdown-menu__link" v-if="locale !== currentLocale">
                                {{ __("main." + locale) }}
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        </section>

        <picture class="login-page__picture">
            <img class="login-page__img" src="/assets/images/new/login-img.webp"
                 srcset="/assets/images/new/login-img@2x.webp 2x" width="756" height="1024" alt="image">
        </picture>
    </main>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import IconLoading from "../partials/IconLoading.vue";

export default {
    name: "Register",
    components: {
        VueRecaptcha,
        IconLoading
    },
    data() {
        return {
            siteKey: window.g_recaptcha_site_key,
            isLoading: false,
            showPassword: false,
            errors: [],
            name: '',
            email: '',
            phone: '',
            password: '',
            remember: '',
            recaptcha: '',
            currentLocale: window.default_locale,
            locales: window.supported_locales
        }
    },
    methods: {
        handleSubmit() {
            this.isLoading = true;
            this.errors = [];

            let formData = {
                name: this.name,
                email: this.email,
                phone: this.phone,
                password: this.password,
                remember: this.remember,
                'g-recaptcha-response': this.recaptcha
            };

            axios.post('/auth/register', formData)
                .then(() => {
                    window.location.href = '/home';
                })
                .catch((e) => {
                    this.errors = e.response.data.errors;
                    this.onCaptchaExpired()
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        onCaptchaVerified: function (recaptchaToken) {
            this.recaptcha = recaptchaToken;
        },
        onCaptchaExpired: function () {
            this.$refs.recaptcha.reset();
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
            this.$refs.password.type === 'text'
                ? this.$refs.password.type = 'password'
                : this.$refs.password.type = 'text';
        }
    },
    watch: {
        name() {
            this.errors['name'] = null;
        },
        email() {
            this.errors['email'] = null;
        },
        phone() {
            this.errors['phone'] = null;
        },
        password() {
            this.errors['password'] = null;
        },
        recaptcha() {
            this.errors['g-recaptcha-response'] = null;
        }
    }
}
</script>
