<template>
    <div class="step-progress">
        <div class="step-progress__row">
            <div v-for="(step, index) in Object.keys(steps)"
                 :key="index"
                 class="step-progress__item"
                 :class="{ 'is-active': +step === currentStep, 'is-filled': +step < currentStep }"
            >
                <h3 class="step-progress__title">
                    {{ breakStepTitle(steps[step]) }}
                </h3>
                <div class="step-progress__number">{{ step }}</div>
            </div>
        </div>

        <div class="progress step-progress__line">
            <div class="progress__bar" role="progressbar" aria-label="Steps progress" :style="{ 'width': stepWidth + '%' }" :aria-valuenow="stepWidth" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepperHead",
    props: {
        steps: {
            type: Object,
            required: true
        },
        currentStep: {
            type: [Number, String],
            required: true
        },
    },
    methods: {
        breakStepTitle(title) {
            return title.split(' ').map((word, index, words) => {
                if ((index === 0 && words.length === 2) || (index === 1 && words.length === 3)) {
                    return word + "\n";
                }

                return word + ' ';
            }).join('');
        }
    },
    computed: {
        stepWidth() {
            return (((this.currentStep - 1) / (Object.keys(this.steps).length - 1)) * 100).toFixed(2);
        }
    }
}
</script>
