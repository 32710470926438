export const startIcon = `<svg width="{{width}}" height="{{height}}" viewBox="0 0 111 153" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M111 57.1954C111 107 55.5001 153 55.5001 153C55.5001 153 0 107 0 57.1954C0.225274 49.4357 1.84003 41.8009 4.75181 34.7275C7.6636 27.654 11.8154 21.2807 16.9698 15.9718C22.1242 10.6629 28.1802 6.52254 34.7915 3.7875C41.4028 1.05245 48.4397 -0.223679 55.5001 0.0320644C62.5604 -0.223679 69.5974 1.05245 76.2087 3.7875C82.82 6.52254 88.8759 10.6629 94.0303 15.9718C99.1848 21.2807 103.337 27.654 106.248 34.7275C109.16 41.8009 110.775 49.4357 111 57.1954Z" fill="#20BA68"/>
<path d="M96.918 55.712C96.918 78.6738 78.3745 97.288 55.5001 97.288C32.6256 97.288 14.0822 78.6738 14.0822 55.712C14.0822 32.7501 32.6256 14.1359 55.5001 14.1359C78.3745 14.1359 96.918 32.7501 96.918 55.712Z" fill="#FAFAFD"/>
<path d="M27.8558 53.2005C26.8251 52.9631 25.6358 52.6465 25.6358 51.6573C25.6358 50.6681 26.4683 49.9756 27.9747 49.9756C31.0074 49.9756 30.7299 52.0925 32.2363 52.0925C33.0292 52.0925 33.7229 51.6177 33.7229 50.8066C33.7229 48.9072 30.7299 47.4828 28.1927 47.4828C25.4376 47.4828 22.504 48.6698 22.504 51.8353C22.504 53.3587 23.0392 54.9811 25.9926 55.7329L29.6595 56.6628C30.7695 56.9397 31.047 57.5728 31.047 58.1466C31.047 59.0962 30.1154 60.0261 28.4306 60.0261C25.1402 60.0261 25.5961 57.4541 23.832 57.4541C23.0392 57.4541 22.4644 58.0081 22.4644 58.7995C22.4644 60.3427 24.3077 62.4003 28.4306 62.4003C32.3552 62.4003 34.2977 60.4812 34.2977 57.9092C34.2977 56.2473 33.5445 54.4865 30.5713 53.8138L27.8558 53.2005Z" fill="#20BA68"/>
<path d="M40.1564 60.6988C40.1564 61.6682 40.7907 62.3211 41.7817 62.3211C42.7728 62.3211 43.4071 61.6682 43.4071 60.6988V50.5494H46.4794C47.411 50.5494 48.1642 50.0745 48.1642 49.1842C48.1642 48.2939 47.411 47.8191 46.4794 47.8191H37.084C36.1524 47.8191 35.3992 48.2939 35.3992 49.1842C35.3992 50.0745 36.1524 50.5494 37.084 50.5494H40.1564V60.6988Z" fill="#20BA68"/>
<path d="M47.813 59.6502C47.6941 59.9668 47.4959 60.5405 47.4959 60.8966C47.4959 61.7672 48.1103 62.3211 49.0023 62.3211C49.7753 62.3211 50.2312 62.0639 50.4493 61.4308L51.3016 59.0567H56.5344L57.3669 61.4308C57.6048 62.0639 58.1202 62.3211 58.8337 62.3211C59.6662 62.3211 60.36 61.688 60.36 60.8966C60.36 60.5405 60.1618 59.9668 60.0428 59.6502L56.1182 48.749C55.7614 47.74 55.1073 47.5619 54.3739 47.5619H53.6009C52.808 47.5619 52.2927 47.8191 52.0152 48.5511L47.813 59.6502ZM55.8209 56.8013H52.0152L53.9378 51.0835H53.9775L55.8209 56.8013Z" fill="#20BA68"/>
<path d="M62.0446 60.6988C62.0446 61.6682 62.6789 62.3211 63.67 62.3211C64.6611 62.3211 65.2954 61.6682 65.2954 60.6988V56.5243H68.5064C70.2904 56.5243 70.7463 57.5333 70.7463 60.0261C70.7463 60.5999 70.885 62.3211 72.6689 62.3211C73.4222 62.3211 74.1952 61.8067 74.1952 61.0549C74.1952 60.3822 73.7591 59.7689 73.7591 58.1268C73.7591 56.6825 73.4618 55.4757 71.7968 55.2383V55.1987C73.3429 54.8426 74.2547 53.3192 74.2547 51.776C74.2547 49.936 73.2239 47.8191 69.3588 47.8191H63.9475C62.7979 47.8191 62.0446 48.2741 62.0446 49.7184V60.6988ZM65.2954 50.3119H68.6452C70.0723 50.3119 71.0039 50.7076 71.0039 52.231C71.0039 53.8336 69.775 54.1501 68.5461 54.1501H65.2954V50.3119Z" fill="#20BA68"/>
<path d="M79.8767 60.6988C79.8767 61.6682 80.5109 62.3211 81.502 62.3211C82.4931 62.3211 83.1274 61.6682 83.1274 60.6988V50.5494H86.1997C87.1313 50.5494 87.8845 50.0745 87.8845 49.1842C87.8845 48.2939 87.1313 47.8191 86.1997 47.8191H76.8043C75.8727 47.8191 75.1195 48.2939 75.1195 49.1842C75.1195 50.0745 75.8727 50.5494 76.8043 50.5494H79.8767V60.6988Z" fill="#20BA68"/>
</svg>
`;
export const finishIcon = `<svg width="{{width}}" height="{{height}}" viewBox="0 0 111 153" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M111 57.1954C111 107 55.5 153 55.5 153C55.5 153 0 107 0 57.1954C0.225274 49.4357 1.83996 41.8009 4.75174 34.7275C7.66353 27.654 11.8153 21.2807 16.9697 15.9718C22.1241 10.6629 28.1801 6.52254 34.7914 3.7875C41.4027 1.05245 48.4397 -0.223679 55.5 0.0320644C62.5603 -0.223679 69.5973 1.05245 76.2086 3.7875C82.8199 6.52254 88.8759 10.6629 94.0303 15.9718C99.1847 21.2807 103.336 27.654 106.248 34.7275C109.16 41.8009 110.775 49.4357 111 57.1954Z" fill="#E74B53"/>
<path d="M96.9179 55.712C96.9179 78.6738 78.3745 97.288 55.5 97.288C32.6255 97.288 14.0821 78.6738 14.0821 55.712C14.0821 32.7501 32.6255 14.1359 55.5 14.1359C78.3745 14.1359 96.9179 32.7501 96.9179 55.712Z" fill="#FAFAFD"/>
<path d="M22.4643 60.6988C22.4643 61.6682 23.0986 62.3211 24.0896 62.3211C25.0807 62.3211 25.715 61.6682 25.715 60.6988V56.2473H30.6307C31.483 56.2473 32.157 56.0099 32.157 55.0009C32.157 53.9919 31.483 53.7544 30.6307 53.7544H25.715V50.4306H31.5227C32.4741 50.4306 33.1679 50.1537 33.1679 49.1249C33.1679 48.0961 32.4741 47.8191 31.5227 47.8191H24.3671C23.2175 47.8191 22.4643 48.2741 22.4643 49.7184V60.6988Z" fill="#E74B53"/>
<path d="M34.8702 60.6988C34.8702 61.6682 35.5045 62.3211 36.4955 62.3211C37.4866 62.3211 38.1209 61.6682 38.1209 60.6988V49.1842C38.1209 48.2148 37.4866 47.5619 36.4955 47.5619C35.5045 47.5619 34.8702 48.2148 34.8702 49.1842V60.6988Z" fill="#E74B53"/>
<path d="M41.0823 60.7581C41.0823 61.8067 41.677 62.3211 42.6482 62.3211C43.6195 62.3211 44.2141 61.8067 44.2141 60.7581V52.5476H44.2538L49.9029 61.4506C50.3191 62.1035 50.6561 62.3211 51.6868 62.3211C53.0545 62.3211 53.6095 61.5891 53.6095 60.3031V49.1249C53.6095 48.0763 53.0148 47.5619 52.0436 47.5619C51.0723 47.5619 50.4777 48.0763 50.4777 49.1249V57.5728H50.438L44.7493 48.4126C44.3925 47.8191 43.7979 47.5619 43.1239 47.5619C41.7364 47.5619 41.0823 48.1159 41.0823 49.4612V60.7581Z" fill="#E74B53"/>
<path d="M56.5692 60.6988C56.5692 61.6682 57.2035 62.3211 58.1946 62.3211C59.1856 62.3211 59.8199 61.6682 59.8199 60.6988V49.1842C59.8199 48.2148 59.1856 47.5619 58.1946 47.5619C57.2035 47.5619 56.5692 48.2148 56.5692 49.1842V60.6988Z" fill="#E74B53"/>
<path d="M67.598 53.2005C66.5673 52.9631 65.378 52.6465 65.378 51.6573C65.378 50.6681 66.2105 49.9756 67.7169 49.9756C70.7496 49.9756 70.4721 52.0925 71.9785 52.0925C72.7714 52.0925 73.4651 51.6177 73.4651 50.8066C73.4651 48.9072 70.4721 47.4828 67.935 47.4828C65.1798 47.4828 62.2462 48.6698 62.2462 51.8353C62.2462 53.3587 62.7814 54.9811 65.7348 55.7329L69.4017 56.6628C70.5117 56.9397 70.7892 57.5728 70.7892 58.1466C70.7892 59.0962 69.8576 60.0261 68.1728 60.0261C64.8824 60.0261 65.3383 57.4541 63.5742 57.4541C62.7814 57.4541 62.2066 58.0081 62.2066 58.7995C62.2066 60.3427 64.0499 62.4003 68.1728 62.4003C72.0975 62.4003 74.04 60.4812 74.04 57.9092C74.04 56.2473 73.2867 54.4865 70.3135 53.8138L67.598 53.2005Z" fill="#E74B53"/>
<path d="M76.41 60.6988C76.41 61.6682 77.0443 62.3211 78.0354 62.3211C79.0264 62.3211 79.6607 61.6682 79.6607 60.6988V55.9505H85.5675V60.6988C85.5675 61.6682 86.2018 62.3211 87.1929 62.3211C88.1839 62.3211 88.8182 61.6682 88.8182 60.6988V49.1842C88.8182 48.2148 88.1839 47.5619 87.1929 47.5619C86.2018 47.5619 85.5675 48.2148 85.5675 49.1842V53.2203H79.6607V49.1842C79.6607 48.2148 79.0264 47.5619 78.0354 47.5619C77.0443 47.5619 76.41 48.2148 76.41 49.1842V60.6988Z" fill="#E74B53"/>
</svg>
`;
export const blueFlag = `<svg width="{{width}}" height="{{height}}" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_113_19737)">
    <path d="M3.54433 15.8365L2.40615 4.72325C2.38959 4.56069 2.42119 4.39687 2.49709 4.25186C2.57299 4.10685 2.68989 3.98694 2.83346 3.90684C8.18962 0.508402 10.7633 1.88731 13.1342 3.15684C15.0089 4.16075 16.7221 5.07871 20.43 1.95371C20.6046 1.8072 20.83 1.73417 21.0581 1.75023C21.2862 1.76629 21.499 1.87015 21.6509 2.03965C21.7654 2.17251 21.8344 2.33803 21.8478 2.51231L22.986 13.6236C22.9992 13.7529 22.9814 13.8833 22.9342 14.0045C22.887 14.1256 22.8116 14.234 22.7143 14.3209C18.4234 18.1959 15.8222 16.8912 13.1441 15.5455C10.976 14.4537 8.73311 13.3307 4.96411 16.4303C4.78754 16.575 4.56103 16.6458 4.3327 16.6276C4.10437 16.6093 3.89219 16.5035 3.74125 16.3326C3.62252 16.1932 3.55333 16.0188 3.54433 15.8365Z" fill="#286BB8"/>
    <path d="M3.52249 3.26418L5.46804 22.522C5.48992 22.7 5.4757 22.8806 5.42622 23.0531C5.37674 23.2256 5.293 23.3865 5.17991 23.5265C5.06682 23.6664 4.92668 23.7825 4.7677 23.868C4.60873 23.9534 4.43414 24.0065 4.2542 24.0241C4.07425 24.0416 3.89258 24.0234 3.71986 23.9703C3.54713 23.9172 3.38684 23.8305 3.24841 23.7151C3.10998 23.5997 2.99619 23.4581 2.91374 23.2985C2.83129 23.1388 2.78184 22.9645 2.76829 22.7857L0.828652 3.59035C0.502614 3.35714 0.255047 3.03185 0.118276 2.65694C-0.0184951 2.28203 -0.0381147 1.87493 0.061979 1.48879C0.162073 1.10265 0.377228 0.755419 0.679352 0.492432C0.981477 0.229444 1.35653 0.0629228 1.75554 0.0146101C2.15455 -0.0337025 2.55898 0.0384386 2.91602 0.221614C3.27306 0.40479 3.56611 0.690487 3.75692 1.0414C3.94773 1.39232 4.02743 1.79215 3.98561 2.18868C3.94379 2.58521 3.78239 2.96003 3.52249 3.26418Z" fill="#454D5C"/>
    </g>
    <defs>
    <clipPath id="clip0_113_19737">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>`;
export const redFlag = `<svg width="{{width}}" height="{{height}}" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_115_24187)">
<path d="M4.46932 19.7942L3.03422 5.90259C3.01334 5.69939 3.05319 5.49462 3.14889 5.31336C3.24459 5.13209 3.39198 4.98222 3.573 4.88208C10.3264 0.634038 13.5715 2.35767 16.5609 3.94458C18.9246 5.19947 21.0847 6.34693 25.76 2.44068C25.9801 2.25753 26.2643 2.16625 26.5519 2.18632C26.8395 2.20639 27.1078 2.33622 27.2994 2.5481C27.4437 2.71417 27.5307 2.92108 27.5477 3.13892L28.9828 17.0281C28.9993 17.1896 28.977 17.3527 28.9174 17.5042C28.8579 17.6556 28.7629 17.7911 28.6401 17.8997C23.2299 22.7434 19.9501 21.1126 16.5733 19.4304C13.8397 18.0657 11.0117 16.6619 6.25947 20.5364C6.03684 20.7173 5.75125 20.8058 5.46335 20.783C5.17545 20.7602 4.90793 20.6279 4.71761 20.4143C4.56791 20.24 4.48067 20.0221 4.46932 19.7942Z" fill="#C81B24"/>
<path d="M4.44165 4.08022L6.89473 28.1525C6.92232 28.375 6.9044 28.6007 6.84201 28.8163C6.77962 29.032 6.67403 29.2332 6.53145 29.4081C6.38886 29.583 6.21215 29.7281 6.01171 29.835C5.81127 29.9418 5.59113 30.0081 5.36424 30.0301C5.13735 30.052 4.90829 30.0292 4.69051 29.9629C4.47273 29.8966 4.27062 29.7881 4.09608 29.6439C3.92153 29.4997 3.77806 29.3226 3.6741 29.1231C3.57014 28.9236 3.50779 28.7056 3.49071 28.4821L1.04508 4.48794C0.633985 4.19643 0.321836 3.78981 0.149385 3.32117C-0.0230651 2.85254 -0.0478029 2.34366 0.0784021 1.86099C0.204607 1.37831 0.47589 0.944274 0.85683 0.61554C1.23777 0.286805 1.71066 0.0786536 2.21376 0.0182627C2.71687 -0.0421282 3.2268 0.0480482 3.67698 0.277018C4.12715 0.505987 4.49666 0.863109 4.73725 1.30175C4.97783 1.7404 5.07832 2.24018 5.02559 2.73585C4.97285 3.23152 4.76935 3.70003 4.44165 4.08022Z" fill="#454D5C"/>
</g>
<defs>
<clipPath id="clip0_115_24187">
<rect width="29" height="30" fill="white"/>
</clipPath>
</defs>
</svg>`;

export const yellowPoint = `<svg width="{{width}}" height="{{height}}" viewBox="0 0 58 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M58 29.5323C58 49.2369 29 79 29 79C29 79 0 49.2369 0 29.5323C0.117711 25.5256 0.961421 21.5835 2.48289 17.9312C4.00437 14.2789 6.17376 10.9881 8.86706 8.24686C11.5604 5.50566 14.7247 3.36785 18.1793 1.95564C21.6338 0.543423 25.3108 -0.115494 29 0.0165561C32.6892 -0.115494 36.3661 0.543423 39.8207 1.95564C43.2752 3.36785 46.4396 5.50566 49.1329 8.24686C51.8262 10.9881 53.9956 14.2789 55.5171 17.9312C57.0386 21.5835 57.8823 25.5256 58 29.5323Z" fill="#C26C32"/>
<path d="M48.0448 26.1902C48.0448 36.6236 39.5181 45.0815 29 45.0815C18.4819 45.0815 9.95522 36.6236 9.95522 26.1902C9.95522 15.7568 18.4819 7.29891 29 7.29891C39.5181 7.29891 48.0448 15.7568 48.0448 26.1902Z" fill="#FAFAFD"/>
</svg>`;

export const alertIcon = `<svg width="{{width}}" height="{{height}}" viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8.06299 12.0625C8.06299 12.3111 8.16177 12.5496 8.33758 12.7254C8.5134 12.9012 8.75185 13 9.00049 13C9.24914 13 9.48759 12.9012 9.66341 12.7254C9.83922 12.5496 9.93799 12.3111 9.93799 12.0625C9.93799 11.8139 9.83922 11.5754 9.66341 11.3996C9.48759 11.2238 9.24914 11.125 9.00049 11.125C8.75185 11.125 8.5134 11.2238 8.33758 11.3996C8.16177 11.5754 8.06299 11.8139 8.06299 12.0625V12.0625ZM8.37549 6.125V9.71875C8.37549 9.80469 8.44581 9.875 8.53174 9.875H9.46924C9.55518 9.875 9.62549 9.80469 9.62549 9.71875V6.125C9.62549 6.03906 9.55518 5.96875 9.46924 5.96875H8.53174C8.44581 5.96875 8.37549 6.03906 8.37549 6.125ZM17.6665 14.7188L9.54151 0.65625C9.42042 0.447266 9.21143 0.34375 9.00049 0.34375C8.78956 0.34375 8.57862 0.447266 8.45948 0.65625L0.334479 14.7188C0.0942449 15.1367 0.395026 15.6562 0.875495 15.6562H17.1255C17.606 15.6562 17.9067 15.1367 17.6665 14.7188ZM2.36378 14.1738L9.00049 2.68555L15.6372 14.1738H2.36378V14.1738Z' fill='#F03833'/></svg>`;
