<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!items" style="margin-left: 20px" />
        </header>

        <!-- <div class="step-form__row"> -->

        <VeeForm
            v-slot="{ handleSubmit, errors }"
            :validation-schema="schema"
            as="div"
        >
            <form @submit="handleSubmit($event, onSubmit)">
                <article class="accordion step-x is-active">
                    <button
                        class="accordion-toggle step-x__toggle"
                        type="button"
                    >
                        {{ __("json.New Item") }}
                        <svg
                            class="account-accordion__arrow"
                            width="18"
                            height="12"
                        >
                            <use
                                xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"
                            ></use>
                        </svg>
                    </button>
                    <div class="accordion-dropdownm is-active">
                        <div class="step-x__body">
                            <div class="step-form__col">
                                <label for="number" class="label"
                                    >{{ __("json.Number") }}
                                    <span class="red-color">*</span></label
                                >
                                <div
                                    class="input-group"
                                    :class="{ 'has-error': errors.number }"
                                >
                                    <Field
                                        v-model="form.number"
                                        type="text"
                                        name="number"
                                        v-slot="{ field }"
                                    >
                                        <input
                                            v-bind="field"
                                            class="input"
                                            id="number"
                                            :placeholder="__('json.Number')"
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="number" />
                                    </div>
                                </div>
                            </div>

                            <div class="step-form__col">
                                <label for="given_name" class="label"
                                    >{{ __("json.Given name") }}
                                    <span class="red-color">*</span></label
                                >
                                <div
                                    class="input-group"
                                    :class="{ 'has-error': errors.given_name }"
                                >
                                    <Field
                                        v-model="form.given_name"
                                        type="text"
                                        name="given_name"
                                        v-slot="{ field }"
                                    >
                                        <input
                                            v-bind="field"
                                            class="input"
                                            id="given_name"
                                            :placeholder="__('json.Given name')"
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="given_name" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="family_name" class="label"
                                    >{{ __("json.Family name") }}
                                    <span class="red-color">*</span></label
                                >
                                <div
                                    class="input-group"
                                    :class="{ 'has-error': errors.family_name }"
                                >
                                    <Field
                                        v-model="form.family_name"
                                        type="text"
                                        name="family_name"
                                        v-slot="{ field }"
                                    >
                                        <input
                                            v-bind="field"
                                            class="input"
                                            id="family_name"
                                            :placeholder="
                                                __('json.Family name')
                                            "
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="family_name" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="rank" class="label"
                                    >{{ __("json.Rank")
                                    }}<span class="red-color">*</span></label
                                >
                                <div
                                    class="input-group"
                                    :class="{ 'has-error': errors.rank }"
                                >
                                    <Field
                                        v-model="form.rank"
                                        type="text"
                                        name="rank"
                                        v-slot="{ field }"
                                    >
                                        <input
                                            v-bind="field"
                                            class="input"
                                            id="rank"
                                            :placeholder="__('json.Rank')"
                                            type="number"
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="rank" />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="ineligible_effects" class="label"
                                    >{{ __("json.Ineligible effects")
                                    }}<span class="red-color">*</span></label
                                >
                                <div
                                    class="input-group"
                                    :class="{
                                        'has-error': errors.ineligible_effects,
                                    }"
                                >
                                    <Field
                                        v-model="form.ineligible_effects"
                                        type="text"
                                        name="ineligible_effects"
                                        v-slot="{ field }"
                                    >
                                        <input
                                            v-bind="field"
                                            class="input"
                                            id="ineligible_effects"
                                            :placeholder="
                                                __('json.Ineligible effects')
                                            "
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage
                                            name="ineligible_effects"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="step-form__col">
                                <label for="nationality_id" class="label"
                                    >{{ __("json.Nationality")
                                    }}<span class="red-color">*</span></label
                                >
                                <div
                                    class="input-group"
                                    :class="{
                                        'has-error': errors.nationality_id,
                                    }"
                                >
                                    <Field
                                        v-model="form.nationality_id"
                                        type="text"
                                        name="nationality_id"
                                        v-slot="{ field }"
                                    >
                                        <v-select
                                            v-bind="field"
                                            v-model="form.nationality_id"
                                            label="name"
                                            class="v-select-uruk"
                                            :placeholder="
                                                __('json.Select Nationality')
                                            "
                                            :options="countries"
                                            :reduce="(country) => country.id"
                                            :clearable="false"
                                        />
                                    </Field>
                                    <div class="input-group__error">
                                        <ErrorMessage name="nationality_id" />
                                    </div>
                                </div>
                            </div>

                            <div class="step-form__col">
                                    <label for="birth_date" class="label"
                                        >{{ __("json.Birth date") }}
                                        <span class="red-color">*</span></label
                                    >
                                    <div
                                        class="input-group"
                                        :class="{ 'has-error': errors.birth_date }"
                                    >
                                        <Field
                                            v-model="form.birth_date"
                                            type="text"
                                            name="birth_date"
                                            v-slot="{ field }"
                                        >
                                            <input
                                                v-bind="field"
                                                class="input"
                                                id="birth_date"
                                                :placeholder="__('json.Birth date')"
                                                type="date"
                                            />
                                        </Field>
                                        <div class="input-group__error">
                                            <ErrorMessage name="birth_date" />
                                        </div>
                                    </div>
                                </div>

                                <div class="step-form__col">
                                        <label for="birth_place" class="label"
                                            >{{ __("json.Birth place") }}
                                            <span class="red-color">*</span></label
                                        >
                                        <div
                                            class="input-group"
                                            :class="{ 'has-error': errors.birth_place }"
                                        >
                                            <Field
                                                v-model="form.birth_place"
                                                type="text"
                                                name="birth_place"
                                                v-slot="{ field }"
                                            >
                                                <input
                                                    v-bind="field"
                                                    class="input"
                                                    id="birth_place"
                                                    :placeholder="__('json.Birth place')"
                                                />
                                            </Field>
                                            <div class="input-group__error">
                                                <ErrorMessage name="birth_place" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-form__col">
                                    <label for="gender" class="label"
                                        >{{ __("json.Gender")
                                        }}<span class="red-color">*</span></label
                                    >
                                    <div
                                        class="input-group"
                                        :class="{
                                                'has-error': errors.gender,
                                            }"
                                    >
                                        <Field
                                            v-model="form.gender"
                                            type="text"
                                            name="gender"
                                            v-slot="{ field }"
                                        >
                                            <v-select
                                                v-bind="field"
                                                v-model="form.gender"
                                                label="name"
                                                class="v-select-uruk"
                                                :placeholder="__('json.Gender')"
                                                :options="genders"
                                                :reduce="(gender) => gender.id"
                                                :clearable="false"
                                            />
                                        </Field>
                                        <div class="input-group__error">
                                            <ErrorMessage name="gender" />
                                        </div>
                                    </div>
                                </div>
    <div class="step-form__col">
                                            <label for="id_doc_nature" class="label"
                                                >{{ __("json.ID doc nature") }}
                                                <span class="red-color">*</span></label
                                            >
                                            <div
                                                class="input-group"
                                                :class="{ 'has-error': errors.id_doc_nature }"
                                            >
                                                <Field
                                                    v-model="form.id_doc_nature"
                                                    type="text"
                                                    name="id_doc_nature"
                                                    v-slot="{ field }"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        class="input"
                                                        id="id_doc_nature"
                                                        :placeholder="__('json.ID doc nature')"
                                                    />
                                                </Field>
                                                <div class="input-group__error">
                                                    <ErrorMessage name="id_doc_nature" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="step-form__col">
                                                <label for="id_doc_number" class="label"
                                                    >{{ __("json.ID doc number") }}
                                                    <span class="red-color">*</span></label
                                                >
                                                <div
                                                    class="input-group"
                                                    :class="{ 'has-error': errors.id_doc_number }"
                                                >
                                                    <Field
                                                        v-model="form.id_doc_number"
                                                        type="text"
                                                        name="id_doc_number"
                                                        v-slot="{ field }"
                                                    >
                                                        <input
                                                            v-bind="field"
                                                            class="input"
                                                            id="id_doc_number"
                                                            :placeholder="__('json.ID doc number')"
                                                        />
                                                    </Field>
                                                    <div class="input-group__error">
                                                        <ErrorMessage name="id_doc_number" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="step-form__col">
                                    <label for="id_doc_issuing_state_id" class="label"
                                        >{{ __("json.ID doc issuing state")
                                        }}<span class="red-color">*</span></label
                                    >
                                    <div
                                        class="input-group"
                                        :class="{
                                                'has-error': errors.id_doc_issuing_state_id,
                                            }"
                                    >
                                        <Field
                                            v-model="form.id_doc_issuing_state_id"
                                            type="text"
                                            name="id_doc_issuing_state_id"
                                            v-slot="{ field }"
                                        >
                                            <v-select
                                                v-bind="field"
                                                v-model="form.id_doc_issuing_state_id"
                                                label="name"
                                                class="v-select-uruk"
                                                :placeholder="__('json.Select State')
                                                    "
                                                :options="countries"
                                                :reduce="(country) => country.id"
                                                :clearable="false"
                                            />
                                        </Field>
                                        <div class="input-group__error">
                                            <ErrorMessage name="id_doc_issuing_state_id" />
                                        </div>
                                    </div>
                                </div>
    <div class="step-form__col">
                                        <label for="id_doc_expiry_date" class="label"
                                            >{{ __("json.ID doc expiry date") }}
                                            <span class="red-color">*</span></label
                                        >
                                        <div
                                            class="input-group"
                                            :class="{ 'has-error': errors.id_doc_expiry_date }"
                                        >
                                            <Field
                                                v-model="form.id_doc_expiry_date"
                                                type="text"
                                                name="id_doc_expiry_date"
                                                v-slot="{ field }"
                                            >
                                                <input
                                                    v-bind="field"
                                                    class="input"
                                                    id="id_doc_expiry_date"
                                                    :placeholder="__('json.Expiry date')"
                                                    type="date"
                                                />
                                            </Field>
                                            <div class="input-group__error">
                                                <ErrorMessage name="id_doc_expiry_date" />
                                            </div>
                                        </div>
                                    </div>













                            <div class="step-x__col step-x__col--w-100">
                                <button
                                    type="submit"
                                    class="button button--outline-red button--md step-form__delete"
                                >
                                    {{ __("json.Save") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </article>
            </form>
        </VeeForm>
        <div>
            <CrewList :master-id="updateShipmentId" ref="myCrewList" />
        </div>

        <!-- </div> -->
        <div class="step-form__buttons">
            <button
                class="button button--outline step-form__button"
                type="button"
                @click="handlePrev"
            >
                {{ __("json.Previous step") }}
            </button>
            <button
                class="button button--blue step-form__button"
                type="submit"
                @click="handleNext"
            >
                {{ __("json.Next step") }}
            </button>
        </div>
    </section>

    <!-- <button id="btn-open-new-modal" data-modal="modal-new" style="display: none;"></button> -->
</template>

<script>
import vSelect from "vue-select";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import { VueTelInput } from "vue-tel-input";
import * as yup from "yup";
import lang from "../../app/lang";
import IconLoading from "../partials/IconLoading.vue";
import { useGetCrews } from "../utils/useGetCrews";
import CrewList from "./CrewList.vue";
import { ref } from "vue";

const myCrewList = ref(null);

const schema = yup.object({
    number: yup.mixed().required(lang("validation.required", {attribute: lang("json.Number")})),
    family_name: yup.mixed().required(lang("validation.required",{attribute:lang("json.Family name")})),
    given_name: yup.mixed().required(lang("validation.required",{attribute:lang("json.Given name")})),
    rank: yup.number().min(0).required(lang("validation.required",{attribute:lang("json.Rank")})),
    ineligible_effects: yup.mixed().required(lang("validation.required",{attribute:lang("json.Ineligible effects")})),
    nationality_id: yup.mixed().required(lang("validation.required",{attribute:lang("json.Nationality")})),
    birth_date: yup.mixed().required(lang("validation.required",{attribute:lang("json.Birth date")})),
    birth_place: yup.mixed().required(lang("validation.required",{attribute:lang("json.Birth place")})),
    gender: yup.mixed().required(lang("validation.required",{attribute:lang("json.Gender")})),
    id_doc_nature: yup.mixed().required(lang("validation.required",{attribute:lang("json.ID doc nature")})),
    id_doc_number: yup.mixed().required(lang("validation.required",{attribute:lang("json.ID doc number")})),
    id_doc_issuing_state_id: yup.mixed().required(lang("validation.required",{attribute:lang("json.ID doc issuing state")})),
    id_doc_expiry_date: yup.mixed().required(lang("validation.required",{attribute:lang("json.ID doc expiry date")}))
});

export default {
    name: "Step4",
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        steps: {
            type: Object,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
        updateShipmentId: {
            type: Number,
            required: false,
        },
        countries: {
            type: Object,
            required: true
        },

    },
    components: {
        vSelect,
        VeeForm,
        Field,
        ErrorMessage,
        VueTelInput,
        IconLoading,
        CrewList,
    },
    setup() {
        const { getCrews, items } = useGetCrews();
        return {
            getCrews,
            items,
        };
    },
    data() {
        return {
            genders:[{id:"MALE",name:"MALE"}, { id: "FEMALE", name: "FEMALE" }],
            currentStep: this.modelValue,
            form: {
                number: null,
                family_name: null,
                given_name: null,
                rank: null,
                ineligible_effects: null,
                nationality_id: null,
                birth_date: null,
                birth_place: null,
                gender: null,
                id_doc_nature: null,
                id_doc_number: null,
                id_doc_issuing_state_id: null,
                id_doc_expiry_date: null,
            },
            schema,
        };
    },
    mounted() {
        this.getCrews(this.updateShipmentId).then((data) => {});
    },
    computed: {
        // formWithShipmentType() {
        //     if (!('shipmentType' in this.form)) {
        //         Object.assign(this.form, { 'shipmentType': this.shipmentType })
        //     }
        //     return this.form;
        // },
    },
    emits: ["update:modelValue"],
    methods: {
        async fillCrew() {
            let url = "/shipments/fill-crew/" + this.updateShipmentId;
            try {
                var response = await axios.post(url, this.form);

                this.$refs.myCrewList.loadItems();
            } catch {}
        },
        handleAdd() {
            //document.getElementById('btn-open-new-modal').click();
        },
        onSubmit() {
            this.fillCrew();
        },
        handleNext() {
            const _currentStep = this.modelValue + 1;
            this.$emit("update:modelValue", _currentStep);
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit("update:modelValue", _currentStep);
        },
        // updateFormData() {
        //     this.form.driver_name = this.existingShipment ? this.existingShipment.driver_name : null;
        //     this.form.nationality_id = this.existingShipment ? this.existingShipment.nationality_id : null;
        //     this.form.passport_id = this.existingShipment ? this.existingShipment.passport_id : null;
        //     if (this.existingShipment && this.existingShipment.driver_phone) {
        //         this.form.driver_phone = this.existingShipment.driver_phone;
        //     }
        // },
    },
    // watch: {
    //     'form.driver_phone'() {
    //         this.form.driver_phone = this.form.driver_phone.replace(' ', '');
    //     }
    // },
};
</script>
