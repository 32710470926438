const default_locale = window.default_locale;
const fallback_locale = window.fallback_locale;
const messages = window.messages;

export default function(key, replacements = {}) {
    let translation = key;

    // check if key starts with json.
    if (key.startsWith('json.')) {
        key = key.replace('json.', '');
        translation = key;
        if (!messages['json']) {
            return translation;
        }

        let locale = null;
        if (messages['json'][default_locale]) {
            locale = default_locale;
        } else if (messages['json'][fallback_locale]) {
            locale = fallback_locale;
        }

        if (locale) {
            if (messages['json'][locale][key]) {
                let result = messages['json'][locale][key];
                Object.keys(replacements).map(replacementKey => {
                    result = result.replace(':' + replacementKey, replacements[replacementKey]);
                });
                return result;
            }
        }
    } else {
        // doesn't start with json, simple lang files
        let keys = key.split('.');
        if (keys.length < 1) {
            return translation;
        }
        let fileKey = keys[0];
        keys.shift();
        let langKey = keys.join('.');

        let locale = null;
        if (messages[default_locale]) {
            locale = default_locale;
        } else if (messages[fallback_locale]) {
            locale = fallback_locale;
        }

        if (locale) {
            if (messages[locale][fileKey]) {
                let langKeyParts = langKey.split('.');
                let result = null;
                if (langKeyParts.length > 1) {
                    let langKeyStart = langKeyParts[0];
                    langKeyParts.shift();
                    let langKeyTail = langKeyParts.join('');
                    if (messages[locale][fileKey][langKeyStart][langKeyTail]) {
                        result = messages[locale][fileKey][langKeyStart][langKeyTail];
                    }
                } else {
                    if (messages[locale][fileKey][langKey]) {
                        result = messages[locale][fileKey][langKey];
                    }
                }

                if (result) {
                    Object.keys(replacements).map(replacementKey => {
                        result = result.replace(':' + replacementKey, replacements[replacementKey]);
                    });
                    return result;
                }
            }
        }
    }

    return translation;
}
