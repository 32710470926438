import {ref} from "vue";

export const useGetCrews = () => {
    const items = ref(null);

    const getCrews = async (updateShipmentId) => {
        let url = '/shipments/crews/' + updateShipmentId;

        return await axios.get(url).then(({data}) => {
            items.value = data;
            return data;
        });
    }

    return { getCrews, items };
}
