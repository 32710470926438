<template>
    <section class="find-section">
        <header class="find-section__header">
            <form class="search-form find-section__form" @submit.prevent="search">
                <div class="input-group search-form__group">
                    <input v-model="query" class="input search-form__input" type="text" :placeholder="__('main.enter_border')" required>
                    <button class="button button--red search-form__button">
                        <span class="search-form__button-title">
                            {{ __("main.search") }}
                        </span>
                        <svg class="search-form__button-icon" width="16" height="16">
                            <use xlink:href="/assets/images/new/sprite.svg#search"></use>
                        </svg>
                    </button>
                </div>
            </form>

            <button class="filter-button" type="button"
                @click="filtersAreShown = !filtersAreShown"
                :class="{ 'is-active': filtersAreShown }"
            >
                <span class="filter-button__text">
                    {{ __("find-bgw.filters") }}
                </span>
                <span class="filter-button__count" v-if="filtersCount">
                    {{ filtersCount }}
                </span>
                <svg class="filter-button__icon" width="13" height="8">
                    <use xlink:href="/assets/images/new/sprite.svg#dropdown-arrow"></use>
                </svg>
                <svg class="filter-button__mobile-icon" width="24" height="21">
                    <use xlink:href="/assets/images/new/sprite.svg#filter"></use>
                </svg>
            </button>
        </header>

        <form class="filter-form" :class="{ 'is-visible': filtersAreShown }">
            <div class="filter-form__row">
                <div class="filter-form__col">
                    <FilterSelect
                        :label="__('find-bgw.category')"
                        :placeholder="__('find-bgw.select_category')"
                        :options="categories"
                        :selected="selectedCategory"
                        @updateSelected="updateSelected($event, 'selectedCategory')"
                    />
                </div>
                <div class="filter-form__col">
                    <FilterSelect
                        :label="__('find-bgw.province')"
                        :placeholder="__('find-bgw.select_province')"
                        :options="provinces"
                        :selected="selectedProvince"
                        @updateSelected="updateSelected($event, 'selectedProvince')"
                    />
                </div>
                <div class="filter-form__col">
                    <button class="button button--outline-blue filter-form__button" type="button"
                        :disabled="!filtersCount"
                        @click="clearFilters"
                    >
                        {{  __("find-bgw.clear_filters") }}
                    </button>
                </div>
            </div>
        </form>

        <div class="find-section__map-wrap">
            <div id="map" ref="map" class="find-section__map" style="width: 100%; height: 615px;"></div>
            <div class="find-section__map-legend">
                <div class="gateway-feed" v-if="filteredBorderGateways.length > 0">
                    <ul class="gateway-feed__list">
                        <li class="gateway-feed__item"
                            v-for="bgw in filteredBorderGateways" :key="bgw.id"
                            @click="setSelectedBorderGateway(bgw.id)">
                            <a href="#" class="gateway-feed__link">
                                <article class="gateway-item">
                                    <div class="gateway-item__header">
                                        <h5 class="gateway-item__title">{{ bgw.name }}</h5>
<!--                                        <span class="gateway-item__status" :class="{ 'gateway-item__status&#45;&#45;red': !bgw.is_created_wallet }">-->
<!--                                            {{ bgw.is_created_wallet ? __('find-bgw.open_now') : __('find-bgw.closed') }}-->
<!--                                        </span>-->
                                    </div>
                                    <div class="gateway-item__row">
                                        <p class="gateway-item__label">{{ __('main.address') }}:</p>
                                        <p class="gateway-item__text">{{ bgw.location }}</p>
<!--                                        <p class="gateway-item__label gateway-item__label&#45;&#45;right">5 km</p>-->
                                    </div>
<!--                                    <div class="gateway-item__row">-->
<!--                                        <p class="gateway-item__label">{{ __('find-bgw.work_time') }}:</p>-->
<!--                                        <p class="gateway-item__text">8am - 4.30pm</p>-->
<!--                                    </div>-->
                                    <div class="gateway-item__row">
                                        <p class="gateway-item__label">{{ __('main.phone_type') }}</p>
                                        <p class="gateway-item__text">{{ bgw.contact_number }}</p>
                                    </div>
                                </article>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="gateway-card" v-if="selectedBorderGateway">
                    <div class="gateway-card__header">
                        <button class="gateway-card__back-button" type="button">
                            {{ __('main.back') }}
                        </button>
                        <button class="gateway-card__close-button" aria-label="Close card" type="button"
                                @click="setSelectedBorderGateway(null)">
                            <svg width="12" height="12">
                                <use xlink:href="/assets/images/new/sprite.svg#close-alt"></use>
                            </svg>
                        </button>
                    </div>

                    <picture class="gateway-card__picture">
                        <img class="gateway-card__img" src="/assets/images/new/gateway-img.jpg"
                             srcset="/assets/images/new/gateway-img@2x.jpg 2x" width="338" height="160" alt="image">
                    </picture>

                    <div class="gateway-card__content">
                        <ul class="gateway-card__list">
                            <li class="gateway-card__item">
                                <article class="gateway-item">
                                    <div class="gateway-item__header">
                                        <h5 class="gateway-item__title">{{ selectedBorderGateway.name }}</h5>
<!--                                        <span class="gateway-item__status" :class="{ 'gateway-item__status&#45;&#45;red': !selectedBorderGateway.is_created_wallet }">-->
<!--                                            {{ selectedBorderGateway.is_created_wallet ? __('find-bgw.open_now') : __('find-bgw.closed') }}-->
<!--                                        </span>-->
                                    </div>
                                    <div class="gateway-item__row">
                                        <p class="gateway-item__label">{{ __('main.address') }}:</p>
                                        <p class="gateway-item__text">{{ selectedBorderGateway.location }}</p>
<!--                                        <p class="gateway-item__label gateway-item__label&#45;&#45;right">5 km</p>-->
                                    </div>
<!--                                    <div class="gateway-item__row">-->
<!--                                        <p class="gateway-item__label">{{ __('find-bgw.work_time') }}:</p>-->
<!--                                        <p class="gateway-item__text">8am - 4.30pm</p>-->
<!--                                    </div>-->
                                    <div class="gateway-item__row">
                                        <p class="gateway-item__label">{{ __('main.phone_type') }}</p>
                                        <p class="gateway-item__text">{{ selectedBorderGateway.contact_number }}</p>
                                    </div>
                                </article>
                            </li>
<!--                            <li class="gateway-card__item">-->
<!--                                <article class="gateway-item">-->
<!--                                    <div class="gateway-item__header">-->
<!--                                        <h5 class="gateway-item__title">{{ __('main.border') }}</h5>-->
<!--                                    </div>-->
<!--                                    <div class="gateway-item__route">-->
<!--                                        <p class="gateway-item__label">{{ __('main.from_border') }}</p>-->
<!--                                        <p class="gateway-item__text">{{ selectedBorderGateway.country }}</p>-->
<!--                                    </div>-->
<!--                                    <div class="gateway-item__route">-->
<!--                                        <p class="gateway-item__label">{{ __('main.to_border') }}</p>-->
<!--                                        <p class="gateway-item__text">Iraq</p>-->
<!--                                    </div>-->
<!--                                </article>-->
<!--                            </li>-->
                        </ul>
                    </div>

                    <button class="button button--red button--md gateway-card__button" type="button" @click="buildRoute(selectedBorderGateway.lng_lat)">
                        {{ __('find-bgw.directions') }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FilterSelect from "./partials/BorderGatewaySearch/FilterSelect.vue";

let bgwMarkers = [];

export default {
    components: {
        FilterSelect
    },
    data() {
        return {
            center: {
                lat: 33.5,
                lng: 44.2
            },
            userPosition: {
                lat: 33.5,
                lng: 44.2
            },
            map: null,
            query: '',
            selectedBorderGateway: null,
            borderGateways: [],
            filtersAreShown: false,
            filteredBorderGateways: [],
            categories: [],
            provinces: [],
            selectedCategory: null,
            selectedProvince: null,
            directionsService: null,
            directionsDisplay: null,
        }
    },
    mounted() {
        this.initGoogleMaps();
        this.getUserCurrentPosition();
        this.initData();
    },
    computed: {
        filtersCount() {
            let count = 0;
            if (this.selectedCategory) {
                count++;
            }
            if (this.selectedProvince) {
                count++;
            }
            return count;
        }
    },
    methods: {
        initGoogleMaps() {
            window.loader.load().then(() => {
                this.map = new google.maps.Map(this.$refs.map, {
                    center: this.center,
                    zoom: 8,
                });

                this.directionsDisplay = new google.maps.DirectionsRenderer({'draggable': false});
                this.directionsService = new google.maps.DirectionsService();
            });
        },
        getUserCurrentPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.userPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                });
            }
        },
        initData() {
            axios.get(`/api/borders?limit=100&locale=${window.default_locale}`).then(response => {
                if (response.status !== 200) {
                    alert("Something went wrong. Try again later.");
                }

                if (!response.data.length) {
                    alert("No results found");
                }

                this.borderGateways = response.data;
                this.borderGateways.forEach((bgw) => {
                    if (!this.categories.includes(bgw.category) && bgw.category !== 'Private company') {
                        this.categories.push(bgw.category);
                    }
                    if (!this.provinces.includes(bgw.province)) {
                        this.provinces.push(bgw.province);
                    }
                });
                this.placeBorderGatewaysOnMap(this.borderGateways);
            });
        },
        search() {
            this.clearBorderGateways();

            this.filteredBorderGateways = this.borderGateways.filter((bgw) => {
                if (this.query) {
                    if (!bgw.name.includes(this.query)) {
                        return false;
                    }
                }

                if (this.selectedCategory) {
                    if (bgw.category !== this.selectedCategory) {
                        return false;
                    }
                }

                if (this.selectedProvince) {
                    if (bgw.province !== this.selectedProvince) {
                        return false;
                    }
                }

                return true;
            });

            this.placeBorderGatewaysOnMap(this.filteredBorderGateways);
        },
        setSelectedBorderGateway(borderGatewayId) {
            this.directionsDisplay.setMap(null);

            if (borderGatewayId === null) {
                this.selectedBorderGateway = null;
                this.fitMapBounds();
                return;
            }

            this.selectedBorderGateway = this.borderGateways.find(bgw => bgw.id === borderGatewayId);
            let coords = this.resolveCoords(this.selectedBorderGateway.lng_lat);
            this.setMapCenter(coords.lat, coords.lng);
            this.map.setZoom(15);
        },
        setMapCenter(latitude, longitude) {
            this.map.setCenter({ lat: latitude, lng: longitude });
        },
        placeBorderGatewaysOnMap(borderGateways) {
            borderGateways.forEach((bgw) => {
                let coords = this.resolveCoords(bgw.lng_lat);
                this.placeMarker(coords.lat, coords.lng, bgw.id);
            });
            this.fitMapBounds();
        },
        placeMarker(latitude, longitude, id) {
            let marker = new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: this.map,
                borderGatewayId: id,
                icon: '/assets/images/bgw.svg'
            });
            marker.addListener('click', () => {
                this.map.setCenter(marker.getPosition());
                this.setSelectedBorderGateway(marker.borderGatewayId);
            });
            bgwMarkers.push(marker);
        },
        fitMapBounds() {
            let bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < bgwMarkers.length; i++) {
                bounds.extend(
                    new google.maps.LatLng(bgwMarkers[i].getPosition())
                );
            }
            this.map.fitBounds(bounds);
            let zoom = this.map.getZoom();
            this.map.setZoom(zoom > 10 ? 10 : zoom);
        },
        clearBorderGateways() {
            this.filteredBorderGateways = [];
            for (let i = 0; i < bgwMarkers.length; i++ ) {
                bgwMarkers[i].setMap(null);
            }
            bgwMarkers = [];
        },
        resolveCoords(coordinates) {
            let coords = coordinates
                .replace('N', '')
                .replace('E', '')
                .replace('°', '')
                .replace('°', '');

            coords = coords.split(',');
            return {
                lng: parseFloat(coords[1].trim()),
                lat: parseFloat(coords[0].trim())
            };
        },
        updateSelected(newSelected, currentSelected) {
            this[currentSelected] = newSelected;
            this.search();
        },
        clearFilters() {
            this.selectedCategory = null;
            this.selectedProvince = null;
            this.clearBorderGateways();
            this.placeBorderGatewaysOnMap(this.borderGateways);
        },
        buildRoute(bgwLngLat) {
            let bgwCoords = this.resolveCoords(bgwLngLat);
            let origin = new google.maps.LatLng(this.userPosition.lat, this.userPosition.lng);
            this.directionsService.route({
                origin: origin,
                destination: new google.maps.LatLng(bgwCoords.lat, bgwCoords.lng),
                travelMode: 'DRIVING',
                avoidTolls: true
            }, (response, status) => {
                if (status === 'OK') {
                    this.directionsDisplay.setMap(this.map);
                    this.directionsDisplay.setDirections(response);
                } else {
                    this.directionsDisplay.setMap(null);
                    // this.directionsDisplay.setDirections(null);
                    console.log('Could not display directions due to: ', status);
                }
            });
        },
    }
}
</script>
